import { useAuth0 } from '@auth0/auth0-react';
import {
  ErrorGeneric,
  Loader,
  useSafeLog,
} from '@fresh-stack/frontend-commons';
import { IconButton, ThemeProvider } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { RouterProvider } from 'react-router-dom';
import { buildAppRouter } from './app/routes/router';
import './assets/App.css';
import { CustomPostHogProvider } from './components/PostHogProvider';
import { TrpcProvider } from './components/TrpcProvider';
import { THEME_GLOBAL } from './theme';
import { enGB } from 'date-fns/locale';
import { closeSnackbar, SnackbarProvider } from 'notistack';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
export const App = () => {
  const safeLog = useSafeLog();
  const { error, isLoading: isLoadingAuth0, isAuthenticated } = useAuth0();

  if (isLoadingAuth0) return <Loader />;
  else if (error && !isAuthenticated) {
    safeLog('error in App.tsx', error);
    return <ErrorGeneric redirectPath="/" />;
  } else
    return (
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enGB}>
        <CustomPostHogProvider>
          <TrpcProvider>
            <ReactQueryDevtools initialIsOpen={false} />
            <ThemeProvider theme={THEME_GLOBAL}>
              <SnackbarProvider
                hideIconVariant
                autoHideDuration={5_000}
                maxSnack={3}
                action={(snackbarId) => (
                  <IconButton onClick={() => closeSnackbar(snackbarId)}>
                    <DeleteForeverIcon color="secondary" fontSize="small" />
                  </IconButton>
                )}
              >
                <RouterProvider router={buildAppRouter()} />
              </SnackbarProvider>
            </ThemeProvider>
          </TrpcProvider>
        </CustomPostHogProvider>
      </LocalizationProvider>
    );
};
