import {
  appColors,
  useQueryState,
  wrapQuery,
} from '@fresh-stack/frontend-commons';
import {
  MIN_DATE,
  isError,
  max,
  min,
  sumBy,
} from '@fresh-stack/fullstack-commons';
import { Box, Grid, Stack, Typography } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers-pro';
import { ResponsiveBar } from '@nivo/bar';
import { ResponsiveLine } from '@nivo/line';
import { differenceInMonths, format, parse, subMonths } from 'date-fns';
import { useSnackbar } from 'notistack';
import { useMemo, useRef } from 'react';
import { trpc } from '../../utils';
import {
  BarChartAmountTooltip,
  LineChartAmountTooltip,
} from './ChartAmountTooltip';
import { URL_DATE_FORMAT } from './ChartDashboardPage';
import { DownloadElementAsPngButton } from './DownloadElementAsPngButton';

const DEFAULT_MONTH = new Date(subMonths(new Date(), 11).toISOString());

export const DashboardSectionCashPosition = ({
  selectedCurrencyCode,
  accounts,
}: {
  readonly selectedCurrencyCode: string;
  readonly accounts: {
    readonly availableBalance: number | undefined;
    readonly id: string;
  }[];
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const refChartFlows = useRef<HTMLDivElement | null>(null);
  const refChartBalance = useRef<HTMLDivElement | null>(null);

  const [cashPositionSinceStr, setCashPositionSince] = useQueryState<
    string | undefined
  >({
    fieldName: 'cashPositionSince',
    defaultValue: format(DEFAULT_MONTH, URL_DATE_FORMAT),
    deserialize: (value: string) =>
      value || format(DEFAULT_MONTH, URL_DATE_FORMAT),
    serialize: (value) => value ?? '',
  });

  const selectedMonth = useMemo(
    () =>
      cashPositionSinceStr
        ? parse(cashPositionSinceStr, URL_DATE_FORMAT, new Date())
        : new Date(),
    [cashPositionSinceStr],
  );

  const { result: maybeCashFlow, isLoading: isLoadingCashFlow } = wrapQuery(
    trpc.reports.getCashFlow.useQuery(
      {
        currency: selectedCurrencyCode,
        since: selectedMonth.toISOString(),
      },
      {
        refetchOnWindowFocus: false,
      },
    ),
  );

  const barData = useMemo(() => {
    if (!maybeCashFlow || isLoadingCashFlow) return [];
    else if (isError(maybeCashFlow))
      throw new Error(); // we are on the home page, show error screen to reload
    else {
      return maybeCashFlow.right.rows.map((x) => ({
        id: new Date(x.month).getTime(),
        key: format(new Date(x.month), 'MMM yyyy'),
        Inflows: x.totalDeposit,
        Outflows: x.totalWithdrawal,
      }));
    }
  }, [isLoadingCashFlow, maybeCashFlow]);

  const { minBalance, maxBalance, data } = useMemo(() => {
    // eslint-disable-next-line functional/no-let
    let currentBalance = sumBy(accounts, (x) =>
      x.availableBalance ? +x.availableBalance : 0,
    );
    const balanceRows = [
      {
        x: format(new Date(), 'MMM yyyy'),
        y: currentBalance,
      },
    ];

    for (const row of [...barData].reverse()) {
      currentBalance = currentBalance - row.Inflows + row.Outflows;
      balanceRows.push({
        x: format(subMonths(new Date(row.id), 1), 'MMM yyyy'),
        y: currentBalance,
      });
    }

    const resultRows = balanceRows.reverse().slice(1);

    const minBalance = min(resultRows.map((row) => row.y)) ?? 0;
    const maxBalance = max(resultRows.map((row) => row.y)) ?? 0;
    const margin = (maxBalance - minBalance) / 10;

    return {
      minBalance: Math.min(minBalance - margin, 0),
      maxBalance: (maxBalance ?? 0) + margin,
      data: {
        id: 'Balance',
        data: resultRows,
      },
    };
  }, [accounts, barData]);

  const intervalMonths = useMemo(
    () =>
      selectedMonth ? differenceInMonths(new Date(), selectedMonth) : undefined,
    [selectedMonth],
  );

  const keys = ['Inflows', 'Outflows'];

  const chartColumnPadding =
    intervalMonths === undefined || intervalMonths > 12
      ? 0.4
      : intervalMonths > 6
        ? 0.2
        : 0.1;

  const bottomAxisLabelAngle =
    intervalMonths === undefined || intervalMonths > 12
      ? 45
      : intervalMonths > 6
        ? 30
        : 0;

  return (
    <Stack pl={1}>
      <Box
        sx={{
          maxWidth: 400,
          borderBottom: 1,
          borderColor: 'silver',
          padding: 1,
        }}
      >
        <Box display={'flex'} gap={1} alignItems={'center'}>
          <Typography variant="subtitle1" color="primary" gutterBottom>
            Cash position
          </Typography>
          <DateTimePicker
            views={['month', 'year']}
            sx={{ width: 200 }}
            minDate={MIN_DATE}
            maxDate={new Date()}
            onChange={(newValue) => {
              if (newValue)
                setCashPositionSince(format(newValue, URL_DATE_FORMAT));
            }}
            value={selectedMonth}
            slotProps={{
              field: {
                label: 'Since',
                clearable: false,
              },
              textField: {
                size: 'small',
              },
            }}
          />
        </Box>
      </Box>
      <Grid container item xs={12}>
        <Grid item xs={12} sm={6} mt={1} pr={1}>
          <Box borderRadius={2} boxShadow={3} pb={4}>
            <Grid
              container
              item
              xs={12}
              pl={4}
              pt={2}
              pr={2}
              alignItems={'center'}
              justifyContent={'space-between'}
              gap={1}
            >
              <Typography variant="subtitle1">
                Cash balance ({selectedCurrencyCode})
              </Typography>
              <DownloadElementAsPngButton
                disabled={false}
                variant="contained"
                filename={`Cash balance`}
                elementRef={refChartBalance}
                onError={() =>
                  enqueueSnackbar({
                    variant: 'error',
                    message:
                      'There was an issue generating the file, please try again later or in a different browser!',
                  })
                }
              />
            </Grid>
            <Box height={300} ref={refChartBalance}>
              <ResponsiveLine
                key={'cash-balance-line-chart'}
                data={[data]}
                animate={false}
                margin={{
                  top: 10,
                  bottom: 60,
                  left: 100,
                  right: 50,
                }}
                xScale={{ type: 'point' }}
                yScale={{
                  type: 'linear',
                  max: maxBalance,
                  min: minBalance,
                }}
                tooltip={LineChartAmountTooltip(selectedCurrencyCode)}
                axisLeft={{
                  tickRotation: 0,
                  legend: `Amount (${selectedCurrencyCode})`,
                  legendPosition: 'middle',
                  legendOffset: -80,
                  format: (x) => x.toLocaleString(),
                }}
                colors={[appColors.charts.balance]}
                curve="monotoneX"
                isInteractive={true}
                enableGridX={false}
                enableGridY={true}
                enablePoints={false}
                enablePointLabel={false}
                pointLabel={''}
                enableArea={true}
                areaBlendMode={'normal'}
                areaOpacity={0.2}
                areaBaselineValue={0}
                yFormat=" >-.2f"
                lineWidth={1.4}
                axisTop={null}
                axisRight={null}
                axisBottom={{
                  tickRotation: bottomAxisLabelAngle,
                }}
                useMesh={true}
                pointSize={10}
                pointBorderWidth={2}
                legends={[]}
                role="figure"
                defs={[]}
                fill={[]}
                debugMesh={false}
                debugSlices={false}
                enableSlices={false}
                enableCrosshair={true}
                crosshairType={'cross'}
                sliceTooltip={() => ''}
                pointColor={appColors.charts.balance}
                pointBorderColor={appColors.charts.balance}
                layers={[
                  'grid',
                  'markers',
                  'axes',
                  'areas',
                  'crosshair',
                  'lines',
                  'slices',
                  'points',
                  'legends',
                  'mesh',
                ]}
              />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} mt={1} pr={1}>
          <Box borderRadius={2} boxShadow={3} pb={4}>
            <Grid
              container
              item
              xs={12}
              pl={4}
              pt={2}
              pr={2}
              alignItems={'center'}
              justifyContent={'space-between'}
              gap={1}
            >
              <Typography variant="subtitle1">
                Cash flow ({selectedCurrencyCode})
              </Typography>
              <DownloadElementAsPngButton
                disabled={false}
                variant="contained"
                filename={`Cash flow`}
                elementRef={refChartFlows}
                onError={() =>
                  enqueueSnackbar({
                    variant: 'error',
                    message:
                      'There was an issue generating the file, please try again later or in a different browser!',
                  })
                }
              />
            </Grid>
            <Box height={300} ref={refChartFlows}>
              <ResponsiveBar
                data={barData}
                keys={keys}
                indexBy="key"
                margin={{
                  top: 35,
                  bottom: 60,
                  left: 100,
                  right: 30,
                }}
                valueScale={{ type: 'linear', nice: true }}
                indexScale={{ type: 'band', round: true }}
                padding={chartColumnPadding}
                axisLeft={{
                  tickRotation: 0,
                  legend: `Amount (${selectedCurrencyCode})`,
                  legendPosition: 'middle',
                  legendOffset: -80,
                  format: (x) => x.toLocaleString(),
                }}
                axisBottom={{
                  tickRotation: bottomAxisLabelAngle,
                }}
                label={() => ''}
                groupMode={'grouped'} //side by side
                colors={[
                  appColors.charts.deposits,
                  appColors.charts.withdrawals,
                ]}
                tooltip={BarChartAmountTooltip(selectedCurrencyCode)}
                legends={[
                  {
                    dataFrom: 'keys',
                    anchor: 'top',
                    direction: 'row',
                    symbolShape: 'circle',
                    symbolSize: 12,
                    symbolSpacing: 4,
                    translateY: -50,
                    itemWidth: 100,
                    itemHeight: 50,
                    itemDirection: 'left-to-right',
                    itemOpacity: 0.85,
                  },
                ]}
                animate={true}
              ></ResponsiveBar>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Stack>
  );
};
