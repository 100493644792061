import { Outlet, ScrollRestoration } from 'react-router-dom';
import { Box } from '@mui/material';

interface LayoutProps {
  readonly isErrorPage?: boolean;
  readonly children?: readonly JSX.Element[] | JSX.Element;
}

export const BaseLayout = ({ children, isErrorPage = false }: LayoutProps) => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Box>{isErrorPage ? children : <Outlet />}</Box>
      <ScrollRestoration />
    </Box>
  );
};
