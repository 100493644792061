import { FONTS } from '@fresh-stack/frontend-commons';
import {
  Tooltip,
  Typography,
  TypographyPropsVariantOverrides,
} from '@mui/material';
import { Variant } from '@mui/material/styles/createTypography';
import { OverridableStringUnion } from '@mui/types';

export const Logo = ({
  color,
  variant,
}: {
  readonly color: 'primary' | 'secondary';
  readonly variant?: OverridableStringUnion<
    Variant | 'inherit',
    TypographyPropsVariantOverrides
  >;
}) => {
  return (
    <Tooltip title="Welcome to our pre-alpha version! Please note, this early stage of development may include incomplete features and occasional bugs. Thank you for being part of our journey!">
      <Typography
        textAlign={'center'}
        variant={variant ?? 'h5'}
        color={color}
        fontFamily={FONTS.montserratExtraBold}
      >
        RIBON
        <span style={{ verticalAlign: 'sub', fontSize: 6 }}>PRE-ALPHA</span>
      </Typography>
    </Tooltip>
  );
};
