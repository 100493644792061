import {
  Box,
  BoxProps,
  Grid,
  Typography,
  TypographyProps,
} from '@mui/material';
import { FONTS, appColors } from '../theme';

interface PageHeaderProps extends BoxProps {
  readonly title: string;
  readonly typographyProps?: TypographyProps;
  readonly endAdornment?: JSX.Element;
}

export const PageHeader = ({
  title,
  endAdornment,
  typographyProps,
  ...boxProps
}: PageHeaderProps) => {
  return (
    <HeaderContainer {...boxProps}>
      <Grid
        container
        item
        xs={12}
        justifyContent={endAdornment ? 'space-between' : 'flex-start'}
        alignItems={'center'}
      >
        <Typography
          {...typographyProps}
          fontFamily={typographyProps?.fontFamily ?? FONTS.interBold}
          variant={typographyProps?.variant ?? 'subtitle1'}
          color={typographyProps?.color ?? 'primary'}
        >
          {title}
        </Typography>
        {endAdornment}
      </Grid>
    </HeaderContainer>
  );
};

export const HeaderContainer = ({
  children,
  ...boxProps
}: { readonly children: JSX.Element[] | JSX.Element } & BoxProps) => {
  return (
    <Box
      width={'100%'}
      {...boxProps}
      padding={boxProps.padding == null ? 1 : boxProps.padding}
      pl={boxProps.pl == null ? 1 : boxProps.pl}
      bgcolor={boxProps.bgcolor ?? appColors.secondary}
      mb={boxProps.mb == null ? 1 : boxProps.mb}
      boxShadow={boxProps.boxShadow == null ? 5 : boxProps.boxShadow}
      display={'flex'}
      alignItems={'center'}
    >
      {children}
    </Box>
  );
};
