import { withAuthenticationRequired } from '@auth0/auth0-react';
import {
  FrontEndError,
  Loader,
  useSafeLog,
  wrapQuery,
} from '@fresh-stack/frontend-commons';
import { UserType, pipe, throwError } from '@fresh-stack/fullstack-commons';
import { usePostHog } from 'posthog-js/react';
import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { getMenu } from '.';
import { MainLayout } from '../../layout';
import { trpc } from '../../utils';
import { UserContext } from '../hooks/userContext';
import { BasicUserDetails } from '@fresh-stack/router/types';

const enableSessionRecording = (email: string) =>
  !email.endsWith('@block.green') &&
  !email.endsWith('@getribon.com') &&
  import.meta.env.VITE_ENVIRONMENT === 'production';

declare global {
  interface Window {
    // eslint-disable-next-line functional/prefer-readonly-type
    _hsq: unknown[];
  }
}

interface ProtectedRouteProps {
  readonly allowedUserTypes: readonly UserType[];
}

const ProtectedRoute = ({ allowedUserTypes }: ProtectedRouteProps) => {
  const { isFetching, result } = wrapQuery(
    trpc.auth.getUser.useQuery(undefined, {
      refetchOnWindowFocus: false,
    }),
  );

  if (isFetching || !result) return <Loader />;

  const user = pipe(
    result,
    throwError(
      (error) =>
        new FrontEndError(
          'Error fetching user',
          error.code,
          error.unpackedError,
        ),
    ),
  );

  if (!allowedUserTypes.includes(user.userType))
    throw new Error(
      `Unauthorized: User type ${user.userType} may not access this route`,
    );

  return <RouteContent user={user} />;
};

const RouteContent = ({ user }: { readonly user: BasicUserDetails }) => {
  const posthog = usePostHog();
  const menu = getMenu(user.userType);
  const safeLog = useSafeLog();

  useEffect(() => {
    window._hsq = window._hsq || [];
    safeLog('sending hubspot user identification', { email: user.email });
    window._hsq.push([
      'identify',
      {
        email: user.email,
      },
    ]);

    if (posthog) {
      posthog.identify(user.email);
      if (enableSessionRecording(user.email)) {
        safeLog('starting posthog session recording', user.email);
        posthog.startSessionRecording();
      }
    }
  }, [posthog, safeLog, user]);

  return (
    <UserContext.Provider value={{ user, menu: menu }}>
      <MainLayout>
        <Outlet />
      </MainLayout>
    </UserContext.Provider>
  );
};

export default withAuthenticationRequired(ProtectedRoute, {
  onRedirecting: () => <Loader />,
  returnTo: () => window.location.href,
});
