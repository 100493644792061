import {
  CustomFieldDefinition,
  FONTS,
  appColors,
  getColorPalette,
} from '@fresh-stack/frontend-commons';
import {
  compact,
  formatCurrencyAmount,
  zip,
} from '@fresh-stack/fullstack-commons';
import { Box, Grid, Paper, Stack, Tooltip, Typography } from '@mui/material';
import { ComputedDatum, DatumId, ResponsivePie } from '@nivo/pie';
import { format } from 'date-fns';
import { useSnackbar } from 'notistack';
import { useMemo, useRef, useState } from 'react';
import { DownloadElementAsPngButton } from './DownloadElementAsPngButton';

const CustomPieArcTooltip =
  (currencyCode: string) =>
  ({
    datum,
  }: {
    readonly datum: {
      readonly id: DatumId;
      readonly label: DatumId;
      readonly value: number;
    };
  }) => (
    <Paper
      sx={{
        padding: 2,
        fontFamily: FONTS.latoBold,
        background: appColors.secondary,
      }}
    >
      <strong>{datum.label}</strong>
      <div>
        {formatCurrencyAmount({ currency: currencyCode, value: datum.value })}
      </div>
    </Paper>
  );

export const AttributeBreakdownPie = ({
  title,
  selectedMonth,
  selectedCurrencyCode,
  selectedAttribute,
  data,
  height,
}: {
  readonly title: string;
  readonly selectedMonth: Date;
  readonly selectedCurrencyCode: string;
  readonly selectedAttribute: CustomFieldDefinition;
  readonly data: {
    readonly id: string;
    readonly label: string;
    readonly value: number;
  }[];
  readonly height: number;
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const chartRef = useRef<HTMLDivElement | null>(null);
  const [filteredIds, setFilteredIds] = useState<{
    readonly ids: string[];
  }>({
    ids: [],
  });

  const colorPaired = useMemo(
    () =>
      compact(
        zip(
          getColorPalette(selectedAttribute.values.length),
          selectedAttribute.values,
        ).map((x) => ({
          color: x[0],
          id: x[1]?.valueId ?? '',
        })),
      ),
    [selectedAttribute.values],
  );

  const fullTitle = `${title} by ${selectedAttribute.className} (${format(selectedMonth, 'MMM yyyy')})`;

  const filteredData = useMemo(() => {
    return data.filter((x) => !filteredIds.ids.includes(x.id));
  }, [filteredIds, data]);

  const colors = useMemo(
    () =>
      compact(
        filteredData.map((d) => colorPaired.find((c) => c.id === d.id)?.color),
      ),
    [colorPaired, filteredData],
  );

  const handleClick = (itemId: string) => {
    if (filteredIds.ids.includes(itemId)) {
      setFilteredIds({
        ids: filteredIds.ids.filter((id) => id !== itemId),
      });
    } else if (filteredData.length > 1) {
      setFilteredIds({
        ids: [...filteredIds.ids, itemId],
      });
    }
  };

  return (
    <Box height={height}>
      <Grid
        container
        item
        xs={12}
        pl={1.5}
        pt={1}
        pr={1}
        alignItems={'center'}
        justifyContent={'space-between'}
      >
        <Typography variant="subtitle1">{fullTitle}</Typography>
        <DownloadElementAsPngButton
          disabled={false}
          filename={fullTitle}
          elementRef={chartRef}
          variant="contained"
          onError={() =>
            enqueueSnackbar({
              variant: 'error',
              message:
                'There was an issue generating the file, please try again later or in a different browser!',
            })
          }
        />
      </Grid>
      {data.length ? (
        <div
          style={{ position: 'relative', height: height - 70 }}
          ref={chartRef}
        >
          <Box
            position="absolute" // Use absolute positioning
            top={0} // Align to the top of the parent div
            left={10} // Align to the left of the parent div
            mt={2} // Optional: add some margin to the top if needed
            style={{ zIndex: 10 }} // Ensure it appears above the pie chart
            width={170}
          >
            <Stack spacing={1} maxHeight={height - 90} overflow={'auto'}>
              {data.map((item) => (
                <Box
                  display={'flex'}
                  alignItems={'center'}
                  gap={1}
                  sx={{
                    cursor: 'pointer',
                    '&:hover': {
                      backgroundColor: 'rgba(0, 0, 0, 0.01)', // subtle hover effect
                    },
                  }}
                  onClick={() => handleClick(item.id)}
                >
                  <Box
                    height={12}
                    width={12}
                    borderRadius={5}
                    bgcolor={colorPaired.find((x) => x.id === item.id)?.color}
                  />
                  <Tooltip title={item.label} placement="right">
                    <Typography
                      style={{
                        width: 'fit-content',
                        maxWidth: 150,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        textDecoration: filteredIds.ids.includes(item.id)
                          ? 'line-through'
                          : 'none',
                      }}
                    >
                      {item.label}
                    </Typography>
                  </Tooltip>
                </Box>
              ))}
            </Stack>
          </Box>
          <ResponsivePie
            data={filteredData}
            margin={{ top: 30, right: 10, bottom: 25, left: 50 }}
            innerRadius={0.8}
            padAngle={0.7}
            cornerRadius={5}
            colors={colors}
            animate={false}
            tooltip={CustomPieArcTooltip(selectedCurrencyCode)}
            onClick={(input) => handleClick(input.id.toString())}
            arcLinkLabel={(
              input: ComputedDatum<{
                readonly id: string;
                readonly label: string;
                readonly value: number;
              }>,
            ) => input.label.toString()}
            arcLabel={(value) =>
              formatCurrencyAmount({
                currency: selectedCurrencyCode,
                value: value.value,
              })
            }
          />
        </div>
      ) : (
        <Box
          display="flex"
          width={'100%'}
          height={'100%'}
          justifyContent={'center'}
          alignItems={'center'}
        >
          <Typography variant="h5" color="silver" mt={-5}>
            No data available for {format(selectedMonth, 'MMM yyyy')}
          </Typography>
        </Box>
      )}
    </Box>
  );
};
