import {
  FONTS,
  useSafeLog,
  wrapMutation,
  wrapQuery,
} from '@fresh-stack/frontend-commons';
import {
  AsyncResult,
  inspectError,
  inspectSuccess,
  match,
  pipe,
} from '@fresh-stack/fullstack-commons';
import {
  ReqCreateTxAttribute,
  ReqDeleteTxAttribute,
  ReqUpdateTxAttribute,
  TxAttributeDto,
} from '@fresh-stack/router/types';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import {
  Alert,
  Button,
  Container,
  IconButton,
  Link,
  Stack,
  Typography,
} from '@mui/material';
import {
  DataGridPro,
  GridColDef,
  GridRenderCellParams,
  GridRowOrderChangeParams,
  GridValidRowModel,
} from '@mui/x-data-grid-pro';
import { useSnackbar } from 'notistack';
import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { trpc } from '../../utils';
import { CreateAttributeDialog } from './AttributeDialogs/CreateAttributeDialog';
import { DeleteAttributeDialog } from './AttributeDialogs/DeleteAttributeDialog';
import { UpdateAttributeDialog } from './AttributeDialogs/UpdateAttributeDialog';

export const QUERY_PARAMS = {
  openModal: {
    key: 'open_modal',
    values: { createTxAttr: 'create_tx_attr' },
  },
} as const;

export interface AttributeRow extends GridValidRowModel {
  readonly id: string;
  readonly name: string;
  readonly options: string[];
  readonly appliesToCount: number[];
}

export const AttributeSettingsTab = () => {
  const safeLog = useSafeLog();
  const { enqueueSnackbar } = useSnackbar();

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const openModalParam = searchParams.get(QUERY_PARAMS.openModal.key);
    if (openModalParam === QUERY_PARAMS.openModal.values.createTxAttr) {
      setOpenCreateTxAttribute(true);
    }
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.delete(QUERY_PARAMS.openModal.key);
    setSearchParams(newSearchParams);
  }, [searchParams, setSearchParams]);

  const {
    result: listCustomFields,
    isFetching: listCustomFieldsFetching,
    refetch: refetchCustomFields,
  } = wrapQuery(
    trpc.banking.transactionAttribute.listCustomFieldsWithTransactionCount.useQuery(
      undefined,
      {
        refetchOnWindowFocus: false,
      },
    ),
  );

  const refetchAttributes = () => refetchCustomFields();

  const txRows: AttributeRow[] = useMemo(() => {
    if (!listCustomFields) {
      return [];
    }

    return pipe(
      listCustomFields,
      match(
        (attributes) =>
          attributes.map((c, index) => ({
            id: c.attributeId,
            name: c.name,
            options: c.options.map((o) => o.name),
            appliesToCount: c.options.map((a) => a.txCount),
            index,
            __reorder__: c.name,
          })),
        (_err) => {
          enqueueSnackbar({
            variant: 'error',
            message:
              'There was an issue loading your data! Please refresh and try again.',
          });
          return [];
        },
      ),
    );
  }, [enqueueSnackbar, listCustomFields]);

  const handleRowOrderChange = async (params: GridRowOrderChangeParams) => {
    const newRows = [...txRows];
    const row = newRows.splice(params.oldIndex, 1)[0];
    newRows.splice(params.targetIndex, 0, row);
    reorderAttributes(newRows);
  };

  const { mutateAsync: reorderTxAttributes, isLoading: isReordering } =
    wrapMutation(trpc.banking.transactionAttribute.reorder.useMutation());

  const reorderAttributes = async (rows: AttributeRow[]) => {
    safeLog(
      'reordering rules',
      rows.map((r) => r.id),
    );
    pipe(
      await reorderTxAttributes({ attributeIds: rows.map((r) => r.id) }),
      inspectError((err) => {
        safeLog('error when reordering custom fields', err);
        enqueueSnackbar({
          message: 'Error when reordering custom fields',
          variant: 'error',
        });
      }),
      inspectSuccess(() => {
        enqueueSnackbar({
          message: 'Custom fields reordered',
          variant: 'success',
        });
        refetchAttributes();
      }),
    );
  };

  const [updatingTxAttributeId, setUpdatingTxAttributeId] = React.useState<
    string | undefined
  >(undefined);

  const { mutateAsync: createTxAttribute, isLoading: txAttributeIsCreating } =
    wrapMutation(trpc.banking.transactionAttribute.create.useMutation());

  const { result: txAttributeResult, isLoading: txAttributeIsLoading } =
    wrapQuery(
      trpc.banking.transactionAttribute.get.useQuery(
        {
          attributeId: updatingTxAttributeId!,
        },
        {
          enabled: !!updatingTxAttributeId,
        },
      ),
    );
  const [openCreateTxAttribute, setOpenCreateTxAttribute] =
    React.useState(false);

  const { mutateAsync: updateTxAttribute, isLoading: txAttributeIsUpdating } =
    wrapMutation(trpc.banking.transactionAttribute.updateAttr.useMutation());

  const dbTxAttribute = useMemo(() => {
    if (!txAttributeResult || !updatingTxAttributeId) {
      return undefined;
    }
    return pipe(
      txAttributeResult,
      match(
        (data) => data,
        (_err) => {
          enqueueSnackbar({
            variant: 'error',
            message:
              'We ran into an issue getting this field, please try again!',
          });
          return undefined;
        },
      ),
    );
  }, [txAttributeResult, updatingTxAttributeId, enqueueSnackbar]);

  const { mutateAsync: deleteTxAttribute } = wrapMutation(
    trpc.banking.transactionAttribute.delete.useMutation(),
  );

  return (
    <Stack spacing={2}>
      <AttributeSettingsSection
        sectionDescription={
          <>
            Categorise your <u>Transactions</u> using custom fields to enable
            analysis and aggregation of your transactions by these fields across
            Ribon.
            <br /> e.g. "PnL line item" as a custom field with the options:
            Revenues, COGS, Marketing, Logistics and Overhead.
          </>
        }
        rows={txRows}
        entityName="transaction"
        refetchAttributes={refetchCustomFields}
        handleRowOrderChange={handleRowOrderChange}
        tableDataFetching={listCustomFieldsFetching || isReordering}
        createAttribute={createTxAttribute}
        attributeIsCreating={txAttributeIsCreating}
        updateAttribute={updateTxAttribute}
        attributeIsUpdating={txAttributeIsUpdating}
        deleteAttribute={deleteTxAttribute}
        setAttributeIdToUpdate={setUpdatingTxAttributeId}
        dbAttributeIsLoading={txAttributeIsLoading}
        attributeIdToUpdate={updatingTxAttributeId}
        openCreateAttribute={openCreateTxAttribute}
        setOpenCreateAttribute={setOpenCreateTxAttribute}
        dbAttributeToEdit={dbTxAttribute}
      />
    </Stack>
  );
};

const AttributeSettingsSection = ({
  sectionDescription,
  rows,
  entityName,
  refetchAttributes,
  tableDataFetching,
  createAttribute,
  attributeIsCreating,
  updateAttribute,
  attributeIsUpdating,
  deleteAttribute,
  attributeIdToUpdate,
  setAttributeIdToUpdate,
  openCreateAttribute,
  setOpenCreateAttribute,
  handleRowOrderChange,
  dbAttributeToEdit,
  dbAttributeIsLoading,
}: {
  readonly sectionDescription: ReactElement;
  readonly rows: readonly AttributeRow[];
  readonly entityName: string;
  readonly refetchAttributes: () => void;
  readonly tableDataFetching: boolean;
  readonly createAttribute: (
    req: ReqCreateTxAttribute,
  ) => AsyncResult<{ readonly id: string }, string>;
  readonly attributeIsCreating: boolean;
  readonly openCreateAttribute: boolean;
  readonly setOpenCreateAttribute: (open: boolean) => void;
  readonly dbAttributeToEdit: TxAttributeDto | undefined;
  readonly dbAttributeIsLoading: boolean;
  readonly updateAttribute: (
    req: ReqUpdateTxAttribute,
  ) => AsyncResult<'success', string>;
  readonly attributeIsUpdating: boolean;
  readonly deleteAttribute: (
    req: ReqDeleteTxAttribute,
  ) => AsyncResult<'success', string>;
  readonly attributeIdToUpdate: string | undefined;
  readonly setAttributeIdToUpdate: (id: string | undefined) => void;
  readonly handleRowOrderChange: (params: GridRowOrderChangeParams) => void;
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const [deleteAttributeRow, setDeleteAttributeRow] = useState<
    AttributeRow | undefined
  >(undefined);

  const [expandedRowIds, setExpandedRowIds] = useState(new Set<string>());

  const setErrorMessage = (message: string) =>
    enqueueSnackbar({ message, variant: 'error' });

  const renderMaybeHiddenList = (
    rowId: string,
    values: (string | number)[],
    renderValue: (v: string | number) => string,
  ) => {
    const showMax = expandedRowIds.has(rowId) ? undefined : 3;
    const showAll = showMax === undefined || values.length <= showMax;
    return (
      <Stack spacing={0}>
        {values.slice(0, showMax).map((value, index) => (
          <Typography key={`${rowId}-${value}-${index}`} variant="body2">
            {renderValue(value)}
          </Typography>
        ))}
        {!showAll && (
          <Typography variant="body2" marginTop={'4px'}>
            <Link
              component="button"
              onClick={() => {
                const newExpandedRowIds = new Set(expandedRowIds);
                newExpandedRowIds.add(rowId);
                setExpandedRowIds(newExpandedRowIds);
              }}
            >
              {values.length - showMax} more...
            </Link>
          </Typography>
        )}
      </Stack>
    );
  };

  const columns: readonly GridColDef<AttributeRow>[] = [
    {
      field: 'name',
      headerName: 'Custom field',
      flex: 1,
    },
    {
      field: 'options',
      headerName: 'Options',
      flex: 1,
      renderCell: ({ row }: GridRenderCellParams) =>
        renderMaybeHiddenList(row.id, row.options, String),
    },
    {
      field: 'appliesToCount',
      headerName: 'Applied to',
      flex: 1,
      renderCell: ({ row }) =>
        renderMaybeHiddenList(
          row.id,
          row.appliesToCount,
          (value) => `${value} ${entityName}${value === 1 ? '' : 's'}`,
        ),
    },
    {
      field: 'actions',
      headerName: '',
      width: 100,
      disableColumnMenu: true,
      filterable: false,
      hideSortIcons: true,
      renderCell: ({ row }) => {
        return (
          <>
            <IconButton onClick={() => setAttributeIdToUpdate(row.id)}>
              <EditIcon />
            </IconButton>
            <IconButton onClick={() => setDeleteAttributeRow(row)}>
              <DeleteIcon />
            </IconButton>
          </>
        );
      },
    },
  ];
  return (
    <Stack alignItems={'flex-start'}>
      <CreateAttributeDialog
        open={openCreateAttribute}
        onClose={() => setOpenCreateAttribute(false)}
        onSuccess={() => {
          setOpenCreateAttribute(false);
          enqueueSnackbar({
            message: 'Custom field created!',
            variant: 'success',
          });
          refetchAttributes();
        }}
        existingAttributeNames={rows.map((row) => row.name)}
        createAttribute={createAttribute}
        attributeIsSaving={attributeIsCreating}
        setErrorMessage={setErrorMessage}
      />
      {!!attributeIdToUpdate && (
        <UpdateAttributeDialog
          onClose={() => setAttributeIdToUpdate(undefined)}
          onSuccess={() => {
            setAttributeIdToUpdate(undefined);
            enqueueSnackbar({
              message: 'Custom field updated!',
              variant: 'success',
            });
            refetchAttributes();
          }}
          updateAttribute={updateAttribute}
          existingAttributeNames={rows.map((row) => row.name)}
          dbAttribute={dbAttributeToEdit}
          attributeIsLoading={dbAttributeIsLoading}
          attributeId={attributeIdToUpdate}
          attributeIsUpdating={attributeIsUpdating}
        />
      )}
      {!!deleteAttributeRow && (
        <DeleteAttributeDialog
          onClose={() => setDeleteAttributeRow(undefined)}
          onSuccess={() => {
            setDeleteAttributeRow(undefined);
            enqueueSnackbar({
              variant: 'success',
              message: 'Custom field deleted!',
            });
            refetchAttributes();
          }}
          deleteAttribute={deleteAttribute}
          attribute={deleteAttributeRow}
          setErrorMessage={setErrorMessage}
        />
      )}
      <Container>
        <Stack
          padding={2}
          pt={0}
          alignItems={'center'}
          width={'100%'}
          maxWidth={1600}
        >
          <Stack
            spacing={2}
            alignContent={'flex-start'}
            width={'100%'}
            maxWidth={800}
          >
            <Alert variant="outlined" severity="info">
              {sectionDescription}
            </Alert>
            <Stack spacing={2} alignItems={'flex-start'} width={'100%'}>
              <DataGridPro
                disableColumnFilter
                disableColumnMenu
                rowReordering
                onRowOrderChange={handleRowOrderChange}
                getRowHeight={() => 'auto'}
                sx={{
                  boxShadow: 1,
                  padding: 1,
                  width: '100%',
                  maxHeight: '60vh',
                }}
                autoHeight
                rows={rows}
                columns={columns}
                initialState={{
                  pagination: { paginationModel: { pageSize: 25 } },
                }}
                loading={tableDataFetching}
                slots={{
                  noRowsOverlay: () => (
                    <Stack
                      alignItems={'center'}
                      justifyContent={'center'}
                      width={'100%'}
                      height={'100%'}
                      sx={{ minHeight: '60px' }}
                    >
                      <Typography variant="body1" fontFamily={FONTS.inter}>
                        No custom fields found
                      </Typography>
                    </Stack>
                  ),
                }}
              />
              <Button
                variant="contained"
                startIcon={<AddIcon />}
                onClick={() => setOpenCreateAttribute(true)}
                sx={{ maxWidth: '100px', alignSelf: 'flex-end' }}
              >
                Create
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </Container>
    </Stack>
  );
};
