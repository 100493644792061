import {
  Box,
  Button,
  Dialog,
  Grid,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import MuiPhoneNumber from 'mui-phone-number';

import {
  DialogFadeTransition,
  wrapMutation,
} from '@fresh-stack/frontend-commons';
import { isSuccess } from '@fresh-stack/fullstack-commons';
import { ReqUpdateUserDetails } from '@fresh-stack/router/types';
import { zodResolver } from '@hookform/resolvers/zod';
import { LoadingButton } from '@mui/lab';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useSnackbar } from 'notistack';
import { Controller, useForm } from 'react-hook-form';
import z from 'zod';
import { trpc } from '../../utils';

const SchemaUpdateUserDetails = z.object({
  firstName: z.string().min(1, 'Please provide a first name.'),
  lastName: z.string().min(1, 'Please provide a last name.'),
  cellphoneNumber: z.string().optional(),
});

export const UpdateUserDetailsDialog = ({
  isOpen,
  firstName,
  lastName,
  cellphoneNumber,
  onClose,
  onSuccess,
}: {
  readonly isOpen: boolean;
  readonly firstName: string | undefined;
  readonly lastName: string | undefined;
  readonly cellphoneNumber: string | undefined;
  readonly onClose: () => void;
  readonly onSuccess: () => void;
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const {
    register,
    formState: { errors, isDirty },
    handleSubmit,
    watch,
    control,
  } = useForm<ReqUpdateUserDetails>({
    resolver: zodResolver(SchemaUpdateUserDetails),
    defaultValues: {
      firstName,
      lastName,
      cellphoneNumber,
    },
  });

  const cellphone = watch('cellphoneNumber');

  const { mutateAsync: saveUser, isLoading } = wrapMutation(
    trpc.organisation.updateUserDetails.useMutation(),
  );

  const onSave = async (user: ReqUpdateUserDetails) => {
    const result = await saveUser(user);
    if (isSuccess(result)) {
      onSuccess();
    } else {
      enqueueSnackbar({
        variant: 'error',
        message: 'There was an issue updating your details, please try again!',
      });
    }
  };

  return (
    <Dialog
      open={isOpen}
      TransitionComponent={DialogFadeTransition}
      onClose={onClose}
      keepMounted={false}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>
        <Typography variant="h5" fontWeight={500}>
          Update your details
        </Typography>
      </DialogTitle>
      <form onSubmit={handleSubmit(onSave)}>
        <DialogContent>
          <Stack spacing={3} mt={3}>
            <Grid item container xs={12}>
              <Grid container item xs={6} pr={1}>
                <TextField
                  id="firstName"
                  label="First name"
                  placeholder="Jane"
                  {...register('firstName')}
                  color="primary"
                  fullWidth
                  error={!!errors.firstName}
                  helperText={
                    errors.firstName ? (
                      <Box>{errors.firstName.message}</Box>
                    ) : null
                  }
                ></TextField>
              </Grid>
              <Grid container item xs={6} pl={1}>
                <TextField
                  id="lastName"
                  label="Last name"
                  placeholder="Smith"
                  {...register('lastName')}
                  color="primary"
                  fullWidth
                  error={!!errors.lastName}
                  helperText={
                    errors.lastName ? (
                      <Box>{errors.lastName.message}</Box>
                    ) : null
                  }
                ></TextField>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                control={control}
                name={'cellphoneNumber'}
                render={({ field: { onChange }, fieldState: { error } }) => (
                  <MuiPhoneNumber
                    fullWidth
                    value={cellphone}
                    defaultCountry={'de'}
                    name={'cellphoneNumber'}
                    variant="outlined"
                    onChange={onChange}
                    error={!!error?.message}
                    helperText={error?.message && <Box>{error.message}</Box>}
                  />
                )}
              />
            </Grid>
          </Stack>
        </DialogContent>
        <DialogActions sx={{ padding: 4 }}>
          <LoadingButton
            type="submit"
            variant="contained"
            color="success"
            loading={isLoading}
            disabled={!isDirty}
          >
            Update
          </LoadingButton>
          <Button
            onClick={onClose}
            variant="contained"
            color="error"
            disabled={isLoading}
          >
            Cancel
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
