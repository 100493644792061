import {
  AccountSummary,
  CustomFieldDefinition,
  FONTS,
  isDepositToTransactionType,
  transactionTypeToIsDeposit,
  useQueryState,
  useSafeLog,
  wrapMutation,
} from '@fresh-stack/frontend-commons';
import {
  ALL,
  compact,
  head,
  inspectError,
  inspectSuccess,
  isEqual,
  pipe,
  uniq,
} from '@fresh-stack/fullstack-commons';
import {
  AutomationRuleSetDto,
  CreateRuleSetDto,
  RuleFilter,
  UpdateRuleDto,
} from '@fresh-stack/router/types';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InfoIcon from '@mui/icons-material/Info';
import SaveIcon from '@mui/icons-material/Save';
import { LoadingButton } from '@mui/lab';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Switch,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  DataGridPro,
  GridColDef,
  GridRenderCellParams,
  GridRowOrderChangeParams,
  gridClasses,
} from '@mui/x-data-grid-pro';
import { enqueueSnackbar } from 'notistack';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { ROUTES } from '../../app/routes';
import { trpc } from '../../utils';
import { DeleteRuleDialog } from './DeleteRuleDialog';
import { ReadonlyRuleFilterView } from './ReadonlyRuleFilterView';
import { RuleDialog } from './RuleDialog';
import { ACTION_HEADER, CONDITION_HEADER } from './common';
import { unstable_usePrompt, useBeforeUnload } from 'react-router-dom';

const FIELD_NAME_SELECTED_FIELD_ID = 'selectedCustomFieldId';

export const buildTransactionRulesWithSelectedFieldPath = (
  customFieldId: string,
) => {
  return `${ROUTES.Commons.TransactionRules}?${FIELD_NAME_SELECTED_FIELD_ID}=${customFieldId}`;
};
export const AutomationRuleEditor = ({
  allAccounts,
  serverRules,
  customFields,
  refetchRules,
}: {
  readonly allAccounts: AccountSummary[];
  readonly serverRules: AutomationRuleSetDto[];
  readonly customFields: CustomFieldDefinition[];
  readonly refetchRules: () => void;
}) => {
  const safeLog = useSafeLog();

  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const [editingRule, setEditingRule] = useState<
    AutomationRuleSetDto | undefined
  >(undefined);

  const [deletingRule, setDeletingRule] = useState<
    AutomationRuleSetDto | undefined
  >(undefined);

  const { mutateAsync: deleteRuleSetAsync, isLoading: isDeletingRuleSet } =
    wrapMutation(trpc.rules.deleteRuleSet.useMutation());

  const deleteRule = useCallback(
    async (id: string) => {
      safeLog('deleting rule', id);
      const deleteOperation = () => {
        return deleteRuleSetAsync(id);
      };
      pipe(
        await deleteOperation(),
        inspectError((err) => {
          safeLog('error when deleting rule', err);
          enqueueSnackbar({
            message: 'Error when deleting rule',
            variant: 'error',
          });
        }),
        inspectSuccess(() => {
          enqueueSnackbar({
            message: 'Rule deleted',
            variant: 'success',
          });
          refetchRules();
        }),
      );
    },
    [safeLog, deleteRuleSetAsync, refetchRules],
  );

  const { mutateAsync: createRuleSetAsync, isLoading: isCreatingRuleSet } =
    wrapMutation(trpc.rules.createRuleSet.useMutation());

  const createRule = async (rule: CreateRuleSetDto) => {
    safeLog('creating rule', rule);

    pipe(
      await createRuleSetAsync({
        filter: rule.filter,
        action: rule.action,
      }),
      inspectError((err) => {
        safeLog('error when creating rule', err);
        enqueueSnackbar({
          message: 'Error when creating rule',
          variant: 'error',
        });
      }),
      inspectSuccess(() => {
        setCreateDialogOpen(false);
        enqueueSnackbar({
          message: 'Rule created',
          variant: 'success',
        });
        refetchRules();
      }),
    );
  };

  const { mutateAsync: updateRuleSetAsync, isLoading: isUpdatingRuleSet } =
    wrapMutation(trpc.rules.updateRuleSet.useMutation());

  const updateRule = async (rule: UpdateRuleDto) => {
    safeLog('updating rule', rule);

    pipe(
      await updateRuleSetAsync(rule),
      inspectError((err) => {
        safeLog('error when updating rule', err);
        enqueueSnackbar({
          message: 'Error when updating rule',
          variant: 'error',
        });
      }),
      inspectSuccess(() => {
        setEditingRule(undefined);
        enqueueSnackbar({
          message: 'Rule updated',
          variant: 'success',
        });
        refetchRules();
      }),
    );
  };

  const {
    mutateAsync: setRuleSetActiveAsync,
    isLoading: isSettingRuleSetActive,
  } = wrapMutation(trpc.rules.setRuleSetActive.useMutation());

  const setRuleActive = useCallback(
    async (ruleId: string, active: boolean) => {
      const activeOperation = () => {
        return setRuleSetActiveAsync({
          id: ruleId,
          active,
        });
      };
      safeLog('setting rule active', ruleId, active);
      pipe(
        await activeOperation(),
        inspectError((err) => {
          safeLog('error when setting rule active', err);
          enqueueSnackbar({
            message: 'Error when setting rule active',
            variant: 'error',
          });
        }),
        inspectSuccess(() => {
          enqueueSnackbar({
            message: `Rule ${active ? 'activated' : 'deactivated'}`,
            variant: 'success',
          });
          refetchRules();
        }),
      );
    },
    [refetchRules, safeLog, setRuleSetActiveAsync],
  );

  const allCurrencies = useMemo(
    () => uniq(compact([ALL, ...allAccounts.map((x) => x.currency)])),
    [allAccounts],
  );

  const [selectedCustomFieldId, setSelectedCustomFieldId] = useQueryState<
    string | undefined
  >({
    fieldName: 'selectedCustomFieldId',
    defaultValue: head(customFields)?.classId,
    deserialize: (x) => x || head(customFields)?.classId,
    serialize: (x) => x ?? '',
  });

  const selectedCustomField = customFields.find(
    (x) => !selectedCustomFieldId || x.classId === selectedCustomFieldId,
  );

  const serverRuleRows = useMemo(
    () =>
      serverRules
        .filter(
          (rule) =>
            rule.action.setAttributeValue.attributeId ===
            selectedCustomField?.classId,
        )
        .map((rule) => ({
          ...rule,
          __reorder__: rule.action.setAttributeValue.valueName,
        })),
    [serverRules, selectedCustomField],
  );

  const [rows, setRows] = useState(serverRuleRows);

  useEffect(() => {
    setRows(serverRuleRows);
  }, [serverRuleRows]);

  const handleRowOrderChange = async (params: GridRowOrderChangeParams) => {
    const newRows = [...rows];
    const row = newRows.splice(params.oldIndex, 1)[0];
    newRows.splice(params.targetIndex, 0, row);
    setRows(newRows);
  };

  const hasReordering = useMemo(
    () => !isEqual(serverRuleRows, rows),
    [rows, serverRuleRows],
  );

  const { mutateAsync: reorderRuleSetsAsync, isLoading: isReorderingRuleSets } =
    wrapMutation(trpc.rules.reorderRuleSets.useMutation());

  const reorderRules = async (rows: AutomationRuleSetDto[]) => {
    safeLog('reordering rules', rows);
    const reorderOperation = () => {
      return reorderRuleSetsAsync({
        ruleIds: rows.map((x) => x.id),
      });
    };
    pipe(
      await reorderOperation(),
      inspectError((err) => {
        safeLog('error when reordering rules', err);
        enqueueSnackbar({
          message: 'Error when reordering rules',
          variant: 'error',
        });
      }),
      inspectSuccess(() => {
        enqueueSnackbar({
          message: 'Rules reordered',
          variant: 'success',
        });
        refetchRules();
      }),
    );
  };

  const columns: GridColDef<AutomationRuleSetDto>[] = useMemo(
    () => [
      {
        field: 'active',
        headerName: 'Active',
        sortable: false,
        width: 70,
        renderCell: (params) => (
          <Tooltip
            title={hasReordering ? 'Save or cancel edits to change' : ''}
          >
            <span>
              <Switch
                checked={params.value}
                onChange={async () => {
                  await setRuleActive(params.row.id, !params.value);
                }}
                disabled={isSettingRuleSetActive || hasReordering}
              />
            </span>
          </Tooltip>
        ),
        align: 'center',
      },
      {
        field: 'id',
        headerName: 'Priority',
        width: 70,
        disableColumnMenu: true,
        sortable: false,
        // 1-indexed row number:
        renderCell: (index) =>
          index.api.getRowIndexRelativeToVisibleRows(index.row.id) + 1,
        align: 'center',
      },
      {
        field: 'filter',
        headerName: CONDITION_HEADER,
        sortable: false,
        flex: 4,
        renderCell: (
          params: GridRenderCellParams<AutomationRuleSetDto, RuleFilter>,
        ) => (
          <Box pt={1} pb={1}>
            {params.value ? (
              <ReadonlyRuleFilterView
                filter={params.value}
                allAccounts={allAccounts}
              />
            ) : null}
          </Box>
        ),
      },
      {
        field: 'action',
        headerName: ACTION_HEADER,
        sortable: false,
        valueFormatter: (params) => params.value.setAttributeValue.valueName,
        flex: 1,
      },
      {
        field: 'actions',
        headerName: '',
        width: 100,
        sortable: false,
        renderCell: ({ row }) => {
          return (
            <>
              <Tooltip
                title={
                  hasReordering ? 'Save or cancel edits to edit a rule' : ''
                }
              >
                <span>
                  <IconButton
                    disabled={hasReordering}
                    onClick={() => setEditingRule(row)}
                  >
                    <EditIcon />
                  </IconButton>
                </span>
              </Tooltip>
              <Tooltip
                title={
                  hasReordering ? 'Save or cancel edits to delete a rule' : ''
                }
              >
                <span>
                  <IconButton
                    disabled={hasReordering}
                    onClick={() => {
                      safeLog('deleting rule', row.id);
                      setDeletingRule(row);
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </span>
              </Tooltip>
            </>
          );
        },
      },
    ],
    [
      hasReordering,
      isSettingRuleSetActive,
      setRuleActive,
      allAccounts,
      safeLog,
    ],
  );

  // Covers navigations within React Router
  unstable_usePrompt({
    when: hasReordering,
    message: 'You have unsaved changes. Are you sure you want to leave?',
  });
  // Covers navigations outside of React Router
  useBeforeUnload((event) => {
    if (hasReordering) event.preventDefault();
  });

  return (
    <>
      {createDialogOpen && selectedCustomField && (
        <RuleDialog
          onClose={() => setCreateDialogOpen(false)}
          onSave={async (row) =>
            createRule({
              filter: {
                isoCurrencyCode:
                  row.filter.isoCurrencyCode === ALL
                    ? undefined
                    : row.filter.isoCurrencyCode,
                accountIds: row.filter.accountIds,
                txNameContains: row.filter.txNameContains.map((x) => ({
                  value: x.value?.split(' ').filter(Boolean),
                })),
                isDeposit: transactionTypeToIsDeposit(
                  row.filter.transactionType,
                ),
                startDate: row.filter.startDate?.toISOString(),
                endDate: row.filter.endDate?.toISOString(),
              },
              action: {
                setAttributeValue: {
                  attributeId: row.customFieldId,
                  valueId: row.customFieldValueId,
                },
              },
            })
          }
          isSaving={isCreatingRuleSet}
          initialRuleFilter={{
            isoCurrencyCode: ALL,
            txNameContains: [],
            transactionType: ALL,
          }}
          availableCurrencies={allCurrencies}
          availableAccounts={allAccounts}
          dialogTitleText="Add new rule"
          customFields={customFields}
          editMode={{
            kind: 'fixed',
            initialCustomFieldId: selectedCustomField.classId,
            initialCustomFieldValueId: undefined,
          }}
        />
      )}
      {editingRule && (
        <RuleDialog
          onClose={() => setEditingRule(undefined)}
          onSave={async (form) =>
            updateRule({
              id: editingRule.id,
              filter: {
                isoCurrencyCode:
                  form.filter.isoCurrencyCode === ALL
                    ? undefined
                    : form.filter.isoCurrencyCode,
                accountIds: form.filter.accountIds,
                txNameContains: form.filter.txNameContains.map((x) => ({
                  databaseId: x.databaseId ?? undefined,
                  value: x.value?.split(' ').filter(Boolean),
                })),
                isDeposit: transactionTypeToIsDeposit(
                  form.filter.transactionType,
                ),
                startDate: form.filter.startDate?.toISOString(),
                endDate: form.filter.endDate?.toISOString(),
              },
              action: {
                setAttributeValue: {
                  attributeId: form.customFieldId,
                  valueId: form.customFieldValueId,
                },
              },
            })
          }
          isSaving={isUpdatingRuleSet}
          initialRuleFilter={{
            isoCurrencyCode: editingRule.filter.isoCurrencyCode ?? ALL,
            accountIds: editingRule.filter.accountIds,
            txNameContains:
              editingRule.filter.txNameContains?.map((x) => ({
                databaseId: x.databaseId ?? undefined,
                value: x.value.join(' '),
              })) ?? [],
            transactionType: isDepositToTransactionType(
              editingRule.filter.isDeposit,
            ),
            startDate: editingRule.filter.startDate
              ? new Date(editingRule.filter.startDate)
              : undefined,
            endDate: editingRule.filter.endDate
              ? new Date(editingRule.filter.endDate)
              : undefined,
          }}
          availableCurrencies={allCurrencies}
          availableAccounts={allAccounts}
          dialogTitleText="Edit rule"
          customFields={customFields}
          editMode={{
            kind: 'fixed',
            initialCustomFieldId:
              editingRule.action.setAttributeValue.attributeId,
            initialCustomFieldValueId:
              editingRule.action.setAttributeValue.valueId,
          }}
        />
      )}
      {deletingRule && (
        <DeleteRuleDialog
          onClose={() => setDeletingRule(undefined)}
          onSave={() => deleteRule(deletingRule.id)}
          isSaving={isDeletingRuleSet}
          ruleDefinition={{
            active: deletingRule.active,
            attributeValueName: deletingRule.action.setAttributeValue.valueName,
            attributeClassName:
              deletingRule.action.setAttributeValue.attributeName,

            filter: deletingRule.filter,
          }}
          allAccounts={allAccounts}
        />
      )}
      <Accordion
        sx={{
          mb: 3,
          boxShadow: 4,
        }}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Box display={'flex'} alignItems={'center'} gap={1}>
            <InfoIcon color="primary" fontSize="small" />
            <Typography variant="subtitle1" color="primary">
              How do automation rules work?
            </Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Stack spacing={1}>
            <Typography variant="body1" gutterBottom>
              Define rules to implement your custom workflows in Ribon. Rules
              automatically set custom fields on your transactions according to
              your definitions.
            </Typography>
            <Accordion
              sx={{
                boxShadow: 3,
              }}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="subtitle2">Priority</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant="body1" gutterBottom textAlign={'justify'}>
                  Priority defines the order in which rules are applied.
                  Priority 1 has the highest precedence. Drag and drop rules to
                  reorder them.
                </Typography>
                <Typography variant="subtitle2">Example</Typography>
                <Typography variant="body1" textAlign={'justify'}>
                  A priority 1 rule sets a custom field to "Marketing" for all
                  transactions with "Facebook" in the title. A priority 3 rule
                  sets the same field to "Operations" for all transactions from
                  a certain bank account. If a transaction from that bank
                  account has "Facebook" in the title, it will be set to
                  "Marketing" by the first rule, overriding the later rule.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion sx={{ boxShadow: 3 }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="subtitle2">Condition</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant="body1" textAlign={'justify'} gutterBottom>
                  Set the filter which defines the transactions which a rule
                  should apply to. Transactions matching all of these conditions
                  will have the custom field set, unless it is overridden by a
                  rule with a higher priority, or a manual override is set.
                </Typography>
                <Typography variant="body1" textAlign={'justify'}>
                  In order to set a rule to only apply to future transactions,
                  use the Start Date field.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion sx={{ boxShadow: 3 }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="subtitle2">Manual overrides</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant="body1" textAlign={'justify'}>
                  By updating custom field values in the transaction details
                  view, you create a manual override which takes precedence over
                  all rules.
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Stack>
        </AccordionDetails>
      </Accordion>
      <Stack
        direction={'row'}
        justifyContent={'space-between'}
        spacing={2}
        mt={1}
      >
        <Tooltip
          title={
            hasReordering ? 'Save or cancel edits to change custom field' : ''
          }
        >
          <FormControl disabled={hasReordering}>
            <InputLabel id="select-attribute-label" size="small">
              Custom field
            </InputLabel>
            <Select
              size="small"
              label="Custom field"
              sx={{ width: 200 }}
              onChange={(e) => setSelectedCustomFieldId(e.target.value)}
              value={selectedCustomFieldId}
            >
              {customFields.map((value) => (
                <MenuItem key={value.classId} value={value.classId}>
                  <Typography
                    style={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {value.className}
                  </Typography>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Tooltip>
        {(hasReordering || isReorderingRuleSets) && (
          // TODO: add an Alert explaining what's going on with ordering
          <Stack direction={'row'} spacing={1} alignItems={'center'}>
            <LoadingButton
              variant={'contained'}
              color={'primary'}
              size="small"
              onClick={() => reorderRules(rows)}
              loading={isReorderingRuleSets}
              startIcon={<SaveIcon />}
            >
              Save new order
            </LoadingButton>
            <Button
              variant={'outlined'}
              color={'primary'}
              size="small"
              onClick={() => refetchRules()}
              startIcon={<CloseIcon />}
              disabled={isReorderingRuleSets}
            >
              Cancel
            </Button>
          </Stack>
        )}
      </Stack>
      <Stack
        sx={{
          mt: 1,
          mb: 1,
          flex: 'grow',
        }}
      >
        {selectedCustomField && serverRuleRows.length ? (
          <DataGridPro
            disableColumnFilter
            disableColumnMenu
            rowReordering
            onRowOrderChange={handleRowOrderChange}
            getRowHeight={() => 'auto'}
            sx={{
              boxShadow: 4,
              padding: 1,
              width: '100%',
              [`& .${gridClasses.columnHeaderTitle}`]: {
                fontWeight: 'bold',
              },
              maxHeight: '50vh',
            }}
            rows={serverRuleRows}
            columns={columns}
            slots={{
              noRowsOverlay: () => (
                <Stack
                  alignItems={'center'}
                  justifyContent={'center'}
                  width={'100%'}
                  height={'100%'}
                  sx={{ minHeight: '60px' }}
                >
                  <Typography variant="body1" fontFamily={FONTS.inter}>
                    No rules found
                  </Typography>
                </Stack>
              ),
            }}
          />
        ) : (
          <Stack
            alignItems={'center'}
            justifyContent={'center'}
            width={'100%'}
            height={'100%'}
            sx={{ minHeight: '60px' }}
            boxShadow={3}
            borderRadius={2}
          >
            <Typography variant="subtitle1" color="GrayText">
              No rules found for '{selectedCustomField?.className}'.
            </Typography>
          </Stack>
        )}
      </Stack>
      <Stack
        direction={'row'}
        justifyContent={'flex-end'}
        alignItems={'center'}
        pt={1}
      >
        <Tooltip
          title={hasReordering ? 'Save or cancel edits to create new rule' : ''}
        >
          <span>
            <Button
              color="primary"
              variant="contained"
              size="small"
              onClick={() => setCreateDialogOpen(true)}
              disabled={hasReordering}
            >
              Add rule
            </Button>
          </span>
        </Tooltip>
      </Stack>
    </>
  );
};
