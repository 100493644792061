import {
  CustomFieldDefinition,
  appColors,
} from '@fresh-stack/frontend-commons';
import { formatCurrencyAmount } from '@fresh-stack/fullstack-commons';
import { Box } from '@mui/material';
import {
  DataGridPro,
  GridColDef,
  GridToolbarContainer,
  GridToolbarExport,
} from '@mui/x-data-grid-pro';
import { format } from 'date-fns';
import { ID_TOTAL } from './commons';

const columns = ({
  nameColumnHeader,
  refMonthColumnHeader,
  prevMonthColumnHeader,
  prevYearColumnHeader,
}: {
  readonly nameColumnHeader: string;
  readonly refMonthColumnHeader: string;
  readonly prevMonthColumnHeader: string;
  readonly prevYearColumnHeader: string;
}): GridColDef[] => [
  {
    field: 'name',
    headerName: nameColumnHeader,
    flex: 1,
    headerClassName: 'grid-theme--header',
    cellClassName: 'grid-theme--first-column',
  },
  {
    field: 'month',
    headerName: refMonthColumnHeader,
    flex: 1,
    disableColumnMenu: true,
    sortable: false,
    headerClassName: 'grid-theme--header',
  },
  {
    field: 'previousMonth',
    headerName: prevMonthColumnHeader,
    flex: 1,
    disableColumnMenu: true,
    sortable: false,
    headerClassName: 'grid-theme--header',
  },
  {
    field: 'previousYear',
    headerName: prevYearColumnHeader,
    flex: 1,
    disableColumnMenu: true,
    sortable: false,
    headerClassName: 'grid-theme--header',
  },
];

type ResMomAttributeBreakdownDto = {
  readonly currencyCode: string;
  readonly attributeClassId: string;
  readonly referenceMonth: {
    readonly month: string;
    readonly entries: {
      readonly attributeValueId: string;
      readonly amount: number;
      readonly isDeposit: boolean;
    }[];
  };
  readonly previousMonth: {
    readonly month: string;
    readonly entries: {
      readonly attributeValueId: string;
      readonly amount: number;
      readonly isDeposit: boolean;
    }[];
  };
  readonly previousYearMonth: {
    readonly month: string;
    readonly entries: {
      readonly attributeValueId: string;
      readonly amount: number;
      readonly isDeposit: boolean;
    }[];
  };
};

const CustomToolbar = () => {
  return (
    <GridToolbarContainer>
      <Box sx={{ flexGrow: 1 }} />
      <Box pr={2}>
        <GridToolbarExport />
      </Box>
    </GridToolbarContainer>
  );
};

export const AttributeBreakdownGrid = ({
  selectedAttribute,
  data,
  currencyCode,
  transactionType,
  height,
}: {
  readonly selectedAttribute: CustomFieldDefinition;
  readonly data: ResMomAttributeBreakdownDto;
  readonly currencyCode: string;
  readonly transactionType: 'Deposits' | 'Withdrawals';
  readonly height: number;
}) => {
  const rows = buildAttributeBreakdownTableRows({
    selectedAttribute,
    data,
    currencyCode,
    transactionType,
  });

  const name = transactionType === 'Deposits' ? 'Inflows' : 'Outflows';

  return (
    <DataGridPro
      sx={{
        width: '100%',
        height: height,
        '& .grid-theme--header': {
          backgroundColor: appColors.secondary,
          pl: 2,
        },
        '& .grid-theme--first-column': {
          pl: 2,
        },
      }}
      pagination={false}
      disableColumnFilter={true}
      hideFooterSelectedRowCount={true}
      hideFooter={true}
      slots={{ toolbar: CustomToolbar }}
      rows={rows}
      pinnedRows={{
        bottom: rows.filter((x) => x.id === ID_TOTAL),
      }}
      columns={columns({
        nameColumnHeader: name,
        refMonthColumnHeader: format(
          new Date(data.referenceMonth.month),
          'MMM yy',
        ),
        prevMonthColumnHeader: `vs. previous month (${format(new Date(data.previousMonth.month), 'MMM yy')})`,
        prevYearColumnHeader: `vs. previous year (${format(new Date(data.previousYearMonth.month), 'MMM yy')})`,
      })}
    />
  );
};

const calculateMom = ({
  currentMonth,
  previousMonth,
  currencyCode,
}: {
  readonly currentMonth: number;
  readonly previousMonth: number;
  readonly currencyCode: string;
}) => {
  const prevMonthDiff = currentMonth - previousMonth;

  const percSign = prevMonthDiff < 0 ? '-' : '+';
  const prevMonthPerc =
    previousMonth !== 0
      ? ` (${percSign}${(Math.abs(prevMonthDiff / previousMonth) * 100).toFixed(1)}%)`
      : '';

  return `${percSign}${formatCurrencyAmount({
    currency: currencyCode,
    value: Math.abs(prevMonthDiff),
  })}${prevMonthPerc}`;
};

export const buildAttributeBreakdownTableRows = ({
  currencyCode,
  selectedAttribute,
  data,
  transactionType,
}: {
  readonly currencyCode: string;
  readonly selectedAttribute: CustomFieldDefinition;
  readonly data: ResMomAttributeBreakdownDto;
  readonly transactionType: 'Deposits' | 'Withdrawals';
}) => {
  const rows: {
    readonly id: string;
    readonly name: string;
    readonly month: string;
    readonly previousMonth: string;
    readonly previousYear: string;
  }[] = [];

  // eslint-disable-next-line functional/no-let
  let totalCrtAmount = 0,
    totalPrevMonthAmount = 0,
    totalPrevYearAmount = 0;

  const isDeposit = transactionType === 'Deposits';

  for (const attributeValue of selectedAttribute.values) {
    const thisMonthValue = +(
      data.referenceMonth.entries.find(
        (x) =>
          x.attributeValueId === attributeValue.valueId &&
          x.isDeposit === isDeposit,
      )?.amount ?? 0
    );
    totalCrtAmount += thisMonthValue;

    const prevMonthValue = +(
      data.previousMonth.entries.find(
        (x) =>
          x.attributeValueId === attributeValue.valueId &&
          x.isDeposit === isDeposit,
      )?.amount ?? 0
    );
    totalPrevMonthAmount += prevMonthValue;

    const prevYearValue = +(
      data.previousMonth.entries.find(
        (x) =>
          x.attributeValueId === attributeValue.valueId &&
          x.isDeposit === isDeposit,
      )?.amount ?? 0
    );
    totalPrevYearAmount += prevYearValue;

    rows.push({
      id: attributeValue.valueId,
      name: attributeValue.valueName,
      month: formatCurrencyAmount({
        currency: currencyCode,
        value: thisMonthValue,
      }),
      previousMonth: calculateMom({
        currencyCode,
        currentMonth: thisMonthValue,
        previousMonth: prevMonthValue,
      }),
      previousYear: calculateMom({
        currencyCode,
        currentMonth: thisMonthValue,
        previousMonth: prevYearValue,
      }),
    });
  }

  rows.push({
    id: ID_TOTAL,
    name: 'Total',
    month: formatCurrencyAmount({
      value: totalCrtAmount,
      currency: currencyCode,
    }),
    previousMonth: calculateMom({
      currencyCode,
      currentMonth: totalCrtAmount,
      previousMonth: totalPrevMonthAmount,
    }),
    previousYear: calculateMom({
      currencyCode,
      currentMonth: totalCrtAmount,
      previousMonth: totalPrevYearAmount,
    }),
  });
  return rows;
};
