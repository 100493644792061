import { FONTS, useSafeLog, wrapQuery } from '@fresh-stack/frontend-commons';
import {
  ignoreError,
  inspectError,
  mapSuccess,
  match,
  pipe,
} from '@fresh-stack/fullstack-commons';
import { AutomationRuleSetDto } from '@fresh-stack/router/types';
import {
  Box,
  Button,
  Container,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useMemo } from 'react';
import { ROUTES } from '../../app/routes';
import { trpc } from '../../utils';
import { QUERY_PARAMS } from '../Account/AttributeSettingsTab';
import { AutomationRuleEditor } from './AutomationRuleEditor';

const NoAttributeContent = () => (
  <Stack spacing={2} alignItems="center" justifyContent="center" height="100%">
    <Typography variant="h5" fontFamily={FONTS.interBold} color="textSecondary">
      No custom fields found
    </Typography>
    <Typography
      variant="h6"
      fontFamily={FONTS.interBold}
      color="textSecondary"
      textAlign={'center'}
    >
      Create a custom field to enable automation rules
    </Typography>
    <Button
      href={
        ROUTES.Settings.Organisation.Attributes +
        `?${QUERY_PARAMS.openModal.key}=${QUERY_PARAMS.openModal.values.createTxAttr}`
      }
      variant="contained"
      color="primary"
    >
      Create custom field
    </Button>
  </Stack>
);

export const AutomationRulesTab = () => {
  const { enqueueSnackbar } = useSnackbar();
  const safeLog = useSafeLog();

  const { result: accountsResult, isLoading: accountsIsLoading } = wrapQuery(
    trpc.banking.account.getInstitutionsWithAccounts.useQuery(undefined, {
      refetchOnWindowFocus: false,
    }),
  );

  const allAccounts = useMemo(
    () =>
      accountsResult
        ? pipe(
            accountsResult,
            mapSuccess((banksWithAccounts) =>
              banksWithAccounts.flatMap((bank) =>
                bank.accounts.map((account) => ({
                  name: account.name,
                  mask: account.mask,
                  currency: account.isoCurrency,
                  accountId: account.accountId,
                })),
              ),
            ),
            inspectError((err) => safeLog('Error fetching accounts', err)),
            inspectError(() =>
              enqueueSnackbar({
                message: 'Error fetching accounts',
                variant: 'error',
              }),
            ),
            match(
              (accounts) => accounts,
              () => [],
            ),
          )
        : [],
    [accountsResult, enqueueSnackbar, safeLog],
  );

  const {
    result: ruleSetsResult,
    isFetching: ruleSetsFetching,
    refetch: refetchRuleSets,
  } = wrapQuery(
    trpc.rules.listRuleSets.useQuery(undefined, {
      refetchOnWindowFocus: false,
    }),
  );

  const { result: listCustomFieldsResult, isFetching: customFieldsFetching } =
    wrapQuery(
      trpc.banking.transactionAttribute.list.useQuery(undefined, {
        refetchOnWindowFocus: false,
      }),
    );

  const customFields = useMemo(() => {
    if (!listCustomFieldsResult) {
      return [];
    } else
      return pipe(
        listCustomFieldsResult,
        inspectError((err) => safeLog('Error fetching attribute schema', err)),
        mapSuccess((schema) =>
          schema.map((attr) => ({
            classId: attr.attributeId,
            className: attr.name,
            values: [
              ...attr.options.map((opt) => ({
                valueId: opt.optionId,
                valueName: opt.name,
              })),
            ],
          })),
        ),
        ignoreError([]),
      );
  }, [listCustomFieldsResult, safeLog]);

  const isLoading =
    accountsIsLoading || ruleSetsFetching || customFieldsFetching;

  const serverRules: AutomationRuleSetDto[] = useMemo(() => {
    if (ruleSetsResult) {
      return pipe(
        ruleSetsResult,
        match(
          (rules) => rules.map((rule) => ({ ...rule })),
          (err) => {
            safeLog('error when getting rules', err);
            enqueueSnackbar({
              message: 'Error when getting rules',
              variant: 'error',
            });
            return [];
          },
        ),
      );
    }
    return [];
  }, [enqueueSnackbar, ruleSetsResult, safeLog]);

  return (
    <Stack>
      <Box height={'93vh'} overflow-y={'auto'} pt={1}>
        <Container maxWidth={'xl'}>
          {isLoading ? (
            <Container>
              <Stack spacing={2}>
                <Skeleton variant="rectangular" height={50} />
                <Skeleton variant="rectangular" height={50} />
                <Skeleton variant="rectangular" height={50} />
              </Stack>
            </Container>
          ) : customFields.length === 0 ? (
            <NoAttributeContent />
          ) : (
            <AutomationRuleEditor
              allAccounts={allAccounts}
              serverRules={serverRules}
              customFields={customFields}
              refetchRules={() => {
                refetchRuleSets();
              }}
            />
          )}
        </Container>
      </Box>
    </Stack>
  );
};
