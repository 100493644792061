import { ConfirmationModal } from '@fresh-stack/frontend-commons';
import {
  AsyncResult,
  inspectError,
  inspectSuccess,
  pipe,
} from '@fresh-stack/fullstack-commons';
import { ReqDeleteTxAttribute } from '@fresh-stack/router/types';

export const DeleteAttributeDialog = <T,>({
  deleteAttribute,
  onClose,
  onSuccess,
  attribute,
  setErrorMessage,
}: {
  readonly deleteAttribute: (
    req: ReqDeleteTxAttribute,
  ) => AsyncResult<NonNullable<T>, string>;
  readonly onClose: () => void;
  readonly onSuccess: () => void;
  readonly attribute: { readonly id: string; readonly name: string };
  readonly setErrorMessage: (message: string) => void;
}) => {
  const onSave = async () => {
    pipe(
      await deleteAttribute({ attributeId: attribute.id }),
      inspectSuccess(onSuccess),
      inspectError((_) =>
        setErrorMessage(
          'We ran into an issue with your request, please try again!',
        ),
      ),
    );
  };
  // TODO(enp-442): add text to the form of how many accounts will be modified
  return (
    <ConfirmationModal
      question={
        <>
          Are you sure you want to delete the custom field{' '}
          <strong>{attribute.name}</strong>?
        </>
      }
      handleApprove={onSave}
      handleCancel={onClose}
    />
  );
};
