import {
  compact,
  formatCurrencyAmount,
  parseDateOrStringOrNumber,
} from '@fresh-stack/fullstack-commons';
import { ResAccountDto } from '@fresh-stack/router/types';
import DeleteIcon from '@mui/icons-material/Delete';
import EditNoteIcon from '@mui/icons-material/EditNote';
import { Box, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { DataGridPro, GridColDef, GridRowsProp } from '@mui/x-data-grid-pro';
import { format } from 'date-fns';
import { FONTS } from '../theme';
import { useMemo, useState } from 'react';

type AccountRow = ResAccountDto & {
  readonly id: string;
  readonly hover: boolean;
};

const baseColumns: readonly GridColDef<AccountRow>[] = [
  { field: 'isoCurrencyCode', headerName: 'Currency', width: 100 },
  { field: 'institutionName', headerName: 'Institution', flex: 1 },
  { field: 'accountName', headerName: 'Account name', flex: 1 },
  {
    field: 'accountMask',
    headerName: 'Account number',
    width: 200,
    valueFormatter: (params) =>
      params.value ? `**** **** **** ${params.value}` : '-',
  },
  {
    field: 'balance',
    headerName: 'Balance',
    width: 150,
    type: 'number',
    renderCell: (params) =>
      params.value === undefined
        ? '-'
        : formatCurrencyAmount({
            currency: params.row.isoCurrencyCode,
            value: params.value,
          }),
  },
  {
    field: 'lastSynced',
    type: 'date',
    width: 150,
    valueFormatter: (params) => {
      if (!params.value) return '-';
      const date = parseDateOrStringOrNumber(params.value);
      if (!date) return '-';
      else return format(date, 'yyyy-MM-dd HH:mm');
    },
    headerName: 'Last updated on',
    description: 'The time at which transactions and balance was last synced',
  },
];

export const AccountTable = ({
  accounts,
  isLoading,
  allowDeleteActions,
  allowAccountTypeEdit,
  onDelete,
  onEditAccountType,
}: {
  readonly accounts: readonly ResAccountDto[];
  readonly isLoading: boolean;
  readonly allowDeleteActions: boolean;
  readonly allowAccountTypeEdit: boolean;
  readonly onDelete: (id: string) => void;
  readonly onEditAccountType: (account: ResAccountDto) => void;
}) => {
  const [hoverRowId, setHoverRowId] = useState<string | undefined>();

  const editColumn: GridColDef<AccountRow> | undefined = allowAccountTypeEdit
    ? {
        field: 'accountType',
        headerName: 'Account type',
        headerAlign: 'center',
        sortable: false,
        filterable: false,
        flex: 1,
        renderCell: (params) => {
          return (
            <Box
              display={'flex'}
              pl={1}
              width={'100%'}
              alignItems={'center'}
              justifyContent={'flex-end'}
              onMouseEnter={() => setHoverRowId(params.id.toString())}
              onMouseLeave={() => setHoverRowId(undefined)}
            >
              {params.row.userAccountTypeName ? (
                <Tooltip title={params.row.userAccountTypeName}>
                  <Typography
                    variant="body2"
                    style={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {params.row.userAccountTypeName}
                  </Typography>
                </Tooltip>
              ) : null}
              <IconButton
                onClick={() => onEditAccountType(params.row)}
                sx={{
                  visibility:
                    hoverRowId === params.row.id ? 'visible' : 'hidden',
                }}
              >
                <EditNoteIcon fontSize="small" color="success" />
              </IconButton>
            </Box>
          );
        },
      }
    : undefined;

  const deleteColumn: GridColDef<AccountRow> | undefined = allowDeleteActions
    ? {
        field: 'editAction',
        headerName: '',
        width: 60,
        sortable: false,
        resizable: false,
        filterable: false,
        renderCell: (params) => {
          return (
            <Box display={'flex'} pl={1}>
              {params.row.isMossAccount ? null : (
                <Tooltip title={'Remove account.'}>
                  <IconButton onClick={() => onDelete(params.row.accountId)}>
                    <DeleteIcon fontSize="small" color="error" />
                  </IconButton>
                </Tooltip>
              )}
            </Box>
          );
        },
      }
    : undefined;

  const columns = compact([...baseColumns, editColumn, deleteColumn]);

  const rows: GridRowsProp<AccountRow> = useMemo(
    () =>
      accounts.map((account) => {
        return {
          id: account.accountId,
          hover: false,
          ...account,
        };
      }),
    [accounts],
  );

  return (
    <div style={{ width: '100%' }}>
      <DataGridPro
        sx={{
          bgcolor: 'white',
          boxShadow: 4,
          padding: 1,
          maxHeight: '70vh',
          width: '100%',
        }}
        autoHeight
        loading={isLoading}
        rows={rows}
        columns={columns}
        initialState={{
          pagination: { paginationModel: { pageSize: 10 } },
        }}
        rowSelection={false}
        slots={{
          noRowsOverlay: () => (
            <Stack
              alignItems={'center'}
              justifyContent={'center'}
              width={'100%'}
              height={'100%'}
              sx={{ minHeight: '60px' }}
            >
              <Typography variant="body1" fontFamily={FONTS.inter}>
                No accounts found. Link an account to get started.
              </Typography>
            </Stack>
          ),
        }}
        pagination
        pageSizeOptions={[5, 10, 25]}
      />
    </div>
  );
};
