import {
  SUPPORTED_COUNTRY_ALPHA2_CODES,
  SupportedCountryAlpha2Code,
  compact,
  keyBy,
  sortBy,
} from '@fresh-stack/fullstack-commons';
import { getName, registerLocale } from 'i18n-iso-countries';

import enlang from 'i18n-iso-countries/langs/en.json';
registerLocale(enlang);

const EXCLUDED_COUNTRIES = ['GB', 'CA'];

export const FRONTEND_SUPPORTED_COUNTRIES = sortBy(
  compact(
    SUPPORTED_COUNTRY_ALPHA2_CODES.filter(
      (x) => !EXCLUDED_COUNTRIES.includes(x),
    ).map((code) =>
      getName(code, 'en')
        ? {
            id: code,
            label: getName(code, 'en') ?? code,
          }
        : undefined,
    ),
  ),
  (c) => c.label,
);

const COUNTRY_BY_CODE = keyBy(FRONTEND_SUPPORTED_COUNTRIES, (c) => c.id);

export const getCountryByCode = (code: SupportedCountryAlpha2Code) =>
  COUNTRY_BY_CODE[code];

export const getCountryName = (code: SupportedCountryAlpha2Code) =>
  getName(code, 'en');
