import { PostHogProvider } from 'posthog-js/react';

export const CustomPostHogProvider = ({
  children,
}: {
  readonly children: React.ReactElement;
}) => (
  <PostHogProvider
    apiKey={import.meta.env.VITE_POSTHOG_KEY}
    options={{
      api_host: import.meta.env.VITE_POSTHOG_HOST,
      disable_session_recording: true, //we manually enable it for users based on our own criteria
    }}
  >
    {children}
  </PostHogProvider>
);
