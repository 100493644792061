import { FONTS } from '@fresh-stack/frontend-commons';
import { Skeleton, Stack, Typography } from '@mui/material';
import { ROUTES } from '../../app/routes';

export const LoadingSkeleton = () => {
  return (
    <Stack spacing={2}>
      <Stack direction="row" spacing={2}>
        <Stack width={'20%'} spacing={2}>
          <Skeleton
            variant="rounded"
            height={'50%'}
            animation="pulse"
          ></Skeleton>
          <Skeleton
            variant="rounded"
            height={'50%'}
            animation="pulse"
          ></Skeleton>
        </Stack>
        <Skeleton
          variant="rounded"
          height={150}
          width={'80%'}
          animation="pulse"
        ></Skeleton>
      </Stack>
      <HomepageChartsSkeleton />
    </Stack>
  );
};

export const AddAcountsSkeleton = () => {
  return (
    <Stack spacing={6} pt={6}>
      <LoadingSkeleton />
      <Typography
        textAlign={'center'}
        fontFamily={FONTS.lato}
        fontWeight={600}
        variant="h5"
        sx={{ opacity: 0.5 }}
      >
        You can{' '}
        <a href={ROUTES.Admin.SetupNewConnection}>connect your bank accounts</a>{' '}
        to view data on your transactions.
      </Typography>
    </Stack>
  );
};

export const HomepageChartsSkeleton = () => {
  return (
    <Stack width="100%" flex="grow" spacing={1}>
      <Stack direction={'row'} spacing={3} alignItems={'flex-end'}>
        <Skeleton
          variant="rounded"
          height={'15vh'}
          width={'10%'}
          animation="wave"
        ></Skeleton>
        <Skeleton
          variant="rounded"
          height={'18vh'}
          width={'10%'}
          animation="wave"
        ></Skeleton>
        <Skeleton
          variant="rounded"
          height={'20vh'}
          width={'10%'}
          animation="wave"
        ></Skeleton>
        <Skeleton
          variant="rounded"
          height={'27vh'}
          width={'10%'}
          animation="wave"
        ></Skeleton>
        <Skeleton
          variant="rounded"
          height={'24vh'}
          width={'10%'}
          animation="wave"
        ></Skeleton>
        <Skeleton
          variant="rounded"
          height={'25vh'}
          width={'10%'}
          animation="wave"
        ></Skeleton>
        <Skeleton
          variant="rounded"
          height={'5vh'}
          width={'10%'}
          animation="wave"
        ></Skeleton>
        <Skeleton
          variant="rounded"
          height={'25vh'}
          width={'10%'}
          animation="wave"
        ></Skeleton>
        <Skeleton
          variant="rounded"
          height={'23vh'}
          width={'10%'}
          animation="wave"
        ></Skeleton>
        <Skeleton
          variant="rounded"
          height={'15vh'}
          width={'10%'}
          animation="wave"
        ></Skeleton>
      </Stack>
      <Skeleton variant="rounded" height={20} width={'100%'}></Skeleton>
    </Stack>
  );
};
