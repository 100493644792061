import {
  CustomHoverIconButton,
  FONTS,
  HeaderContainer,
  NotificationCard,
  appColors,
  wrapMutation,
  wrapQuery,
} from '@fresh-stack/frontend-commons';
import { countBy, isError, isSuccess } from '@fresh-stack/fullstack-commons';
import CloseIcon from '@mui/icons-material/Close';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import NotificationsIcon from '@mui/icons-material/Notifications';
import {
  Badge,
  Box,
  Chip,
  CircularProgress,
  Drawer,
  IconButton,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import React from 'react';
import { trpc } from '../../utils';
import './animation.css';

export const Notifications = () => {
  const { enqueueSnackbar } = useSnackbar();

  const [openDrawer, setOpenDrawer] = React.useState(false);
  const {
    result: maybeNotifications,
    isFetching,
    isRefetching,
    refetch,
  } = wrapQuery(
    trpc.organisation.getUserNotifications.useQuery(undefined, {
      refetchOnWindowFocus: false,
      refetchInterval: 30_000,
    }),
  );

  const {
    mutate: markNotificationAsRead,
    isLoading: markingOne,
    result: notificationMarkedResult,
  } = wrapMutation(trpc.organisation.markUserNotificationAsRead.useMutation());

  const {
    mutate: markAllNotificationsAsRead,
    isLoading: markingAll,
    result: allMarkedResult,
  } = wrapMutation(
    trpc.organisation.markAllUserNotificationsAsRead.useMutation(),
  );

  const unread =
    maybeNotifications && isSuccess(maybeNotifications)
      ? countBy(maybeNotifications.right, (x) =>
          !x.readtAt ? 'unread' : 'read',
        )['unread']
      : 0;

  React.useEffect(() => {
    if (notificationMarkedResult) {
      if (isError(notificationMarkedResult)) {
        enqueueSnackbar({
          variant: 'error',
          message:
            'We had an issue marking the notification as read. Please try again!',
        });
      } else {
        refetch();
      }
    }
  }, [enqueueSnackbar, notificationMarkedResult, refetch]);

  React.useEffect(() => {
    if (allMarkedResult) {
      if (isError(allMarkedResult)) {
        enqueueSnackbar({
          variant: 'error',
          message:
            'We had an issue marking your notifications as read. Please try again!',
        });
      } else {
        refetch();
      }
    }
  }, [allMarkedResult, enqueueSnackbar, refetch]);

  return (
    <>
      <IconButton
        sx={{ ml: 1 }}
        onClick={() => setOpenDrawer(true)}
        className={`icon-button ${unread ? 'pulse-animation' : ''}`}
      >
        <Badge badgeContent={unread} color="success">
          <NotificationsIcon color={'primary'} />
        </Badge>
      </IconButton>
      <Drawer
        anchor="right"
        sx={{ color: 'red' }}
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
      >
        <Box width={400}>
          <HeaderContainer height={30} bgcolor={appColors.primary}>
            <Box display="flex" alignItems={'center'}>
              <IconButton
                onClick={() => setOpenDrawer(false)}
                color="secondary"
                size="small"
              >
                <CloseIcon fontSize="small" />
              </IconButton>
              <Typography
                fontFamily={FONTS.montserratBold}
                color={'white'}
                variant={'subtitle1'}
              >
                {unread > 0
                  ? `Notifications (${unread} unread)`
                  : 'Notifications'}
              </Typography>
              {unread > 0 && (
                <Tooltip title={'Mark all as read.'}>
                  <CustomHoverIconButton
                    hoverColor={appColors.secondary}
                    defaultColor="gray"
                    onClick={() => markAllNotificationsAsRead()}
                    disabled={isFetching || markingAll || markingOne}
                  >
                    <DoneAllIcon fontSize="medium" />
                  </CustomHoverIconButton>
                </Tooltip>
              )}
              {(isFetching || isRefetching) && (
                <CircularProgress size={15} color="secondary" sx={{ ml: 1 }} />
              )}
            </Box>
          </HeaderContainer>
          <Stack
            pl={2}
            pr={2}
            pt={1}
            pb={1}
            maxHeight={'94vh'}
            overflow="auto"
            spacing={1}
          >
            {isFetching && (
              <Skeleton width={'100%'} animation="wave" height={40} />
            )}
            {maybeNotifications &&
              isSuccess(maybeNotifications) &&
              !maybeNotifications.right.length && (
                <Chip label={'You have no notifications.'} />
              )}

            <Stack spacing={1}>
              {maybeNotifications &&
                isSuccess(maybeNotifications) &&
                maybeNotifications.right.map((notification, i) => (
                  <NotificationCard
                    key={'notif' + i}
                    notification={notification}
                    onMarkAsRead={() => markNotificationAsRead(notification.id)}
                    disabled={
                      isFetching || isRefetching || markingOne || markingAll
                    }
                  />
                ))}
            </Stack>
          </Stack>
        </Box>
      </Drawer>
    </>
  );
};
