import { NavigationTabs, TabProps } from '@fresh-stack/frontend-commons';
import { ROUTES } from '../../app/routes';
import { AllAccountsTab } from './AllAccountsTab';
import { AccountTypeSettings } from '../Account/AccountTypeSettings';
import { NavBarAvatar } from '../../components/navbar/NavBarAvatar';

const tabs: TabProps[] = [
  {
    name: 'Accounts',
    path: ROUTES.Commons.Accounts,
    component: (key?: string) => <AllAccountsTab key={key} />,
  },
  {
    name: 'Account types',
    path: ROUTES.Commons.AccountTypes,
    component: (key?: string) => <AccountTypeSettings key={key} />,
  },
];

export const AccountTabs = () => {
  return <NavigationTabs tabs={tabs} endAdornment={<NavBarAvatar />} />;
};
