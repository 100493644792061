export const ALL = 'All' as const;

export const formatCurrencyAmount = ({
  currency,
  value,
  locale,
}: {
  readonly currency: string | undefined;
  readonly value: string | number;
  readonly locale?: Intl.LocalesArgument;
}) =>
  (+value).toLocaleString(
    locale ?? 'en-CH',
    currency
      ? {
          style: 'currency',
          currency: currency,
        }
      : undefined,
  );

export const formatCurrencyAmountWithSign = ({
  currency,
  value,
  locale,
}: {
  readonly currency: string | undefined;
  readonly value: string | number;
  readonly locale?: Intl.LocalesArgument;
}) =>
  +value > 0
    ? '+' + formatCurrencyAmount({ currency, value, locale })
    : formatCurrencyAmount({ currency, value, locale });
