import { formatCurrencyAmount } from '@fresh-stack/fullstack-commons';
import {
  Card,
  CardContent,
  CardHeader,
  Tooltip,
  Typography,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { appColors } from '@fresh-stack/frontend-commons';

export const AmountHeroCard = ({
  title,
  amount,
  currency,
  tooltip,
}: {
  readonly title: string;
  readonly amount: number | undefined;
  readonly currency: string | undefined;
  readonly tooltip: string;
}) => {
  const formattedAmount =
    amount == null ? '-' : formatCurrencyAmount({ currency, value: amount });
  return (
    <Card
      sx={{
        width: '100%',
        minWidth: 200,
        height: 100,
        bgcolor: appColors.primary,
        borderRadius: 4,
      }}
    >
      <CardHeader
        sx={{ mt: -1 }}
        title={
          <Typography color="secondary" variant="body1">
            {title}
          </Typography>
        }
        action={
          <Tooltip title={tooltip}>
            <InfoIcon color="secondary" fontSize="small" />
          </Tooltip>
        }
      />
      <CardContent sx={{ mt: -3 }}>
        <Typography variant="h5" color={appColors.white}>
          {formattedAmount}
        </Typography>
      </CardContent>
    </Card>
  );
};
