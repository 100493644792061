import {
  Loader,
  PageHeader,
  RedirectWithQuery,
  useSafeLog,
  wrapMutation,
  wrapQuery,
} from '@fresh-stack/frontend-commons';
import {
  SupportedCountryAlpha2Code,
  formatCurrencyAmount,
  isError,
  isNone,
  isSuccess,
} from '@fresh-stack/fullstack-commons';
import { LoadingButton } from '@mui/lab';
import { Alert, Autocomplete, Box, Stack, TextField } from '@mui/material';
import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro';
import { format } from 'date-fns';
import { useSnackbar } from 'notistack';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { trpc } from '../../utils';
import { FRONTEND_SUPPORTED_COUNTRIES } from '../../utils/language';
import { NavBarAvatar } from '../../components/navbar/NavBarAvatar';

const txColumns: readonly GridColDef[] = [
  {
    field: 'paymentDate',
    headerName: 'Date',
    renderCell: (value) => {
      return format(new Date(value.row.paymentDate), 'dd MMM yyyy');
    },
  },
  { field: 'accountNumber', headerName: 'Account number', width: 120 },
  {
    field: 'amount',
    headerName: 'Amount',
    width: 150,
    type: 'number',
    renderCell: (params) =>
      formatCurrencyAmount({
        currency: params.row.currency,
        value: params.value,
      }),
  },
  { field: 'merchantName', headerName: 'Merchant', width: 250 },
  { field: 'status', headerName: 'Status', width: 200 },
  { field: 'teamName', headerName: 'Team', width: 200 },
  { field: 'category', headerName: 'Category', width: 300 },
];

export const MossUploadPage = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [selectedCountry, setSelectedCountry] = React.useState<
    SupportedCountryAlpha2Code | undefined
  >(undefined);

  const enablePlaidUs = useFeatureFlagEnabled('enablePlaidUs');

  const countryList = useMemo(() => {
    if (enablePlaidUs) return FRONTEND_SUPPORTED_COUNTRIES;
    else return FRONTEND_SUPPORTED_COUNTRIES.filter((x) => x.id !== 'US');
  }, [enablePlaidUs]);

  const { fileId } = useParams();
  const safeLog = useSafeLog();

  const {
    result: maybeFile,
    refetch,
    isRefetching,
  } = wrapQuery(
    trpc.imports.getUploadedFileWithTransactions.useQuery(
      { fileId: fileId ?? '' },
      {
        refetchOnWindowFocus: false,
        enabled: !!fileId,
      },
    ),
  );

  const { mutateAsync: submitFileForProcessing, isLoading: isSubmitting } =
    wrapMutation(trpc.imports.submitFileForProcessing.useMutation());

  const handleSubmit = async () => {
    if (fileId && selectedCountry) {
      const maybeSubmitted = await submitFileForProcessing({
        fileId,
        countryCode: selectedCountry,
      });

      if (isSuccess(maybeSubmitted)) {
        refetch();
      } else {
        switch (maybeSubmitted.left.code) {
          case 'io-failed':
          case 'not-found':
          case 'unknown':
            enqueueSnackbar({
              message:
                'There was an issue with your request. Please try again.',
              variant: 'error',
            });
            return;
          case 'already-processed':
          case 'already-submitted':
            enqueueSnackbar({
              message: 'This file has already been submitted.',
              variant: 'error',
            });
            return;
        }
      }
    }
  };

  safeLog('file id', fileId);
  if (!fileId) {
    return <RedirectWithQuery path="/" />;
  } else if (!maybeFile) {
    return <Loader />;
  } else if (isError(maybeFile)) {
    return <Loader />;
  } else if (isNone(maybeFile.right)) {
    return <RedirectWithQuery path="/404" />;
  } else if (!maybeFile.right.value.status) {
    return (
      <Stack>
        <PageHeader
          title={`Processing file: ${maybeFile.right.value.fileName}`}
          endAdornment={<NavBarAvatar />}
          height={40}
        />
        <Stack
          justifyContent={'center'}
          alignItems={'center'}
          padding={2}
          spacing={2}
        >
          <Box>
            <DataGridPro
              sx={{
                maxHeight: '50vh',
                boxShadow: 4,
                padding: 1,
                width: 'fit-content',
              }}
              rows={maybeFile.right.value.transactions}
              columns={txColumns}
              pagination
              initialState={{
                pagination: { paginationModel: { pageSize: 25 } },
              }}
              pageSizeOptions={[25, 50, 100]}
            />
          </Box>

          <Box
            display="flex"
            width={'100%'}
            maxWidth={500}
            alignItems={'center'}
            gap={2}
          >
            <Autocomplete
              disablePortal
              id="select-country"
              fullWidth
              size="small"
              sx={{ maxWidth: 250 }}
              onChange={(_, value) => setSelectedCountry(value?.id)}
              options={countryList}
              placeholder="Moss account country"
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Moss account country"
                  fullWidth
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'new-password', // disable autocomplete and autofill
                  }}
                />
              )}
            />
            <LoadingButton
              sx={{ width: 'fit-content' }}
              variant="contained"
              loading={isRefetching || isSubmitting}
              disabled={!selectedCountry}
              onClick={handleSubmit}
            >
              Submit for processing
            </LoadingButton>
          </Box>
        </Stack>
      </Stack>
    );
  } else if (
    maybeFile.right.value.status === 'Submitted' ||
    maybeFile.right.value.status === 'Processed'
  ) {
    return (
      <Stack>
        <PageHeader
          title={`File ${maybeFile.right.value.fileName}: ${maybeFile.right.value.status}`}
          endAdornment={<NavBarAvatar />}
          height={40}
        />

        <Stack
          justifyContent={'center'}
          alignItems={'center'}
          padding={2}
          spacing={2}
        >
          <Alert
            variant="filled"
            severity="success"
            sx={{ width: 'fit-content' }}
          >
            {maybeFile.right.value.fileName}{' '}
            {maybeFile.right.value.status === 'Submitted'
              ? 'has been submitted. Your transaction updates will be available shortly.'
              : 'has already been processed.'}
          </Alert>
          <DataGridPro
            sx={{
              boxShadow: 4,
              padding: 1,
              width: 'fit-content',
              maxHeight: '60vh',
            }}
            rows={maybeFile.right.value.transactions}
            columns={txColumns}
            pagination
            initialState={{
              pagination: { paginationModel: { pageSize: 25 } },
            }}
            pageSizeOptions={[25, 50, 100]}
          />
        </Stack>
      </Stack>
    );
  }
};
