import {
  CustomFieldDefinition,
  DialogFadeTransition,
} from '@fresh-stack/frontend-commons';
import { zodResolver } from '@hookform/resolvers/zod';
import ClearIcon from '@mui/icons-material/Clear';
import LaunchIcon from '@mui/icons-material/Launch';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Dialog,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import z from 'zod';
import { ROUTES } from '../app/routes';
import { QUERY_PARAMS } from '../pages/Account/AttributeSettingsTab';

export const CLEAR_VALUE = 'clear';
export const EMPTY_VALUE = '';

const buildFormSchema = (customFields: CustomFieldDefinition[]) =>
  z.object(
    customFields.reduce<
      Record<
        string,
        z.ZodUnion<
          readonly [
            z.ZodLiteral<string>,
            z.ZodLiteral<string>,
            ...z.ZodLiteral<string>[],
          ]
        >
      >
    >((acc, attr) => {
      const literals: readonly z.ZodLiteral<string>[] = attr.values.map(
        (value) => z.literal(value.valueId),
      );
      acc[attr.classId] = z.union([
        z.literal(EMPTY_VALUE),
        z.literal(CLEAR_VALUE),
        ...literals,
      ]);
      return acc;
    }, {}),
  );

export type BulkEditFormSchemaType = z.infer<
  ReturnType<typeof buildFormSchema>
>;

export const BulkEditTransactionsDialog = ({
  isOpen,
  onClose,
  onSave,
  isSaving,
  customFields,
  title,
}: {
  readonly isOpen: boolean;
  readonly onClose: () => void;
  readonly onSave: (formValues: BulkEditFormSchemaType) => void;
  readonly isSaving: boolean;
  readonly customFields: CustomFieldDefinition[];
  readonly title: string;
}) => {
  const formSchema = useMemo(
    () => buildFormSchema(customFields),
    [customFields],
  );

  const { control, handleSubmit, resetField, watch } =
    useForm<BulkEditFormSchemaType>({
      resolver: zodResolver(formSchema),
      defaultValues: customFields.reduce(
        (acc, attr) => ({ ...acc, [attr.classId]: EMPTY_VALUE }),
        {},
      ),
    });

  const NoAttributeContent = () => (
    <>
      <DialogTitle>
        <Typography variant="h5">No transaction custom fields found</Typography>
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1">
          Custom fields must first be configured for your organization.
        </Typography>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button
            href={
              ROUTES.Settings.Organisation.Attributes +
              `?${QUERY_PARAMS.openModal.key}=${QUERY_PARAMS.openModal.values.createTxAttr}`
            }
            target="_blank"
            variant="contained"
            color="primary"
            endIcon={<LaunchIcon />}
          >
            Create custom field
          </Button>
        </DialogActions>
      </DialogContent>
    </>
  );

  const FormContent = () => (
    <>
      <DialogTitle>
        <Typography variant="h5">{title}</Typography>
      </DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit(onSave)}>
          <Stack spacing={2}>
            {customFields.map((attr) => (
              <Grid container key={attr.classId} alignItems="center">
                <Grid item xs={12} sm={4}>
                  <Typography
                    style={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {attr.className}
                  </Typography>
                </Grid>
                <Controller
                  name={attr.classId}
                  control={control}
                  render={({ field }) => (
                    <Grid item xs={12} sm={8}>
                      <Box display="flex" alignItems="center" width="100%">
                        <Box flexGrow={1} mr={1}>
                          <Select
                            {...field}
                            fullWidth
                            size="small"
                            onChange={(e) => field.onChange(e.target.value)}
                            value={field.value}
                          >
                            <MenuItem value={CLEAR_VALUE}>
                              <em>Clear value</em>
                            </MenuItem>
                            <Divider sx={{ margin: 0 }} />
                            {attr.values.map((value) => (
                              <MenuItem
                                key={value.valueId}
                                value={value.valueId}
                              >
                                <Typography
                                  style={{
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                  }}
                                >
                                  {value.valueName}
                                </Typography>
                              </MenuItem>
                            ))}
                          </Select>
                        </Box>
                        <Tooltip title="Don't update this field">
                          <IconButton onClick={() => resetField(attr.classId)}>
                            <ClearIcon />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </Grid>
                  )}
                />
              </Grid>
            ))}
            <DialogActions>
              <Button onClick={onClose}>Cancel</Button>
              <LoadingButton
                type="submit"
                variant="contained"
                color="primary"
                loading={isSaving}
                disabled={
                  isSaving ||
                  Object.values(watch()).every((v) => v === EMPTY_VALUE)
                }
              >
                Save
              </LoadingButton>
            </DialogActions>
          </Stack>
        </form>
      </DialogContent>
    </>
  );

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      TransitionComponent={DialogFadeTransition}
    >
      {customFields.length > 0 ? <FormContent /> : <NoAttributeContent />}
    </Dialog>
  );
};
