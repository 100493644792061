import {
  PageHeader,
  appColors,
  useQueryState,
  useSafeLog,
  wrapQuery,
} from '@fresh-stack/frontend-commons';
import {
  MIN_DATE,
  UNCATEGORISED_ID,
  compact,
  head,
  ignoreError,
  inspectError,
  isError,
  mapSuccess,
  pipe,
  sortBy,
  sumBy,
  uniq,
} from '@fresh-stack/fullstack-commons';
import {
  Box,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers-pro';
import { format, parse } from 'date-fns';
import { useMemo } from 'react';
import { useUserContext } from '../../app/hooks/userContext';
import { NavBarAvatar } from '../../components/navbar/NavBarAvatar';
import { trpc } from '../../utils';
import { getCountryName } from '../../utils/language';
import { DashboardSectionCashPosition } from './DashboardSectionCashPosition';
import { DashboardSectionHeroCards } from './DashboardSectionHeroCards';
import { DashboardSectionTransactionActivity } from './DashboardSectionTransactionActivity';
import { AddAcountsSkeleton, LoadingSkeleton } from './skeletons';

export const URL_DATE_FORMAT = 'dd-MM-yy';

export const ChartDashboardPage = () => {
  const { user } = useUserContext();
  const welcomeText = user.firstName
    ? `Welcome, ${user.firstName}!`
    : `Welcome!`;

  const safeLog = useSafeLog();
  const { result: accountResult, isFetching: isLoadingAccounts } = wrapQuery(
    trpc.banking.account.getBankAccounts.useQuery(undefined, {
      refetchOnWindowFocus: false,
    }),
  );

  const { result: listTxAttributesResult } = wrapQuery(
    trpc.banking.transactionAttribute.list.useQuery(undefined, {
      refetchOnWindowFocus: false,
    }),
  );

  const customFieldsOrEmpty =
    !listTxAttributesResult || isError(listTxAttributesResult)
      ? []
      : listTxAttributesResult?.right;

  const customFields = useMemo(() => {
    if (!listTxAttributesResult) {
      return [];
    } else
      return pipe(
        listTxAttributesResult,
        inspectError((err) =>
          safeLog('Error fetching custom field schema', err),
        ),
        mapSuccess((schema) =>
          schema.map((attr) => ({
            classId: attr.attributeId,
            className: attr.name,
            values: [
              {
                valueId: UNCATEGORISED_ID,
                valueName: 'Uncategorised',
              },
              ...attr.options.map((opt) => ({
                valueId: opt.optionId,
                valueName: opt.name,
              })),
            ],
          })),
        ),
        ignoreError([]),
      );
  }, [listTxAttributesResult, safeLog]);

  const [customFieldId, setCustomFieldId] = useQueryState<string>({
    fieldName: 'attributeClassId',
    defaultValue: head(customFieldsOrEmpty)?.attributeId ?? '',
    deserialize: (value: string) => value,
    serialize: (value) => value,
  });

  const selectedCustomField = useMemo(
    () => customFields.find((x) => x.classId === customFieldId),
    [customFieldId, customFields],
  );

  const maybeAllAccounts = useMemo(() => {
    if (accountResult)
      return pipe(
        accountResult,
        inspectError(() => {
          throw new Error(); // we are on the home page, show error screen to reload
        }),
        mapSuccess((allAccounts) =>
          allAccounts.map((account) => ({
            id: account.accountId,
            name: account.accountName,
            bank: account.institutionName,
            mask: account.accountMask,
            currency: account.isoCurrencyCode,
            countryCode: account.isoCountryCode,
            country: getCountryName(account.isoCountryCode) ?? '',
            availableBalance: account.balance,
          })),
        ),
      );
    else return;
  }, [accountResult]);

  const allCurrencies = useMemo(() => {
    if (!maybeAllAccounts || isError(maybeAllAccounts)) return [];
    else
      return sortBy(
        uniq(compact(maybeAllAccounts.right.map((x) => x.currency))),
        (x) => x,
      );
  }, [maybeAllAccounts]);

  const [selectedCurrencyCode, setSelectedCurrencyCode] = useQueryState({
    fieldName: 'currencyCode',
    defaultValue: allCurrencies.includes('EUR')
      ? 'EUR'
      : head(allCurrencies) ?? 'EUR',
    serialize: (x) => x ?? '',
    deserialize: (x) => (allCurrencies.includes(x) ? x : head(allCurrencies)),
  });

  const { currencyAccounts } = useMemo(() => {
    const accounts =
      !maybeAllAccounts || isError(maybeAllAccounts)
        ? []
        : maybeAllAccounts?.right?.filter(
            (x) => x.currency === selectedCurrencyCode,
          );
    const currentBalance = sumBy(accounts, (x) =>
      x.availableBalance ? +x.availableBalance : 0,
    );
    return { currencyAccounts: accounts, currentBalance };
  }, [maybeAllAccounts, selectedCurrencyCode]);

  const [selectedMonthStr, setSelectedMonth] = useQueryState<
    string | undefined
  >({
    fieldName: 'selectedMonth',
    defaultValue: undefined,
    deserialize: (value: string) => value || undefined,
    serialize: (value) => value ?? '',
  });

  const selectedMonth = useMemo(
    () =>
      selectedMonthStr
        ? parse(selectedMonthStr, URL_DATE_FORMAT, new Date())
        : new Date(),
    [selectedMonthStr],
  );

  return (
    <Stack>
      <PageHeader
        title={'Dashboard'}
        endAdornment={<NavBarAvatar />}
        height={40}
        mb={0}
      />
      <Container maxWidth={'xl'}>
        <Stack maxWidth={1600} padding={1} pt={2}>
          {isLoadingAccounts ||
          !maybeAllAccounts ||
          isError(maybeAllAccounts) ? (
            <LoadingSkeleton />
          ) : !maybeAllAccounts.right.length ? (
            <AddAcountsSkeleton />
          ) : (
            <Stack spacing={1}>
              <Box pl={1} pr={1}>
                <Grid
                  container
                  item
                  xs={12}
                  alignItems={'center'}
                  pl={2}
                  pr={2}
                  pb={1}
                  pt={1}
                  borderRadius={3}
                  bgcolor={appColors.secondary}
                >
                  <Grid
                    container
                    item
                    xs={12}
                    sm={4}
                    justifyContent={'flex-start'}
                    alignItems={'center'}
                  >
                    <Typography color="primary" variant="h6">
                      {welcomeText}
                    </Typography>{' '}
                  </Grid>
                  <Grid
                    container
                    item
                    xs={12}
                    sm={8}
                    gap={1}
                    justifyContent={'flex-end'}
                    alignItems={'center'}
                  >
                    <FormControl sx={{ width: 100 }}>
                      <InputLabel id="select-currency-label" size="small">
                        Currency
                      </InputLabel>
                      <Select
                        labelId="select-currency-label"
                        label="Currency"
                        id="select-currency"
                        value={selectedCurrencyCode}
                        size="small"
                        onChange={(change) => {
                          if (change.target.value)
                            setSelectedCurrencyCode(
                              change.target.value.toString(),
                            );
                        }}
                      >
                        {allCurrencies.map((x) => (
                          <MenuItem key={'mi-cry-' + x} value={x}>
                            {`${x}`}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    {!!customFieldsOrEmpty.length && (
                      <FormControl>
                        <InputLabel id="select-attribute-label" size="small">
                          Custom field
                        </InputLabel>
                        <Select
                          size="small"
                          label="Custom field"
                          sx={{ width: 200 }}
                          onChange={(e) => {
                            setCustomFieldId(e.target.value);
                          }}
                          value={customFieldId}
                        >
                          {customFieldsOrEmpty.map((value) => (
                            <MenuItem
                              key={value.attributeId}
                              value={value.attributeId}
                            >
                              <Typography
                                style={{
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                  whiteSpace: 'nowrap',
                                }}
                              >
                                {value.name}
                              </Typography>
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                    <DateTimePicker
                      views={['month', 'year']}
                      sx={{ width: 150 }}
                      minDate={MIN_DATE}
                      maxDate={new Date()}
                      onChange={(newValue) => {
                        if (newValue)
                          setSelectedMonth(format(newValue, URL_DATE_FORMAT));
                      }}
                      value={selectedMonth}
                      slotProps={{
                        field: {
                          label: 'Month',
                          defaultValue: new Date(),
                          clearable: false,
                        },
                        textField: {
                          size: 'small',
                        },
                      }}
                    />
                  </Grid>
                </Grid>
              </Box>
              <Stack spacing={2} pb={2}>
                <DashboardSectionHeroCards
                  selectedCurrencyCode={selectedCurrencyCode}
                  selectedMonth={selectedMonth}
                  accounts={currencyAccounts}
                />
                <DashboardSectionCashPosition
                  selectedCurrencyCode={selectedCurrencyCode}
                  accounts={currencyAccounts}
                />
                {selectedCustomField && (
                  <DashboardSectionTransactionActivity
                    selectedCustomField={selectedCustomField}
                    selectedMonth={selectedMonth}
                    selectedCurrencyCode={selectedCurrencyCode}
                  />
                )}
              </Stack>
            </Stack>
          )}
        </Stack>
      </Container>
    </Stack>
  );
};
