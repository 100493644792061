import { FONTS, appColors } from '@fresh-stack/frontend-commons';
import { formatCurrencyAmount } from '@fresh-stack/fullstack-commons';
import { Paper } from '@mui/material';
import { Point } from '@nivo/line';

export const BarChartAmountTooltip =
  (currencyCode: string) =>
  ({ id, value }: { readonly id: string | number; readonly value: number }) => (
    <Paper
      sx={{
        padding: 2,
        fontFamily: FONTS.latoBold,
        background: appColors.secondary,
      }}
    >
      <strong>{id}</strong>
      <div>{formatCurrencyAmount({ currency: currencyCode, value })}</div>
    </Paper>
  );

export const LineChartAmountTooltip =
  (currencyCode: string) =>
  ({ point }: { readonly point: Point }) => (
    <Paper
      sx={{
        padding: 2,
        fontFamily: FONTS.latoBold,
        background: appColors.secondary,
      }}
    >
      <strong>{point.data.xFormatted}</strong>
      <div>
        {formatCurrencyAmount({
          currency: currencyCode,
          value: +point.data.y,
        })}
      </div>
    </Paper>
  );
