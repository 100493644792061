export const SETTINGS_BASE_PATH = '/settings';
export const ORG_SETTINGS_BASE_PATH = `${SETTINGS_BASE_PATH}/organisation`;

export const ROUTES = {
  Public: {
    Privacy: '/privacy',
    Signup: '/signup',
  },
  Commons: {
    Home: '/home', // THIS PATH IS USED IN THE HUBSPOT COOKIE BANNER AND CTA SETUP, DO NOT UPDATE IT ALONE
    Accounts: '/accounts',
    AccountTypes: '/account-types',
    Transactions: '/transactions',
    TransactionRules: '/transaction-rules',
    Logout: '/logout',
  },
  Settings: {
    Organisation: {
      Users: `${ORG_SETTINGS_BASE_PATH}/users`,
      Attributes: `${ORG_SETTINGS_BASE_PATH}/attributes`,
    },
    User: `${SETTINGS_BASE_PATH}/user`,
  },
  Admin: {
    Invoices: '/invoices',
    Sales: '/tbc/sales',
    Reports: '/reports',
    Imports: '/imports',
    UploadedMossFile: '/imports/upload/:fileId',
    SetupNewConnection: '/new-connection',
  },
};

export const PUBLIC_ROUTES: readonly string[] = Object.values(ROUTES.Public);

export const buildMossUploadFileRoute = (fileId: string) =>
  ROUTES.Admin.UploadedMossFile.replace(':fileId', fileId);
