import { useCallback } from 'react';

import { MAX_UPLOAD_SIZE_BYTES } from '@fresh-stack/fullstack-commons';
import CloudUploadIcon from '@mui/icons-material/CloudUploadOutlined';
import { FormHelperText, Stack } from '@mui/material';
import { StandardCSSProperties } from '@mui/system';
import Dropzone from 'react-dropzone';

interface FileInputProps {
  readonly disabled?: boolean;
  readonly multiple?: boolean;
  readonly maxFileSize?: number;
  readonly maxFiles?: number;
  readonly text?: string;
  readonly width?: number;
  readonly backgroundColor?: StandardCSSProperties['backgroundColor'];
  readonly borderColor?: StandardCSSProperties['backgroundColor'];
  readonly onFileUpload: (value: File[]) => void;
}

export const FileDragAndDropUploader = ({
  disabled,
  multiple,
  maxFiles,
  maxFileSize,
  text,
  backgroundColor,
  borderColor,
  width,
  onFileUpload,
}: FileInputProps) => {
  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      onFileUpload(acceptedFiles);
    },
    [onFileUpload],
  );

  return (
    <Dropzone
      onDrop={onDrop}
      multiple={multiple}
      maxSize={maxFileSize ?? MAX_UPLOAD_SIZE_BYTES}
      maxFiles={maxFiles}
      disabled={disabled}
    >
      {({ getRootProps, getInputProps }) => {
        return (
          <Stack
            padding={3}
            {...getRootProps()}
            borderRadius={1}
            border={1.5}
            height={'100%'}
            width={width}
            borderColor={borderColor ?? 'silver'}
            sx={{
              background: backgroundColor ?? '#f9f9f9',
              borderStyle: 'dashed',
              cursor: disabled ? 'not-allowed' : 'pointer',
              '&:hover': disabled
                ? undefined
                : { borderColor: 'ThreeDDarkShadow' },
            }}
            alignItems={'center'}
            justifyContent={'center'}
          >
            <input {...getInputProps()} />
            <FormHelperText sx={{ textAlign: 'center' }}>
              {text || 'Click or drag and drop files to upload'}
            </FormHelperText>
            <CloudUploadIcon color={disabled ? 'disabled' : 'secondary'} />
          </Stack>
        );
      }}
    </Dropzone>
  );
};
