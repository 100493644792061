import { FONTS, wrapQuery } from '@fresh-stack/frontend-commons';
import {
  DateOrStringOrNumber,
  getPayload,
  isError,
  isSuccess,
} from '@fresh-stack/fullstack-commons';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt';
import {
  Alert,
  Box,
  Button,
  Container,
  Stack,
  Typography,
} from '@mui/material';
import {
  DataGridPro,
  GridColDef,
  GridValidRowModel,
} from '@mui/x-data-grid-pro';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useUserContext } from '../../app/hooks/userContext';
import { trpc } from '../../utils';
import { AddNewUserDialog } from './AddNewUserDialog';

export interface UserRow extends GridValidRowModel {
  readonly email: string;
  readonly firstName: string;
  readonly lastName: string;
  readonly roleId: string;
  readonly createdAt: DateOrStringOrNumber;
}

const columns: readonly GridColDef<UserRow>[] = [
  { field: 'firstName', headerName: 'First name', width: 150 },
  { field: 'lastName', headerName: 'Last name', width: 150 },
  { field: 'email', headerName: 'Email', width: 250 },
  { field: 'roleId', headerName: 'Role', width: 150 },
  { field: 'createdAt', headerName: 'Since', width: 150, type: 'date' },
];

export const OrganisationUsersTab = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [openNewUser, setOpenNewUser] = React.useState(false);
  const { user } = useUserContext();
  const {
    result: maybeUsers,
    isFetching,
    refetch,
  } = wrapQuery(
    trpc.organisation.getUsers.useQuery(undefined, {
      refetchOnWindowFocus: false,
    }),
  );

  return (
    <Stack alignItems={'flex-start'}>
      <AddNewUserDialog
        open={openNewUser}
        onClose={() => setOpenNewUser(false)}
        onSuccess={({ email }: { readonly email: string }) => {
          setOpenNewUser(false);
          enqueueSnackbar({
            variant: 'success',
            message: `User ${email} has been created and we've sent them an invite!`,
          });
          refetch();
        }}
      />
      <Container>
        <Stack
          maxWidth={1600}
          padding={2}
          pt={0}
          width={'100%'}
          alignItems={'center'}
        >
          <Stack spacing={2} alignContent={'flex-start'}>
            <Box
              display="flex"
              alignItems={'center'}
              justifyContent={'space-between'}
            >
              <Typography variant="h5" fontFamily={FONTS.interBold}>
                Company users
              </Typography>
              {user.userType === 'Admin' && !isFetching && (
                <Button
                  onClick={() => setOpenNewUser(true)}
                  size="small"
                  variant="contained"
                  color="success"
                >
                  Add user
                  <PersonAddAlt1Icon fontSize="small" sx={{ ml: 1 }} />
                </Button>
              )}
            </Box>
            {maybeUsers && isError(maybeUsers) && (
              <Alert severity="error" variant="filled">
                We had an issue while loading your data! Please refresh the page
                and try again.
              </Alert>
            )}
            <DataGridPro
              sx={{
                boxShadow: 1,
                padding: 1,
                maxHeight: '60vh',
              }}
              rows={
                maybeUsers && isSuccess(maybeUsers)
                  ? getPayload(maybeUsers).map((x) => ({
                      ...x,
                      createdAt: new Date(x.createdAt),
                    }))
                  : []
              }
              columns={columns}
              loading={isFetching}
              initialState={{
                pagination: { paginationModel: { pageSize: 25 } },
              }}
              pageSizeOptions={[5, 10, 25]}
            />
          </Stack>
        </Stack>
      </Container>
    </Stack>
  );
};
