import {
  AccountSummary,
  DialogFadeTransition,
} from '@fresh-stack/frontend-commons';
import { RuleFilter } from '@fresh-stack/router/types';
import { LoadingButton } from '@mui/lab';
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import { ReadonlyRuleFilterView } from './ReadonlyRuleFilterView';
import { ACTION_HEADER, CONDITION_HEADER } from './common';

type RuleForm = {
  readonly filter: RuleFilter;
  readonly active: boolean;
  readonly attributeClassName: string;
  readonly attributeValueName: string;
};

export const DeleteRuleDialog = ({
  onClose,
  onSave,
  isSaving,
  ruleDefinition,
  allAccounts,
}: {
  readonly onClose: () => void;
  readonly onSave: () => void;
  readonly isSaving: boolean;
  readonly ruleDefinition: RuleForm;
  readonly allAccounts: AccountSummary[];
}) => {
  return (
    <Dialog
      open={true}
      TransitionComponent={DialogFadeTransition}
      onClose={onClose}
      sx={{ '& .MuiDialog-paper': { minWidth: '1000px' } }}
    >
      <DialogTitle variant="h5" fontWeight={500}>
        Delete rule
      </DialogTitle>
      <DialogContent sx={{ width: '100%' }}>
        <Alert severity="error" sx={{ mt: 1, mb: 2 }}>
          Are you sure you want to delete this rule? This action cannot be
          undone.
        </Alert>
        <Divider sx={{ mt: 4, mb: 2 }} />
        <Typography variant="h6" fontWeight={500} sx={{ pb: 1 }}>
          {CONDITION_HEADER}
        </Typography>
        <Box
          pt={1}
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <ReadonlyRuleFilterView
            filter={ruleDefinition.filter}
            allAccounts={allAccounts}
          />
        </Box>
        <Divider sx={{ mt: 4, mb: 2 }} />
        <Typography variant="h6" fontWeight={500} sx={{ pb: 1 }}>
          {ACTION_HEADER}
        </Typography>
        <Stack direction="row" alignItems={'center'} gap={2} pt={1}>
          <FormControl sx={{ minWidth: 200 }}>
            <InputLabel id="select-custom-field-label" size="small">
              Custom Field
            </InputLabel>
            <Select
              labelId="select-custom-field-label"
              id="select-custom-field"
              value={ruleDefinition.attributeClassName}
              fullWidth
              size="small"
              label="Custom Field"
              disabled={true}
            >
              <MenuItem value={ruleDefinition.attributeClassName}>
                {ruleDefinition.attributeClassName}
              </MenuItem>
            </Select>
          </FormControl>
          <FormControl sx={{ minWidth: 200 }}>
            <InputLabel id="select-valueid-label" size="small">
              Option
            </InputLabel>
            <Select
              labelId="select-valueid-label"
              id="select-valueid"
              value={ruleDefinition.attributeValueName}
              fullWidth
              size="small"
              label="Option"
              disabled={true}
            >
              <MenuItem
                key={'mi-valueid'}
                value={ruleDefinition.attributeValueName}
              >
                {ruleDefinition.attributeValueName}
              </MenuItem>
            </Select>
          </FormControl>
        </Stack>
        <DialogActions>
          <Button onClick={onClose} variant="outlined" color="primary">
            Cancel
          </Button>
          <LoadingButton
            variant="contained"
            color="error"
            loading={isSaving}
            disabled={isSaving}
            onClick={() => onSave()}
          >
            Delete
          </LoadingButton>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};
