import {
  CustomFieldDefinition,
  wrapQuery,
} from '@fresh-stack/frontend-commons';
import { isError } from '@fresh-stack/fullstack-commons';
import { Box, Grid, Stack, Typography } from '@mui/material';
import { useMemo } from 'react';
import { trpc } from '../../utils';
import { AttributeBreakdownGrid } from './AttributeBreakdownGrid';
import { AttributeBreakdownPie } from './AttributeBreakdownPie';

export const DashboardSectionTransactionActivity = ({
  selectedMonth,
  selectedCurrencyCode,
  selectedCustomField,
}: {
  readonly selectedMonth: Date;
  readonly selectedCurrencyCode: string;
  readonly selectedCustomField: CustomFieldDefinition;
}) => {
  const { result: maybeBreakdownCustomField } = wrapQuery(
    trpc.reports.getMomCustomFieldBreakdown.useQuery(
      {
        classId: selectedCustomField.classId,
        currency: selectedCurrencyCode,
        referenceMonth: selectedMonth.toISOString(),
      },
      {
        refetchOnWindowFocus: false,
        enabled: !!selectedCurrencyCode,
      },
    ),
  );

  const data = useMemo(() => {
    if (!maybeBreakdownCustomField) return undefined;
    else if (isError(maybeBreakdownCustomField))
      throw new Error(); // we are on the home page, show error screen to reload
    else {
      return maybeBreakdownCustomField.right;
    }
  }, [maybeBreakdownCustomField]);

  const pieInflowsData = data?.referenceMonth.entries
    .filter((x) => x.isDeposit)
    .map((x) => ({
      id: x.attributeValueId,
      label:
        selectedCustomField.values.find((v) => v.valueId === x.attributeValueId)
          ?.valueName ?? 'Unknown',
      value: x.amount,
    }));

  const pieOutflowsData = data?.referenceMonth.entries
    .filter((x) => !x.isDeposit)
    .map((x) => ({
      id: x.attributeValueId,
      label:
        selectedCustomField.values.find((v) => v.valueId === x.attributeValueId)
          ?.valueName ?? 'Unknown',
      value: x.amount,
    }));

  return data && pieInflowsData && pieOutflowsData ? (
    <Stack width={'100%'} spacing={1} pl={1}>
      <Box sx={{ maxWidth: 300, borderBottom: 1, borderColor: 'silver' }}>
        <Typography variant="subtitle1" color="primary" gutterBottom>
          Transaction activity
        </Typography>
      </Box>
      <Grid container item xs={12}>
        <Grid item sm={12} md={6} mt={1} pr={1}>
          <AttributeBreakdownGrid
            transactionType="Withdrawals"
            selectedAttribute={selectedCustomField}
            currencyCode={selectedCurrencyCode}
            data={data}
            height={400}
          />
        </Grid>
        <Grid item sm={12} md={6} mt={1} pr={1}>
          <Box borderRadius={1} boxShadow={2} padding={1}>
            <AttributeBreakdownPie
              selectedCurrencyCode={selectedCurrencyCode}
              selectedMonth={selectedMonth}
              data={pieOutflowsData}
              title={'Outflows'}
              height={384}
              selectedAttribute={selectedCustomField}
            />
          </Box>
        </Grid>
      </Grid>
      <Grid container item xs={12}>
        <Grid item sm={12} md={6} mt={1} pr={1}>
          <AttributeBreakdownGrid
            transactionType="Deposits"
            selectedAttribute={selectedCustomField}
            currencyCode={selectedCurrencyCode}
            data={data}
            height={400}
          />
        </Grid>
        <Grid item sm={12} md={6} mt={1} pr={1}>
          <Box borderRadius={1} boxShadow={2} padding={1}>
            <AttributeBreakdownPie
              selectedCurrencyCode={selectedCurrencyCode}
              selectedMonth={selectedMonth}
              data={pieInflowsData}
              title={'Inflows'}
              height={384}
              selectedAttribute={selectedCustomField}
            />
          </Box>
        </Grid>
      </Grid>
    </Stack>
  ) : null;
};
