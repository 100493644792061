import { TabContext, TabPanel } from '@mui/lab';
import { Box, Stack, Tab, Tabs } from '@mui/material';
import { SyntheticEvent, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSafeLog } from '../utils';
import { Error404 } from './Error404';
import { HeaderContainer } from './commons';
import { FONTS, appColors } from './theme';

type BaseTabProps = {
  readonly name: string;
  readonly path: string;
};

type RootTabProps = BaseTabProps & {
  readonly component: (key?: string) => JSX.Element;
};

type ParentTabProps = BaseTabProps & {
  readonly children: readonly RootTabProps[];
};

export const isParentTab = (tab: BaseTabProps): tab is ParentTabProps => {
  return 'children' in tab;
};

export type TabProps = ParentTabProps | RootTabProps;

export const NavigationTabs = ({
  tabs,
  endAdornment,
}: {
  readonly tabs: TabProps[];
  readonly endAdornment?: JSX.Element;
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname;

  const handleChange = (_event: SyntheticEvent, newValue: string) => {
    navigate(newValue);
  };

  return (
    <TabbedLayout
      tabs={tabs}
      endAdornment={endAdornment}
      currentPath={currentPath}
      onChange={handleChange}
    />
  );
};

export const TabbedLayout = ({
  tabs,
  currentPath,
  onChange,
  parentMinHeight = '50%',
  parentMaxHeight = '35px',
  parentFontSize = undefined,
  parentFontFamily = FONTS.montserratBold,
  parentTabBgColour = appColors.secondary,
  childMinHeight = '25%',
  childMaxHeight = '20px',
  childFontSize = '0.75rem',
  childFontFamily = FONTS.inter,
  childTabBgColour = appColors.secondaryDarker,
  endAdornment,
}: {
  readonly tabs: TabProps[];
  readonly currentPath: string;
  readonly onChange: (_event: SyntheticEvent, newValue: string) => void;
  readonly parentMinHeight?: string;
  readonly parentMaxHeight?: string;
  readonly parentFontSize?: string;
  readonly parentFontFamily?: string;
  readonly parentTabBgColour?: string;
  readonly childMinHeight?: string;
  readonly childMaxHeight?: string;
  readonly childFontSize?: string;
  readonly childFontFamily?: string;
  readonly childTabBgColour?: string;
  readonly endAdornment?: JSX.Element;
}) => {
  const safeLog = useSafeLog();
  // Validate tab structure - children tabs must start with parent tab path
  useMemo(() => {
    for (const tab of tabs) {
      if (isParentTab(tab)) {
        for (const subTab of tab.children) {
          if (!subTab.path.startsWith(tab.path)) {
            safeLog(
              `Error: subtab path ${subTab.path} does not start with parent tab path ${tab.path}`,
            );
            throw new Error(
              `Subtab path ${subTab.path} does not start with parent tab path ${tab.path}`,
            );
          }
        }
      }
    }
  }, [tabs, safeLog]);

  const { activeParent, activeChild } = useMemo(() => {
    for (const tab of tabs) {
      if (currentPath.startsWith(tab.path)) {
        if (isParentTab(tab)) {
          for (const child of tab.children) {
            if (currentPath.startsWith(child.path)) {
              return { activeParent: tab, activeChild: child };
            }
          }
          return { activeParent: tab, activeChild: tab.children[0] };
        }
        return { activeParent: tab, activeChild: undefined };
      }
    }
    return { activeParent: undefined, activeChild: undefined };
  }, [tabs, currentPath]);

  if (activeParent === undefined) return <Error404 />;

  const activeParentChildren = isParentTab(activeParent)
    ? activeParent.children
    : undefined;

  return (
    <Stack alignItems={'flex-start'}>
      <Box width={'100%'}>
        <TabContext value={currentPath}>
          <HeaderContainer mb={0} padding={0} pl={0}>
            <Box
              width={'100%'}
              display={'flex'}
              justifyContent={'space-between'}
            >
              <Box
                sx={{
                  borderBottom: 1,
                  borderColor: 'divider',
                }}
              >
                <Tabs
                  textColor="primary"
                  indicatorColor="primary"
                  value={activeParent.path}
                  onChange={onChange}
                  variant="scrollable"
                  sx={{
                    '.MuiTab-root': {
                      minHeight: parentMinHeight,
                      maxHeight: parentMaxHeight,
                      fontSize: parentFontSize,
                      fontFamily: parentFontFamily,
                      textTransform: 'none',
                    },
                    minHeight: parentMinHeight,
                    bgcolor: parentTabBgColour,
                  }}
                >
                  {tabs.map((tab) => (
                    <Tab
                      key={`tab-${tab.path}`}
                      label={tab.name}
                      value={tab.path}
                    />
                  ))}
                </Tabs>
              </Box>
              {activeChild && (
                <Tabs
                  value={activeChild.path}
                  onChange={onChange}
                  textColor="primary"
                  indicatorColor="primary"
                  variant="scrollable"
                  sx={{
                    '.MuiTab-root': {
                      minHeight: childMinHeight,
                      maxHeight: childMaxHeight,
                      fontSize: childFontSize,
                      fontFamily: childFontFamily,
                      textTransform: 'none',
                    },
                    minHeight: childMinHeight,
                    bgcolor: childTabBgColour,
                  }}
                >
                  {activeParentChildren &&
                    activeParentChildren.map((child) => (
                      <Tab
                        key={child.path}
                        label={child.name}
                        value={child.path}
                      />
                    ))}
                </Tabs>
              )}
              <Box mr={1}>{endAdornment}</Box>
            </Box>
          </HeaderContainer>

          {tabs.map((tab) =>
            isParentTab(tab) ? (
              tab.children.map((child) => (
                <TabPanel key={child.path} value={child.path}>
                  {child.component('comp' + child.path)}
                </TabPanel>
              ))
            ) : (
              <TabPanel key={tab.path} value={tab.path}>
                {tab.component('parent' + tab.path)}
              </TabPanel>
            ),
          )}
        </TabContext>
      </Box>
    </Stack>
  );
};
