import {
  ConfirmationModal,
  PageHeader,
  wrapMutation,
  wrapQuery,
} from '@fresh-stack/frontend-commons';
import {
  formatBytes,
  inspectError,
  inspectSuccess,
  isError,
  pipe,
} from '@fresh-stack/fullstack-commons';
import { ResFileUploadDto } from '@fresh-stack/router/types';
import DeleteIcon from '@mui/icons-material/Delete';
import { Box, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro';
import { useSnackbar } from 'notistack';
import React, { MouseEventHandler, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { buildMossUploadFileRoute } from '../../app/routes';
import { NavBarAvatar } from '../../components/navbar/NavBarAvatar';
import { trpc } from '../../utils';
import { MossTransactionImport } from './MossTransactionImport';

const importsColumns: GridColDef<ResFileUploadDto>[] = [
  { field: 'createdAt', headerName: 'Created at', type: 'date', flex: 1 },
  { field: 'fileName', headerName: 'File name', flex: 1 },
  { field: 'fileSize', headerName: 'File size', flex: 1 },
  { field: 'status', headerName: 'Status', flex: 1 },
];

const MOSS_TEMPLATE_URL =
  import.meta.env.VITE_PUBLIC_STORAGE_BUCKET +
  import.meta.env.VITE_UPLOAD_TEMPLATES_MOSS;

export const ImportsPage = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const { result, refetch } = wrapQuery(
    trpc.imports.getImports.useQuery(undefined, {
      refetchOnWindowFocus: false,
    }),
  );

  const { mutateAsync: markForDeletion, isLoading: markingForDeletion } =
    wrapMutation(trpc.imports.markFileForDeletion.useMutation());

  const [fileToRemove, setFileToRemove] = React.useState<
    ResFileUploadDto | undefined
  >();

  const onConfirmRemoveFile = async (fileId: string) => {
    if (fileToRemove) {
      setFileToRemove(undefined);

      pipe(
        await markForDeletion({
          fileId,
        }),
        inspectSuccess(() => {
          enqueueSnackbar({
            variant: 'success',
            message:
              'The upload has been submitted for deletion, it will be removed shortly along with all associated transactions!',
          });
          refetch();
        }),
        inspectError(() =>
          enqueueSnackbar({
            variant: 'error',
            message:
              'We had an issue submitting the file for deletion. Please try again later.',
          }),
        ),
      );
    }
  };

  const importEntries = useMemo(() => {
    if (!result || isError(result)) return [];
    else
      return (
        result?.right.map((x) => ({
          ...x,
          status: x.status ?? 'Not submitted yet',
          fileSize: formatBytes(x.fileSizeBytes),
          createdAt: new Date(x.createdAt),
        })) ?? []
      );
  }, [result]);

  const onDeleteColumn: GridColDef<ResFileUploadDto> = {
    field: 'action',
    headerName: 'Action',
    sortable: false,

    renderCell: (params) => {
      const onClick: MouseEventHandler = (e) => {
        e.stopPropagation(); // don't select or  this row after clicking the delete request
        setFileToRemove(params.row);
      };

      return params.row.isDeleteable ? (
        <IconButton
          disabled={markingForDeletion}
          onClick={onClick}
          color="error"
        >
          <DeleteIcon />
        </IconButton>
      ) : (
        <Tooltip title="This file has been submitted for processing and cannot be deleted yet.">
          <span>
            <IconButton disabled={true}>
              <DeleteIcon color="disabled" />
            </IconButton>
          </span>
        </Tooltip>
      );
    },
  };

  return (
    <Stack alignItems={'flex-start'}>
      {fileToRemove && (
        <ConfirmationModal
          question={`Are you sure you want to submit this file and all its associated transactions for deletion?`}
          handleApprove={() => {
            onConfirmRemoveFile(fileToRemove.id);
          }}
          handleCancel={() => {
            setFileToRemove(undefined);
          }}
        />
      )}
      <Box width={'100%'}>
        <PageHeader
          title={'Moss imports'}
          height={40}
          endAdornment={<NavBarAvatar />}
          mb={0}
        />
        <Stack
          justifyContent={'center'}
          alignItems={'center'}
          padding={2}
          spacing={2}
          minHeight={500}
        >
          <Box>
            <MossTransactionImport
              title={
                importEntries.length
                  ? 'Upload a new Moss CSV export file'
                  : 'Upload your first Moss CSV export file'
              }
              width={500}
            />
            <Typography
              variant="caption"
              color="textSecondary"
              textAlign={'center'}
            >
              Use a file generated in Moss. You can download a template for it{' '}
              <a
                target="_blank"
                href={MOSS_TEMPLATE_URL}
                className="hover:no-underline"
                rel="noreferrer"
              >
                here
              </a>
            </Typography>
          </Box>

          {!!importEntries.length && (
            <Box width={800}>
              <DataGridPro
                sx={{
                  boxShadow: 4,
                  maxHeight: '50vh',
                  padding: 1,
                  '& .MuiDataGridPro-row:hover': {
                    cursor: 'pointer',
                  },
                }}
                rows={importEntries}
                rowSelection={false}
                columns={[...importsColumns, onDeleteColumn]}
                onRowClick={(row) =>
                  navigate(buildMossUploadFileRoute(row.id.toString()))
                }
                initialState={{
                  pagination: { paginationModel: { pageSize: 25 } },
                }}
                pageSizeOptions={[25, 50, 100]}
              />
            </Box>
          )}
        </Stack>
      </Box>
    </Stack>
  );
};
