export type UserType = 'Admin' | 'User';

export const isRoleAllowed = ({
  required,
  actual,
}: {
  readonly required?: UserType;
  readonly actual?: UserType;
}) => {
  if (!actual) return false;
  if (!required) return true;
  switch (actual) {
    case 'Admin':
      return true;
    case 'User':
      return required === 'User';
  }
};
