import { wrapQuery } from '@fresh-stack/frontend-commons';
import { isError, isSuccess } from '@fresh-stack/fullstack-commons';
import EditIcon from '@mui/icons-material/Edit';
import {
  Alert,
  Box,
  Container,
  IconButton,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import React from 'react';
import { trpc } from '../../utils';
import { UpdateUserDetailsDialog } from './UpdateUserDetailsDialog';

export const UserSettingsTab = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [isEditing, setIsEditing] = React.useState(false);

  // we have the user in the context, but we pull it again to be sure we have the latest info
  const { result: maybeUser, refetch } = wrapQuery(
    trpc.organisation.getUserProfile.useQuery(undefined, {
      refetchOnWindowFocus: false,
    }),
  );

  const UserProfile = (input: {
    readonly email: string;
    readonly firstName?: string | undefined;
    readonly lastName?: string | undefined;
    readonly cellphoneNumber?: string | undefined;
    readonly preferredName?: string | undefined;
  }) => {
    const name = `${input.firstName ?? ''} ${input.lastName ?? ''}`.trim();
    return (
      <Box padding={3} boxShadow={4} borderRadius={2} maxWidth={400}>
        <Box display="flex" alignItems={'center'} justifyContent={'flex-start'}>
          <Typography variant="subtitle1">
            {name ? name : 'No name available.'}
          </Typography>
          <Tooltip title="Edit details" onClick={() => setIsEditing(true)}>
            <IconButton sx={{ ml: 1 }} color="primary">
              <EditIcon />
            </IconButton>
          </Tooltip>
        </Box>

        <Typography variant="body1">{input.email}</Typography>
        <Typography variant="body1">
          {input.cellphoneNumber
            ? input.cellphoneNumber
            : 'No phone number available.'}
        </Typography>
      </Box>
    );
  };

  return (
    <Stack alignItems={'flex-start'}>
      {maybeUser && isSuccess(maybeUser) && (
        <UpdateUserDetailsDialog
          isOpen={isEditing}
          firstName={maybeUser.right.firstName}
          lastName={maybeUser.right.lastName}
          cellphoneNumber={maybeUser.right.cellphoneNumber}
          onClose={() => setIsEditing(false)}
          onSuccess={() => {
            refetch();
            setIsEditing(false);
            enqueueSnackbar({
              variant: 'success',
              message: 'Your details have been updated!',
            });
          }}
        />
      )}
      <Container>
        <Stack maxWidth={1600} padding={2} pt={0} width={'100%'}>
          <Stack spacing={1} alignContent={'flex-start'}>
            {!maybeUser ? (
              <Skeleton width="100%" height={400} animation="wave" />
            ) : isError(maybeUser) ? (
              <Alert>
                There has been an issue retrieving your data. Please refresh to
                try again!
              </Alert>
            ) : (
              <UserProfile {...maybeUser.right} />
            )}
          </Stack>
        </Stack>
      </Container>
    </Stack>
  );
};
