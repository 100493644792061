import { Box, Button, Container, Stack, Typography } from '@mui/material';
import Lottie from 'lottie-react';
import animation from '../assets/animations/404.animation.json';
import { FONTS } from './theme';

export const Error404 = () => {
  return (
    <Container>
      <Stack
        width={'100'}
        alignItems={'center'}
        spacing={1}
        paddingTop={10}
        height={'95vh'}
        justifyContent={'center'}
      >
        <Typography
          color="primary"
          variant="h4"
          fontFamily={FONTS.montserratExtraBold}
          gutterBottom
          textAlign={'center'}
        >
          Oops! Page Not Found.
        </Typography>
        <Typography
          textAlign={'center'}
          variant="subtitle1"
          fontFamily={FONTS.latoBold}
          color="primary"
          gutterBottom
        >
          We can't seem to find the page you're looking for.
        </Typography>
        <Typography
          color="primary"
          variant="subtitle1"
          fontFamily={FONTS.latoBold}
          gutterBottom
        >
          But don't worry, you can find plenty of other things on our homepage.
        </Typography>
        <Box sx={{ pt: 2 }}>
          <Button
            variant="contained"
            size="small"
            color="primary"
            onClick={() => (window.location.href = '/')}
          >
            Take me home
          </Button>
        </Box>
        <Lottie animationData={animation} loop={true} />
      </Stack>
    </Container>
  );
};
