import { noop } from '@fresh-stack/fullstack-commons';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import { useEffect, useState } from 'react';

export const useSafeLog = () => {
  // TODO: enable safe log for all users in tooling
  const isConsoleLogEnabled = useFeatureFlagEnabled('allowConsoleLog');

  const [log, setLog] = useState<typeof console.log>(() => noop);

  useEffect(() => {
    if (isConsoleLogEnabled) {
      setLog(() => console.log);
    } else {
      setLog(() => noop);
    }
  }, [isConsoleLogEnabled]);

  return log;
};
