import React, { useState } from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { httpLink } from '@trpc/client';
import { trpc } from '../utils';
import { PUBLIC_ROUTES } from '../app/routes';

export const TrpcProvider = ({
  children,
}: {
  readonly children: readonly React.ReactElement[];
}) => {
  const [queryClient] = useState(() => new QueryClient());

  const { getAccessTokenSilently } = useAuth0();
  const [trpcClient] = useState(() =>
    trpc.createClient({
      links: [
        httpLink({
          url: import.meta.env.VITE_API_URL,
          headers: async () => {
            if (PUBLIC_ROUTES.includes(window.location.pathname)) return {};
            else {
              const token = await getAccessTokenSilently();

              return {
                authorization: token ? `Bearer ${token}` : '',
              };
            }
          },
        }),
      ],
    }),
  );
  return (
    <QueryClientProvider client={queryClient}>
      <trpc.Provider client={trpcClient} queryClient={queryClient}>
        {children}
      </trpc.Provider>
    </QueryClientProvider>
  );
};
