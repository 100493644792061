import {
  AsyncResult,
  inspectError,
  inspectSuccess,
  pipe,
} from '@fresh-stack/fullstack-commons';
import { ReqCreateTxAttribute } from '@fresh-stack/router/types';
import { AttributeDialog, AttributeFormSchemaType } from './AttributeDialog';

export const CreateAttributeDialog = <T,>({
  open,
  onClose,
  onSuccess,
  existingAttributeNames,
  createAttribute,
  setErrorMessage,
  attributeIsSaving,
}: {
  readonly open: boolean;
  readonly onClose: () => void;
  readonly onSuccess: ({ name }: { readonly name: string }) => void;
  readonly existingAttributeNames: readonly string[];
  readonly setErrorMessage: (message: string) => void;
  readonly createAttribute: (
    req: ReqCreateTxAttribute,
  ) => AsyncResult<NonNullable<T>, string>;
  readonly attributeIsSaving: boolean;
}) => {
  const onSave = async (formValues: AttributeFormSchemaType) => {
    const req = {
      name: formValues.name,
      options: formValues.options.map((o) => o.name),
    };
    pipe(
      await createAttribute(req),
      inspectSuccess((_) => onSuccess({ name: formValues.name })),
      inspectError((_) =>
        setErrorMessage(
          'We ran into an issue with your request, please try again!',
        ),
      ),
    );
  };
  return (
    <AttributeDialog
      key={existingAttributeNames.join('')}
      open={open}
      onClose={onClose}
      onSave={onSave}
      otherAttributeNames={existingAttributeNames}
      dialogTitleText="Create custom field"
      submitButtonText="Create"
      isSaving={attributeIsSaving}
      initialValues={{ name: '', options: [{ name: '' }] }}
      initialValuesLoading={false}
    />
  );
};
