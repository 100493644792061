import { appColors } from '@fresh-stack/frontend-commons';
import { Box, Drawer, Link, List } from '@mui/material';
import { useUserContext } from '../../app/hooks/userContext';
import { ROUTES } from '../../app/routes';
import { DRAWER_WIDTH } from '../../layout';
import { Logo } from '../Logo';
import { MenuItem } from './MenuItem';

export const SideMenu = () => {
  const { menu } = useUserContext();

  return (
    <Drawer
      sx={{
        width: DRAWER_WIDTH,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: DRAWER_WIDTH,
          boxSizing: 'border-box',
          bgcolor: appColors.primary,
        },
      }}
      variant="permanent"
      anchor="left"
    >
      <Box m={1}>
        <Logo color={'secondary'} />
      </Box>

      <List
        sx={{ width: '100%', mt: 2 }}
        component="nav"
        aria-labelledby="nested-list-subheader"
      >
        {menu.map((item) => (
          <MenuItem key={'mli' + item.path} item={item} />
        ))}
      </List>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: '100%',
        }}
      >
        <Box />
        <Box pb={2} pl={2}>
          <Box display="flex">
            <Link
              href={ROUTES.Public.Privacy}
              underline="always"
              color={'secondary'}
              target="_blank"
            >
              Privacy policy
            </Link>
          </Box>
        </Box>
      </Box>
    </Drawer>
  );
  // }
};
