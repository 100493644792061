/* eslint-disable no-restricted-imports */
// to avoid the risk of importing lodash in full somewhere
// we're exporting the only lodash utils we use here to use across all projects

import {
  Dictionary,
  IsEqualCustomizer,
  includes,
  isArray,
  isEqualWith,
  isObject,
  omitBy,
} from 'lodash';
export {
  chunk,
  cloneDeep,
  compact,
  countBy,
  difference,
  groupBy,
  head,
  isEqual,
  keyBy,
  last,
  max,
  maxBy,
  min,
  minBy,
  noop,
  partition,
  random,
  reduce,
  sortBy,
  startCase,
  sumBy,
  uniq,
  uniqBy,
  upperFirst,
  xor,
  zip,
} from 'lodash';

export const isEqualIgnoringFields = <T>(
  a: T,
  b: T,
  ignoredKeys: (keyof T)[],
) =>
  isEqualWith(a, b, (_a, _b, key) =>
    ignoredKeys.includes(key as keyof T) ? true : undefined,
  );

// Treat { id: 1, name: 'John' } as equal to { id: 1, name: 'John', age: undefined }
export const isEqualTreatingMissingAsUndefined = <T>(a: T, b: T) =>
  isEqualWith(a, b, comparisonFunc);

const comparisonFunc: IsEqualCustomizer = <T>(
  a: Dictionary<T>,
  b: Dictionary<T>,
) => {
  // If we don't have an object with undefined properties, compare as normal
  if (isArray(a) || isArray(b)) return;
  if (!isObject(a) || !isObject(b)) return;
  if (!includes(a, undefined) && !includes(b, undefined)) return;

  return isEqualWith(
    omitBy(a, (value) => value === undefined),
    omitBy(b, (value) => value === undefined),
    comparisonFunc,
  );
};
