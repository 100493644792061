import { Button, ButtonPropsVariantOverrides } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import { toPng } from 'html-to-image';
import download from 'downloadjs';
import { OverridableStringUnion } from '@mui/types';

export const DownloadElementAsPngButton = ({
  disabled,
  elementRef,
  filename,
  onError,
  variant,
}: {
  readonly disabled: boolean;
  readonly elementRef: React.MutableRefObject<HTMLDivElement | null>;
  readonly filename: string;
  readonly onError: () => void;
  readonly variant?: OverridableStringUnion<
    'text' | 'outlined' | 'contained',
    ButtonPropsVariantOverrides
  >;
}) => {
  const onDownloadChart = async () => {
    if (elementRef.current) {
      try {
        const dataUrl = await toPng(elementRef.current);

        download(dataUrl, `${filename}.png`);
      } catch (_err) {
        onError();
      }
    }
  };
  return (
    <Button
      disabled={disabled}
      size="small"
      variant={variant ?? 'text'}
      onClick={onDownloadChart}
      sx={{ padding: 1, gap: 1, pr: 2, height: 30 }}
    >
      <DownloadIcon fontSize="small" />
      Export
    </Button>
  );
};
