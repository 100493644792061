import { AccountSummary } from '@fresh-stack/frontend-commons';
import { compact } from '@fresh-stack/fullstack-commons';
import { RuleFilter } from '@fresh-stack/router/types';
import { Chip, Grid } from '@mui/material';
import { Stack } from '@mui/system';
import { format } from 'date-fns';

export const ReadonlyRuleFilterView = ({
  filter,
  allAccounts,
}: {
  readonly filter: RuleFilter;
  readonly allAccounts: AccountSummary[];
}) => {
  const accounts = compact(
    filter.accountIds?.map((x) => allAccounts.find((y) => y.accountId === x)),
  );
  return (
    <Stack spacing={1}>
      {filter.txNameContains?.length ? (
        <Grid container item alignItems={'center'} gap={1}>
          {filter.txNameContains.map((act, index) => (
            <Grid item alignItems={'center'} gap={1}>
              <Chip
                variant="outlined"
                size="small"
                sx={{ width: 'fit-content', pl: 1, pr: 1 }}
                label={'Description: ' + act.value.join(',')}
              />
              {index < (filter.txNameContains?.length ?? 0) - 1 ? ' or' : null}
            </Grid>
          ))}
        </Grid>
      ) : null}
      {accounts.length ? (
        <Grid container item alignItems={'center'} gap={1}>
          {accounts.map((act, index) => (
            <>
              <Chip
                variant="outlined"
                size="small"
                sx={{ width: 'fit-content', pl: 1, pr: 1 }}
                label={'Account: ' + act.name}
              />
              {index < accounts.length - 1 ? ' or' : null}
            </>
          ))}
        </Grid>
      ) : null}
      <Grid container item gap={1}>
        {filter.isoCurrencyCode ? (
          <Chip
            variant="outlined"
            size="small"
            sx={{ width: 'fit-content', pl: 1, pr: 1 }}
            label={'Currency: ' + filter.isoCurrencyCode}
          />
        ) : null}
        {filter.isDeposit != null ? (
          <Chip
            variant="outlined"
            size="small"
            sx={{ width: 'fit-content', pl: 1, pr: 1 }}
            label={'Type: ' + (filter.isDeposit ? 'Inflows' : 'Outflows')}
          />
        ) : null}
        {filter.startDate ? (
          <Chip
            variant="outlined"
            size="small"
            sx={{ width: 'fit-content', pl: 1, pr: 1 }}
            label={`After: ${format(new Date(filter.startDate), 'dd MMM yyyy')}`}
          />
        ) : null}
        {filter.endDate ? (
          <Chip
            variant="outlined"
            size="small"
            sx={{ width: 'fit-content', pl: 1, pr: 1 }}
            label={`Before: ${format(new Date(filter.endDate), 'dd MMM yyyy')}`}
          />
        ) : null}
      </Grid>
    </Stack>
  );
};
