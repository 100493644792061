import { Box, Button, Container, Stack, Typography } from '@mui/material';
import Lottie from 'lottie-react';
import animation from '../assets/animations/error.animation.json';
import { FONTS } from './theme';

export const ErrorGeneric = ({
  redirectPath,
}: {
  readonly redirectPath: string;
}) => {
  return (
    <Container>
      <Stack
        width={'100'}
        alignItems={'center'}
        spacing={1}
        paddingTop={10}
        height={'95vh'}
        justifyContent={'center'}
      >
        <Typography
          color="primary"
          variant="h4"
          fontFamily={FONTS.montserratExtraBold}
          gutterBottom
          textAlign={'center'}
        >
          Oops, tiny issue detected!
        </Typography>
        <Typography
          textAlign={'center'}
          variant="subtitle1"
          fontFamily={FONTS.latoBold}
          color="primary"
          gutterBottom
        >
          Nothing to worry about, our elves have been notified about this.
        </Typography>
        <Typography
          color="primary"
          variant="subtitle1"
          fontFamily={FONTS.latoBold}
          gutterBottom
        >
          Let's try this again!
        </Typography>
        <Box sx={{ pt: 2 }}>
          <Button
            variant="contained"
            size="small"
            color="primary"
            onClick={() => (window.location.href = redirectPath)}
          >
            Take me home
          </Button>
        </Box>
        <Typography variant="caption">
          If you need any help, please contact{' '}
          <a
            target="_blank"
            href="mailto:support@block.green"
            className="hover:no-underline"
            rel="noreferrer"
          >
            support@block.green
          </a>
        </Typography>
        <Lottie animationData={animation} loop={true} />
      </Stack>
    </Container>
  );
};
