import { useLocation, useNavigate } from 'react-router-dom';

import { Tooltip, Typography } from '@mui/material';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { AppMenuItem } from '../../app/routes';
import ComingSoonIcon from '@mui/icons-material/LockClockOutlined';
import { appColors } from '@fresh-stack/frontend-commons';

export const MenuItem = ({ item }: { readonly item: AppMenuItem }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const isSelected = location.pathname === item.path;
  return (
    <Tooltip title={item.isLocked ? 'Coming soon. We will let you know!' : ''}>
      <span>
        <ListItemButton
          key={'key-lib-mi-' + item.name}
          disabled={item.isLocked}
          sx={{
            bgcolor: isSelected ? appColors.secondary : null,
            padding: 0.5,
            '&:hover': {
              textDecoration: 'underline',
              bgcolor: isSelected ? appColors.secondary : null,
            },
          }}
          onClick={() => navigate(item.path)}
        >
          <ListItemIcon sx={{ ml: 1, mr: -2 }}>
            {item.icon({ color: isSelected ? 'primary' : 'secondary' })}
          </ListItemIcon>
          <ListItemText>
            <Typography color={isSelected ? 'primary' : 'secondary'}>
              {item.name}
            </Typography>
          </ListItemText>
          {item.isLocked && (
            <ListItemIcon>
              <ComingSoonIcon color="secondary" />
            </ListItemIcon>
          )}
        </ListItemButton>
      </span>
    </Tooltip>
  );
};
