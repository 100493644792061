import { UserDefinedAccountTypeDto } from '@fresh-stack/router/types';
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useState } from 'react';

const OPTION_REMOVE = {
  id: 'REMOVE',
  label: '---Clear account type---',
  affected: 0,
  addedAt: new Date(),
  index: -1,
};
export const EditAccountTypeModal = ({
  accountTypes,
  currentAccountTypeId,
  handleApprove,
  handleCancel,
}: {
  readonly accountTypes: UserDefinedAccountTypeDto[];
  readonly currentAccountTypeId: string | undefined;
  readonly handleApprove: (
    type:
      | { readonly kind: 'update'; readonly value: UserDefinedAccountTypeDto }
      | { readonly kind: 'clear' },
  ) => void;
  readonly handleCancel: () => void;
}) => {
  const [selectedType, setSelectedType] = useState<
    UserDefinedAccountTypeDto | undefined
  >(accountTypes.find((x) => x.id === currentAccountTypeId));

  const typeEntries = currentAccountTypeId
    ? [OPTION_REMOVE, ...accountTypes]
    : accountTypes;

  return (
    <Dialog open={true} onClose={handleCancel}>
      <DialogTitle id="alert-dialog-title">{'Update account type'}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <Stack width={500} height={150} spacing={2}>
            <Typography>Please select the new account type.</Typography>
            <Autocomplete
              disablePortal
              id="select-country"
              fullWidth
              size="small"
              onChange={(_, value) => {
                if (value) setSelectedType(value);
              }}
              options={typeEntries}
              value={selectedType}
              ListboxProps={{ style: { maxHeight: 200 } }}
              placeholder="Account type"
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Account type"
                  fullWidth
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'new-password', // disable autocomplete and autofill
                  }}
                />
              )}
            />
          </Stack>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {selectedType && selectedType.id !== currentAccountTypeId && (
          <Button
            variant="contained"
            color="success"
            onClick={() => {
              if (selectedType.id === OPTION_REMOVE.id)
                handleApprove({ kind: 'clear' });
              else handleApprove({ kind: 'update', value: selectedType });
            }}
          >
            Save
          </Button>
        )}
        <Button
          variant="contained"
          color="error"
          onClick={handleCancel}
          autoFocus
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};
