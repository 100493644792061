import { ScrollRestoration } from 'react-router-dom';

import { Box, CssBaseline } from '@mui/material';
import { SideMenu } from '../components/navbar/SideMenu';
import { appColors } from '@fresh-stack/frontend-commons';

export interface LayoutProps {
  readonly children?: readonly JSX.Element[] | JSX.Element;
}
export const DRAWER_WIDTH = 200;

export const MainLayout = ({ children }: LayoutProps) => {
  return (
    <Box
      sx={{ display: 'flex', overflow: 'hidden', boxSizing: 'border-box' }}
      bgcolor={appColors.secondaryLighter}
      height={'99.4vh'}
    >
      <CssBaseline />

      <Box width={DRAWER_WIDTH}>
        <SideMenu />
      </Box>
      <Box width={'100%'} overflow={'auto'}>
        {children}
      </Box>
      <ScrollRestoration />
    </Box>
  );
};
