import {
  ConfirmationModal,
  wrapMutation,
  wrapQuery,
} from '@fresh-stack/frontend-commons';
import { isError, noop } from '@fresh-stack/fullstack-commons';
import { UserDefinedAccountTypeDto } from '@fresh-stack/router/types';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import EditNoteIcon from '@mui/icons-material/EditNote';
import { LoadingButton } from '@mui/lab';
import {
  Alert,
  Box,
  Container,
  IconButton,
  Link,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro';
import { useSnackbar } from 'notistack';
import { useMemo, useState } from 'react';
import { trpc } from '../../utils';
import { ROUTES } from '../../app/routes';

export const AccountTypeSettings = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [newLabel, setNewLabel] = useState<string | undefined>();
  const [labelToDelete, setLabelToDelete] = useState<
    UserDefinedAccountTypeDto | undefined
  >();

  const [labelToRename, setLabelToRename] = useState<
    UserDefinedAccountTypeDto | undefined
  >();

  const [newLabelName, setNewLabelName] = useState<string | undefined>();

  const {
    result,
    isFetching: isLoadingAccountTypes,
    refetch: refetchAccountTypes,
  } = wrapQuery(
    trpc.banking.accountAttribute.listAccountTypes.useQuery(undefined, {
      refetchOnMount: true,
    }),
  );
  const rows = useMemo(() => {
    return !result || isError(result) ? [] : result?.right ?? [];
  }, [result]);

  const cleanNewLabel = newLabel?.trim();

  const isExistingLabel = useMemo(() => {
    return rows.some(
      (x) => x.label.toLowerCase() === cleanNewLabel?.toLowerCase(),
    );
  }, [cleanNewLabel, rows]);

  const isValidNewLabel = useMemo(() => {
    return !!cleanNewLabel && !isExistingLabel;
  }, [cleanNewLabel, isExistingLabel]);

  const { mutateAsync: addAccountType, isLoading: isAdding } = wrapMutation(
    trpc.banking.accountAttribute.addAccountType.useMutation(),
  );

  const { mutateAsync: updateAccountTypeLabel, isLoading: isUpdating } =
    wrapMutation(
      trpc.banking.accountAttribute.updateAccountTypeLabel.useMutation(),
    );

  const { mutateAsync: deleteAccountType, isLoading: isDeleting } =
    wrapMutation(trpc.banking.accountAttribute.deleteAccountType.useMutation());

  const onAddAccountType = async (input: string) => {
    const clean = input.trim();
    const result = await addAccountType(clean);
    refetchAccountTypes();
    if (isError(result)) {
      enqueueSnackbar({
        message: `There was an error while adding the new account type. Please try again.`,
        variant: 'error',
      });
    } else {
      setNewLabel('');
      enqueueSnackbar({
        message: `Added new account type: ${clean}.`,
        variant: 'success',
      });
    }
  };

  const isSavingSomething = isAdding || isDeleting || isUpdating;

  const actionColumn: GridColDef<UserDefinedAccountTypeDto> | undefined =
    useMemo(() => {
      return {
        field: 'actions',
        headerName: 'Actions',
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        renderCell: (params) => {
          return (
            <Box display={'flex'} pl={1}>
              <IconButton
                onClick={() => setLabelToDelete(params.row)}
                disabled={isSavingSomething}
              >
                <DeleteIcon fontSize="small" color="error" />
              </IconButton>
            </Box>
          );
        },
      };
    }, [isSavingSomething]);

  const onConfirmDelete = async (accountType: UserDefinedAccountTypeDto) => {
    const result = await deleteAccountType(accountType.id);
    setLabelToDelete(undefined);
    refetchAccountTypes();
    if (isError(result)) {
      enqueueSnackbar({
        message: `There was an error on removing account type. Please check if it still exists and try again.`,
        variant: 'error',
      });
    } else {
      enqueueSnackbar({
        message: `Successfully removed ${accountType.label} account type.`,
        variant: 'success',
      });
    }
  };

  const labelColumn: GridColDef<UserDefinedAccountTypeDto> = useMemo(() => {
    return {
      field: 'label',
      headerName: 'Name',
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        const onUpdateAccountTypeLabel = async (
          entry: UserDefinedAccountTypeDto,
          input: string,
        ) => {
          const clean = input.trim();
          const result = await updateAccountTypeLabel({
            id: entry.id,
            label: clean,
          });
          refetchAccountTypes();
          if (isError(result)) {
            enqueueSnackbar({
              message: `There was an error while updating the account type. Please try again.`,
              variant: 'error',
            });
          } else {
            setLabelToRename(undefined);
            setNewLabelName(undefined);
            enqueueSnackbar({
              message: `Account type updated.`,
              variant: 'success',
            });
          }
        };

        const cleanNewLabelName = newLabelName?.trim();

        const alreadyExists = rows.some(
          (x) => x.label.toLowerCase() === cleanNewLabelName?.toLowerCase(),
        );

        const isSaveDisabled =
          isSavingSomething ||
          !cleanNewLabelName ||
          cleanNewLabelName === labelToRename?.label ||
          alreadyExists;

        return labelToRename && labelToRename.id === params.row.id ? (
          <Box display={'flex'} padding={1} alignItems={'center'}>
            <TextField
              value={newLabelName}
              label={'New name'}
              placeholder="New name"
              size="small"
              disabled={isSavingSomething}
              rows={2}
              onKeyDown={(e) => {
                e.stopPropagation();
              }}
              onChange={(value) => setNewLabelName(value.target.value)}
            ></TextField>
            <Tooltip title={'Save'}>
              <IconButton
                onClick={() => {
                  if (newLabelName)
                    onUpdateAccountTypeLabel(params.row, newLabelName);
                }}
                disabled={isSaveDisabled}
              >
                <CheckIcon
                  fontSize="small"
                  color={isSaveDisabled ? 'disabled' : 'success'}
                />
              </IconButton>
            </Tooltip>
            <Tooltip title={'Cancel'}>
              <IconButton
                onClick={() => {
                  setLabelToRename(undefined);
                  setNewLabelName(undefined);
                }}
                disabled={isSavingSomething}
              >
                <CloseIcon fontSize="small" color="error" />
              </IconButton>
            </Tooltip>
          </Box>
        ) : (
          <Box display={'flex'} padding={1} alignItems={'center'}>
            <Typography>{params.row.label}</Typography>
            <Tooltip title={'Update name'}>
              <IconButton
                onClick={() => {
                  setLabelToRename(params.row);
                  setNewLabelName(params.row.label);
                }}
                disabled={isSavingSomething}
              >
                <EditNoteIcon fontSize="small" color="success" />
              </IconButton>
            </Tooltip>
          </Box>
        );
      },
    };
  }, [
    enqueueSnackbar,
    isSavingSomething,
    labelToRename,
    newLabelName,
    refetchAccountTypes,
    rows,
    updateAccountTypeLabel,
  ]);

  const baseColumns: readonly GridColDef<UserDefinedAccountTypeDto>[] = [
    labelColumn,
    {
      field: 'affected',
      sortable: false,
      headerName: 'Associated accounts',
      width: 200,
      type: 'number',
      disableColumnMenu: true,
    },
    actionColumn,
  ];

  return (
    <Stack alignItems={'flex-start'}>
      {labelToDelete && (
        <ConfirmationModal
          question={
            <>
              Are you sure you want to delete the{' '}
              <strong>{labelToDelete.label}</strong> account type?
            </>
          }
          handleApprove={() => onConfirmDelete(labelToDelete)}
          handleCancel={() => setLabelToDelete(undefined)}
        />
      )}
      <Container>
        <Stack
          padding={2}
          pt={0}
          alignItems={'center'}
          width={'100%'}
          maxWidth={1600}
        >
          <Stack
            spacing={2}
            alignContent={'flex-start'}
            width={'100%'}
            maxWidth={800}
          >
            <Alert variant="outlined" severity="info" icon={null}>
              <Typography variant="body2">
                Categorise your{' '}
                <Link underline="always" href={ROUTES.Commons.Accounts}>
                  Accounts
                </Link>{' '}
                using your own types to enable analysis and aggregation of your
                bank accounts by these fields across Ribon.
              </Typography>
            </Alert>
            <Stack spacing={1} alignItems={'flex-start'} width={'100%'}>
              <Box width={'100%'}>
                <Stack
                  direction={'row'}
                  spacing={1}
                  alignItems={'flex-start'}
                  width={'100%'}
                >
                  <Box width={400} gap={1}>
                    <TextField
                      size="small"
                      fullWidth
                      value={newLabel}
                      label={'New account type'}
                      placeholder="e.g. 'Savings account'"
                      onChange={(value) => setNewLabel(value.target.value)}
                      disabled={isSavingSomething || isLoadingAccountTypes}
                    ></TextField>
                    {isExistingLabel && (
                      <Typography variant="caption" color={'error'}>
                        There is already an account type with this name.
                      </Typography>
                    )}
                  </Box>
                  <LoadingButton
                    sx={{ width: 250, height: 40 }}
                    loading={isSavingSomething}
                    disabled={!isValidNewLabel || isLoadingAccountTypes}
                    variant="contained"
                    onClick={() =>
                      cleanNewLabel ? onAddAccountType(cleanNewLabel) : noop()
                    }
                  >
                    Add new type
                  </LoadingButton>
                </Stack>
              </Box>
              <DataGridPro
                getRowHeight={() => 50}
                rowReordering={rows.length > 1}
                sx={{
                  boxShadow: 1,
                  padding: 1,
                  width: '100%',
                  maxHeight: '60vh',
                  '& .MuiDataGridPro-row:hover': {
                    cursor: 'pointer',
                  },
                }}
                rows={rows}
                autoHeight
                columns={baseColumns}
                initialState={{
                  pagination: { paginationModel: { pageSize: 50 } },
                }}
                pagination
                loading={isLoadingAccountTypes}
                rowSelection={false}
                disableMultipleRowSelection={true}
                slots={{
                  noRowsOverlay: () => (
                    <Stack
                      alignItems={'center'}
                      justifyContent={'center'}
                      width={'100%'}
                      height={'100%'}
                      sx={{ minHeight: '60px' }}
                    >
                      <Typography variant="body2">
                        No account types defined yet
                      </Typography>
                    </Stack>
                  ),
                }}
              />
            </Stack>
          </Stack>
        </Stack>
      </Container>
    </Stack>
  );
};
