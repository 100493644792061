import {
  AccountSummary,
  CustomFieldDefinition,
  DialogFadeTransition,
  FilterDatePicker,
  TRANSACTION_TYPES,
} from '@fresh-stack/frontend-commons';
import {
  ALL,
  head,
  isEqualTreatingMissingAsUndefined,
} from '@fresh-stack/fullstack-commons';
import { zodResolver } from '@hookform/resolvers/zod';
import AddIcon from '@mui/icons-material/AddCircle';
import InfoIcon from '@mui/icons-material/Info';
import { LoadingButton } from '@mui/lab';
import {
  Alert,
  Autocomplete,
  Badge,
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { z } from 'zod';
import { ACTION_HEADER, CONDITION_HEADER } from './common';

const SchemaFilter = z.object({
  isoCurrencyCode: z.string(),
  accountIds: z.array(z.string()).optional(),
  txNameContains: z.array(
    z.object({
      databaseId: z.string().optional().nullable(),
      value: z.string(),
    }),
  ),
  transactionType: z
    .union([z.literal(ALL), z.literal('Inflows'), z.literal('Outflows')])
    .default(ALL),
  startDate: z.date().optional(),
  endDate: z.date().optional(),
});

type RuleFilter = z.infer<typeof SchemaFilter>;

const SchemaRuleDefinition = z.object({
  filter: SchemaFilter,
  customFieldId: z.string(),
  customFieldValueId: z.string(),
});

type RuleDefinition = z.infer<typeof SchemaRuleDefinition>;

export const RuleDialog = ({
  onClose,
  onSave,
  isSaving,
  initialRuleFilter,
  dialogTitleText,
  availableCurrencies,
  availableAccounts,
  customFields,
  editMode,
}: {
  readonly onClose: () => void;
  readonly onSave: (rule: RuleDefinition) => void;
  readonly isSaving: boolean;
  readonly dialogTitleText: string;
  readonly availableCurrencies: string[];
  readonly availableAccounts: AccountSummary[];

  readonly initialRuleFilter: RuleFilter;
  readonly customFields: CustomFieldDefinition[];
  readonly editMode:
    | {
        readonly kind: 'allow-choice';
        readonly initialCustomFieldId?: undefined;
        readonly initialCustomFieldValueId?: undefined;
      }
    | {
        readonly kind: 'fixed';
        readonly initialCustomFieldId: string;
        readonly initialCustomFieldValueId: string | undefined;
      };
}) => {
  const { control, handleSubmit, watch, setValue } = useForm<RuleDefinition>({
    resolver: zodResolver(SchemaRuleDefinition),
    defaultValues: {
      filter: {
        ...initialRuleFilter,
        txNameContains: initialRuleFilter.txNameContains.length
          ? initialRuleFilter.txNameContains.filter((x) => !!x.value.trim())
          : [],
      },
      customFieldId:
        editMode.initialCustomFieldId ?? head(customFields)?.classId,
      customFieldValueId:
        customFields
          .find(
            (x) =>
              !editMode.initialCustomFieldId ||
              x.classId === editMode.initialCustomFieldId,
          )
          ?.values?.find(
            (x) =>
              !editMode.initialCustomFieldValueId ||
              x.valueId === editMode.initialCustomFieldValueId,
          )?.valueId ?? undefined,
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'filter.txNameContains',
  });

  const [candidateSearch, setCandidateSearch] = useState('');
  const isValidCandidate = useMemo(() => {
    return (
      candidateSearch
        .trim()
        .split(' ')
        .filter((x) => !!x).length > 0
    );
  }, [candidateSearch]);

  const handleAddFilter = () => {
    if (isValidCandidate) {
      append({
        value: candidateSearch,
        databaseId: null,
      });
      setCandidateSearch('');
    }
  };

  const selectedFieldId = watch('customFieldId');
  const selectedField = customFields.find((x) => x.classId === selectedFieldId);

  const [filterHasValues, setFilterHasValues] = useState(true);
  const [isSaveAllowed, setIsSaveAllowed] = useState(
    editMode.kind === 'allow-choice',
  );

  useEffect(() => {
    const subscription = watch((newFormState) => {
      const selectedField = customFields.find(
        (x) => x.classId === newFormState.customFieldId,
      );
      if (
        selectedField &&
        !selectedField.values.some(
          (x) => x.valueId === newFormState.customFieldValueId,
        )
      ) {
        setValue(
          'customFieldValueId',
          head(selectedField.values)?.valueId ?? '',
        );
      }
    });

    return () => subscription.unsubscribe();
  }, [
    customFields,
    editMode.initialCustomFieldValueId,
    editMode.kind,
    initialRuleFilter,
    setValue,
    watch,
  ]);

  useEffect(() => {
    const subscription = watch((newFormState) => {
      const currentFilter = newFormState.filter;
      const newFilterHasValues =
        currentFilter?.isoCurrencyCode !== ALL ||
        !!currentFilter.accountIds?.length ||
        !!currentFilter.txNameContains?.some((x) => !!x?.value?.trim()) ||
        currentFilter.transactionType !== ALL ||
        currentFilter.startDate !== undefined ||
        currentFilter.endDate !== undefined;

      setFilterHasValues(newFilterHasValues);

      if (!newFilterHasValues || !newFormState.customFieldValueId)
        setIsSaveAllowed(false);
      else if (editMode.kind === 'allow-choice') setIsSaveAllowed(true);
      else if (
        editMode.kind === 'fixed' &&
        (newFormState.customFieldValueId !==
          editMode.initialCustomFieldValueId ||
          !isEqualTreatingMissingAsUndefined(initialRuleFilter, currentFilter))
      ) {
        setIsSaveAllowed(true);
      } else return setIsSaveAllowed(false);
    });

    return () => subscription.unsubscribe();
  }, [
    editMode.initialCustomFieldValueId,
    editMode.kind,
    initialRuleFilter,
    watch,
  ]);

  return (
    <Dialog
      open={true}
      TransitionComponent={DialogFadeTransition}
      onClose={onClose}
      sx={{ '& .MuiDialog-paper': { minWidth: '1000px' } }}
    >
      <DialogTitle variant="h5" fontWeight={500}>
        {dialogTitleText}
      </DialogTitle>
      <DialogContent sx={{ width: '100%' }}>
        <Typography variant="body1" fontWeight={400}>
          Create an automation rule which will apply to all transactions.
        </Typography>
        <form onSubmit={handleSubmit(onSave)}>
          <Divider sx={{ mt: 4, mb: 2 }} />
          <Box display={'flex'} alignItems={'center'} gap={1}>
            <Typography variant="h6" fontWeight={500} gutterBottom>
              {CONDITION_HEADER}{' '}
            </Typography>
            <Tooltip
              title={
                'Define the conditions that will trigger this rule. Note that this ' +
                'will also apply to historical transactions. To apply this rule to ' +
                'only future transactions, set the start date to the current date.'
              }
              color="info"
            >
              <InfoIcon />
            </Tooltip>
          </Box>
          {!filterHasValues && (
            <Box sx={{ mb: 1 }}>
              <Alert severity="error">
                At least one filter condition is required.
              </Alert>
            </Box>
          )}
          <Stack spacing={2}>
            <Box borderRadius={2} border={0} boxShadow={1} padding={2}>
              <Box display={'flex'} alignItems={'center'}>
                <TextField
                  value={candidateSearch}
                  label={'Transaction description contains'}
                  sx={{ width: 300 }}
                  size="small"
                  fullWidth
                  onChange={(value) => setCandidateSearch(value.target.value)}
                  onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                      event.preventDefault();
                      handleAddFilter();
                    }
                  }}
                ></TextField>
                <Tooltip
                  title={
                    isValidCandidate
                      ? 'Add description filter'
                      : 'A valid string is required'
                  }
                >
                  <span>
                    <IconButton
                      disabled={!isValidCandidate}
                      color="success"
                      onClick={handleAddFilter}
                    >
                      <AddIcon fontSize="large" />
                    </IconButton>
                  </span>
                </Tooltip>
              </Box>

              {fields.length ? (
                <Stack
                  direction="row"
                  alignItems={'center'}
                  sx={{ flexDirection: 'row', flexWrap: 'wrap', gap: 1, pt: 1 }}
                >
                  {fields.map((field, index) =>
                    field.value ? (
                      <>
                        <Badge
                          color="success"
                          variant="dot"
                          anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                          }}
                          invisible={!!field.databaseId}
                        >
                          <Chip
                            key={'chip-description-' + index}
                            size="small"
                            sx={{ width: 'fit-content', pl: 1, pr: 1 }}
                            label={'Description: ' + field.value}
                            onDelete={() => remove(index)}
                          />
                        </Badge>
                        {index < (fields?.length ?? 0) - 1 ? ' or' : null}
                      </>
                    ) : null,
                  )}
                </Stack>
              ) : null}
            </Box>
            <Box borderRadius={2} border={0} boxShadow={1} padding={2}>
              <Controller
                control={control}
                name="filter.accountIds"
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <Autocomplete
                    multiple
                    id="autocomplete-accounts"
                    options={availableAccounts}
                    getOptionLabel={(option) => option.name}
                    sx={{ width: 'fit-content', minWidth: 300, maxWidth: 1200 }}
                    value={availableAccounts.filter((x) =>
                      value?.includes(x.accountId),
                    )}
                    onChange={(_event, newValue) => {
                      onChange(newValue.map((x) => x.accountId));
                    }}
                    onBlur={onBlur}
                    ref={ref}
                    size="small"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Accounts"
                        variant="outlined"
                        size="small"
                        sx={{ minWidth: 120 }}
                      />
                    )}
                    renderOption={(props, option) => (
                      <MenuItem {...props} key={option.accountId}>
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            width: '100%',
                          }}
                        >
                          <Typography
                            sx={{
                              mr: 1,
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                            }}
                            noWrap
                          >
                            {option.name}
                          </Typography>
                          <Typography fontFamily="monospace">
                            {option.mask ? `**** ${option.mask}` : ''}
                          </Typography>
                        </Box>
                      </MenuItem>
                    )}
                    slotProps={{
                      paper: {
                        style: { width: 'auto', minWidth: '300px' },
                      },
                    }}
                  />
                )}
              />
              <Stack
                direction="row"
                alignItems={'center'}
                sx={{ flexDirection: 'row', flexWrap: 'wrap', gap: 1, pt: 3 }}
              >
                <FormControl sx={{ minWidth: 120 }}>
                  <InputLabel id="select-currency-label" size="small">
                    Currency
                  </InputLabel>
                  <Controller
                    control={control}
                    name="filter.isoCurrencyCode"
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <Select
                        labelId="select-currency-label"
                        id="select-currency"
                        value={value}
                        fullWidth
                        size="small"
                        label="Currency"
                        onChange={(change) => {
                          if (change.target.value)
                            onChange(change.target.value.toString());
                        }}
                        onBlur={onBlur}
                        ref={ref}
                      >
                        {availableCurrencies.map((x) => (
                          <MenuItem key={'mi-cry-' + x} value={x}>
                            {x}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                </FormControl>
                <Controller
                  control={control}
                  name="filter.transactionType"
                  render={({ field }) => (
                    <ToggleButtonGroup
                      size="small"
                      color="success"
                      exclusive
                      {...field}
                    >
                      {TRANSACTION_TYPES.map((x) => (
                        <ToggleButton
                          key={'tb-type-' + x}
                          value={x}
                          size="small"
                        >
                          {x}
                        </ToggleButton>
                      ))}
                    </ToggleButtonGroup>
                  )}
                />
                <Stack
                  direction="row"
                  alignItems={'center'}
                  sx={{ flexDirection: 'row', flexWrap: 'wrap', gap: 1 }}
                >
                  <Controller
                    control={control}
                    name="filter.startDate"
                    render={({ field: { onChange, value } }) => (
                      <FilterDatePicker
                        value={value}
                        setValue={onChange}
                        label="Start date"
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name="filter.endDate"
                    render={({ field: { onChange, value } }) => (
                      <FilterDatePicker
                        value={value}
                        setValue={onChange}
                        label="End date"
                      />
                    )}
                  />
                </Stack>
              </Stack>
            </Box>
          </Stack>
          <Divider sx={{ mt: 4, mb: 2 }} />
          <Box display={'flex'} alignItems={'center'} gap={1} pb={1}>
            <Typography variant="h6" fontWeight={500}>
              {ACTION_HEADER}
            </Typography>
            <Tooltip
              color="info"
              title="Define the custom field value that will be set when this rule is triggered."
            >
              <InfoIcon />
            </Tooltip>
          </Box>
          <Stack direction="row" alignItems={'center'} gap={2} pt={1}>
            <FormControl sx={{ minWidth: 200 }}>
              <InputLabel id="select-valueid-label" size="small">
                Custom field
              </InputLabel>
              <Controller
                control={control}
                name="customFieldId"
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <Select
                    labelId="select-valueid-label"
                    id="select-valueid"
                    value={value}
                    fullWidth
                    size="small"
                    disabled={editMode.kind === 'fixed'}
                    label="Custom field"
                    onChange={(change) => {
                      if (change.target.value)
                        onChange(change.target.value.toString());
                    }}
                    onBlur={onBlur}
                    ref={ref}
                    renderValue={(selected) =>
                      customFields.find((x) => x.classId === selected)
                        ?.className
                    }
                  >
                    {customFields.map((x) => (
                      <MenuItem key={'mi-field-' + x.classId} value={x.classId}>
                        {x.className}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </FormControl>
            <FormControl sx={{ minWidth: 200 }}>
              <InputLabel id="select-valueid-label" size="small">
                Option
              </InputLabel>
              <Controller
                control={control}
                name="customFieldValueId"
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <Select
                    labelId="select-valueid-label"
                    id="select-valueid"
                    value={value}
                    fullWidth
                    size="small"
                    label="Option"
                    onChange={(change) => {
                      if (change.target.value)
                        onChange(change.target.value.toString());
                    }}
                    onBlur={onBlur}
                    ref={ref}
                    renderValue={(selected) =>
                      selectedField?.values.find((x) => x.valueId === selected)
                        ?.valueName
                    }
                  >
                    {selectedField?.values.map((x) => (
                      <MenuItem key={'mi-val-' + x.valueId} value={x.valueId}>
                        {x.valueName}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </FormControl>
          </Stack>
          <DialogActions>
            <LoadingButton
              type="submit"
              variant="contained"
              color="success"
              loading={isSaving}
              disabled={!isSaveAllowed}
            >
              Save
            </LoadingButton>
            <Button onClick={onClose} variant="contained" color="error">
              Cancel
            </Button>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
};
