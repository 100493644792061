import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { LoadingButton } from '@mui/lab';

interface DownloadButtonProps {
  readonly text?: string;
  readonly isLoading: boolean;
  readonly onClick: () => void;
  readonly style?: React.CSSProperties;
  readonly size?: 'small' | 'medium' | 'large';
  readonly disabled?: boolean;
}

export const DownloadButton = ({
  text,
  isLoading,
  onClick,
  style,
  size,
  disabled,
}: DownloadButtonProps) => {
  return (
    <LoadingButton
      disabled={disabled}
      sx={{ width: 'fit-content', whiteSpace: 'nowrap' }}
      variant="contained"
      color="primary"
      size={size}
      onClick={onClick}
      loading={isLoading}
      startIcon={<FileDownloadIcon />}
      loadingPosition="start"
      style={style}
    >
      {text ?? 'Download'}
    </LoadingButton>
  );
};
