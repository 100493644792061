import { NavigationTabs, TabProps } from '@fresh-stack/frontend-commons';
import { ROUTES } from '../../app/routes';
import { AllTransactionsTab } from './AllTransactionsTab';
import { AttributeSettingsTab } from '../Account/AttributeSettingsTab';
import { AutomationRulesTab } from '../Automation/AutomationRulePage';
import { NavBarAvatar } from '../../components/navbar/NavBarAvatar';

const tabs: TabProps[] = [
  {
    name: 'Transactions',
    path: ROUTES.Commons.Transactions,
    component: (key?: string) => <AllTransactionsTab key={key} />,
  },
  {
    name: 'Transaction custom fields',
    path: ROUTES.Settings.Organisation.Attributes,
    component: (key?: string) => <AttributeSettingsTab key={key} />,
  },
  {
    name: 'Automation rules',
    path: ROUTES.Commons.TransactionRules,
    component: (key?: string) => <AutomationRulesTab key={key} />,
  },
];

export const TransactionTabs = () => {
  return <NavigationTabs tabs={tabs} endAdornment={<NavBarAvatar />} />;
};
