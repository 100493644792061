import {
  FileDragAndDropUploader,
  useSafeLog,
  wrapMutation,
} from '@fresh-stack/frontend-commons';
import {
  DateOrStringOrNumber,
  isError,
  isSuccess,
} from '@fresh-stack/fullstack-commons';
import { LoadingButton } from '@mui/lab';
import { Chip, Stack } from '@mui/material';
import { GridValidRowModel } from '@mui/x-data-grid-pro';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { buildMossUploadFileRoute } from '../../app/routes';
import { trpc } from '../../utils';

import { useSnackbar } from 'notistack';

export interface UserRow extends GridValidRowModel {
  readonly email: string;
  readonly firstName: string;
  readonly lastName: string;
  readonly roleId: string;
  readonly createdAt: DateOrStringOrNumber;
}

export const MossTransactionImport = ({
  width,
  title,
}: {
  readonly width?: number;
  readonly title?: string;
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const [availableFile, setAvailableFile] = React.useState<File | undefined>();
  const navigate = useNavigate();

  //we have to because the upload of the file does not have a loading indicator
  const [isLoading, setIsLoading] = React.useState(false);

  const { mutateAsync: generateUrl } = wrapMutation(
    trpc.imports.generateMossImportUploadUrl.useMutation(),
  );

  const { mutateAsync: parseMossFile } = wrapMutation(
    trpc.imports.parseMossFileAndSave.useMutation(),
  );

  const safeLog = useSafeLog();

  const uploadAndParse = async () => {
    if (availableFile) {
      setIsLoading(true);
      try {
        const result = await generateUrl({
          fileName: availableFile.name,
          fileSize: availableFile.size,
        });
        if (isSuccess(result)) {
          const uploadResult = await fetch(result.right.url, {
            method: 'PUT',
            headers: {
              'Content-Type': 'text/csv',
            },
            body: await availableFile.arrayBuffer(),
          });

          safeLog('upload result', uploadResult, availableFile);

          if (uploadResult.ok) {
            const fileId = result.right.id;
            const dbResult = await parseMossFile({
              fileId: fileId,
              fileName: availableFile.name,
              fileSize: availableFile.size,
            });

            if (isError(dbResult)) {
              safeLog('parse error', dbResult);
              enqueueSnackbar({
                variant: 'error',
                message:
                  'There was an issue uploading your file. Please try again!',
              });
              setAvailableFile(undefined);
              setIsLoading(false);
            } else {
              switch (dbResult.right.kind) {
                case 'invalid-row':
                  enqueueSnackbar({
                    variant: 'error',
                    message: `We found an issue in the file: ${dbResult.right.errorMessage} at row ${dbResult.right.index + 1}.`,
                  });
                  break;
                case 'success':
                  navigate(buildMossUploadFileRoute(fileId));
                  break;
              }
            }
          }
        }
      } catch (err) {
        enqueueSnackbar({
          variant: 'error',
          message:
            'There was an issue while processing your file, please try again!',
        });
        safeLog('error:', err);
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <Stack alignItems={'flex-start'}>
      <Stack spacing={2} direction={'row'} alignItems={'center'}>
        <FileDragAndDropUploader
          text={title ?? 'Upload a Moss CSV export'}
          multiple={false}
          width={width}
          disabled={isLoading}
          onFileUpload={([file]) => {
            if (file) {
              setAvailableFile(file);
            }
          }}
        />
        {availableFile && (
          <Stack spacing={1}>
            <Chip
              label={availableFile.name}
              color="success"
              onDelete={() => setAvailableFile(undefined)}
            />
            <LoadingButton
              variant="contained"
              onClick={uploadAndParse}
              loading={isLoading}
            >
              Continue
            </LoadingButton>
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};
