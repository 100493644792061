import {
  AccountTable,
  ConfirmationModal,
  DownloadButton,
  FONTS,
  useSafeLog,
  wrapMutation,
  wrapQuery,
} from '@fresh-stack/frontend-commons';
import {
  inspectError,
  inspectSuccess,
  isRoleAllowed,
  isSuccess,
  mapSuccess,
  match,
  pipe,
} from '@fresh-stack/fullstack-commons';
import {
  ResAccountDto,
  UserDefinedAccountTypeDto,
} from '@fresh-stack/router/types';
import { Box, Button, Container, Grid, Stack, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUserContext } from '../../app/hooks/userContext';
import { ROUTES } from '../../app/routes';
import { trpc } from '../../utils';
import { EditAccountTypeModal } from './EditAccountTypeModal';

export const AllAccountsTab = () => {
  const safeLog = useSafeLog();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { user } = useUserContext();
  const [accountIdToDelete, setAccountIdToDelete] = React.useState<
    string | undefined
  >();

  const [accountToEdit, setAccountToEdit] = React.useState<
    ResAccountDto | undefined
  >();

  const allowEdits = isRoleAllowed({
    required: 'Admin',
    actual: user.userType,
  });

  const {
    result,
    isFetching: isLoadingAccounts,
    refetch: refetchAccounts,
  } = wrapQuery(
    trpc.banking.account.getBankAccounts.useQuery(undefined, {
      refetchOnWindowFocus: false,
    }),
  );

  const { result: resultAccountTypes } = wrapQuery(
    trpc.banking.accountAttribute.listAccountTypes.useQuery(undefined, {
      refetchOnWindowFocus: false,
    }),
  );

  const accountTypes = useMemo(
    () =>
      resultAccountTypes && isSuccess(resultAccountTypes)
        ? resultAccountTypes.right
        : [],
    [resultAccountTypes],
  );

  const accounts: ResAccountDto[] = useMemo(
    () =>
      result
        ? pipe(
            result,
            mapSuccess((res): ResAccountDto[] =>
              res.map((x) => ({
                ...x,
                balance: x.balance,
                isoCurrencyCode: x.isoCurrencyCode,
                lastSynced: x.lastSynced,
                userAccountTypeId: x.userAccountTypeId,
                userAccountTypeName: x.userAccountTypeName,
              })),
            ),
            match(
              (success) => success,
              (error) => {
                safeLog('error', error);
                enqueueSnackbar({
                  variant: 'error',
                  message: 'Failed to load accounts',
                });
                return [];
              },
            ),
          )
        : [],
    [result, safeLog, enqueueSnackbar],
  );

  const accountForRemoval = React.useMemo(() => {
    return (
      allowEdits && accounts.find((x) => x.accountId === accountIdToDelete)
    );
  }, [accountIdToDelete, accounts, allowEdits]);

  const { mutateAsync: markAccountForDeletion } = wrapMutation(
    trpc.banking.account.markAccountForDeletion.useMutation(),
  );

  const { mutateAsync: setAccountType } = wrapMutation(
    trpc.banking.account.attribute.setUserDefinedAccountType.useMutation(),
  );

  const { mutateAsync: clearAccountType } = wrapMutation(
    trpc.banking.account.attribute.clearAccountType.useMutation(),
  );

  const onUpdateAccountType = async (
    account: ResAccountDto,
    updateValue:
      | {
          readonly kind: 'update';
          readonly value: UserDefinedAccountTypeDto;
        }
      | { readonly kind: 'clear' },
  ) => {
    if (allowEdits) {
      setAccountToEdit(undefined);

      const editOperation = () => {
        if (updateValue.kind === 'update')
          return setAccountType({
            accountId: account.accountId,
            accountTypeId: updateValue.value.id,
          });
        else return clearAccountType(account.accountId);
      };

      pipe(
        await editOperation(),
        inspectSuccess(() => {
          enqueueSnackbar({
            variant: 'success',
            message: 'Operation completed successfully!',
          });
          refetchAccounts();
        }),
        inspectError(() => {
          enqueueSnackbar({
            variant: 'error',
            message: 'We had an issue updating the account, please try again!',
          });
          refetchAccounts();
        }),
      );
    }
  };

  const onConfirmRemoveAccount = async (accountForRemoval: ResAccountDto) => {
    if (allowEdits) {
      setAccountIdToDelete(undefined);

      pipe(
        await markAccountForDeletion({
          accountId: accountForRemoval.accountId,
        }),
        inspectSuccess(() => {
          enqueueSnackbar({
            variant: 'success',
            message: 'Operation completed successfully!',
          });
          refetchAccounts();
        }),
        inspectError(() => {
          enqueueSnackbar({
            variant: 'error',
            message:
              'We had an issue removing the connection, please try again later!',
          });
          refetchAccounts();
        }),
      );
    }
  };

  return (
    <Stack>
      <Box height={'85vh'} overflow={'auto'}>
        {accountForRemoval && (
          <ConfirmationModal
            question={`Are you sure you want to remove ${accountForRemoval.accountName} (** ${accountForRemoval.accountMask}) from ${accountForRemoval.institutionName} from your watch list?`}
            handleApprove={() => {
              onConfirmRemoveAccount(accountForRemoval);
            }}
            handleCancel={() => {
              setAccountIdToDelete(undefined);
            }}
          />
        )}
        {accountToEdit && (
          <EditAccountTypeModal
            accountTypes={accountTypes}
            currentAccountTypeId={accountToEdit.userAccountTypeId}
            handleApprove={(accountType) =>
              onUpdateAccountType(accountToEdit, accountType)
            }
            handleCancel={() => {
              setAccountToEdit(undefined);
            }}
          />
        )}
        <Container maxWidth={'xl'}>
          <Stack pl={1} pr={1} spacing={1}>
            <Grid container alignItems="flex-end" pb={1}>
              <Grid item xs={12} sm={4} container alignItems="center">
                <Typography variant="h6" fontFamily={FONTS.interBold}>
                  Bank accounts
                </Typography>
              </Grid>
              <Grid
                container
                item
                xs={12}
                sm={8}
                justifyContent="flex-end"
                alignItems={'flex-end'}
              >
                {allowEdits && (
                  <Button
                    size="small"
                    variant="contained"
                    onClick={() => navigate(ROUTES.Admin.SetupNewConnection)}
                    sx={{ width: 'fit-content', mr: 2 }}
                  >
                    Add more accounts
                  </Button>
                )}
                <DownloadButton
                  text="Download CSV"
                  size="small"
                  isLoading={false}
                  onClick={() => {
                    enqueueSnackbar({
                      message: 'CSV download coming soon!',
                    });
                  }}
                />
              </Grid>
            </Grid>
            <AccountTable
              isLoading={isLoadingAccounts}
              allowDeleteActions={!!allowEdits}
              allowAccountTypeEdit={!!allowEdits && !!accountTypes.length}
              accounts={accounts}
              onDelete={setAccountIdToDelete}
              onEditAccountType={setAccountToEdit}
            />
          </Stack>
        </Container>
      </Box>
    </Stack>
  );
};
