import { BasicUserDetails } from '@fresh-stack/router/types';
import { createContext, useContext } from 'react';
import { AppMenuItem } from '../routes';

export type UserContextData = {
  readonly user: BasicUserDetails;
  readonly menu: ReadonlyArray<AppMenuItem>;
};

export const UserContext = createContext<UserContextData | 'loading'>(
  'loading',
);

export const useUserContext = (): UserContextData => {
  const context = useContext(UserContext);

  if (!context) {
    throw new Error('useUserContext must be used inside the ProtectedRoute');
  } else if (context === 'loading') {
    // Given we are using withAuthenticationRequired, this should never happen
    throw new Error('User is loading');
  } else return context;
};
