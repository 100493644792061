import {
  DialogFadeTransition,
  wrapMutation,
} from '@fresh-stack/frontend-commons';
import { UserType, isSuccess } from '@fresh-stack/fullstack-commons';
import { ReqAddNewUserToCompany } from '@fresh-stack/router/types';
import { zodResolver } from '@hookform/resolvers/zod';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Dialog,
  Grid,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useSnackbar } from 'notistack';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import z from 'zod';
import { trpc } from '../../utils';

const SchemaAddNewUserToCompany = z.object({
  email: z.string().email(),
  firstName: z.string().min(1),
  lastName: z.string().min(1),
  role: z.union([z.literal('Admin'), z.literal('User')]),
});

export const AddNewUserDialog = ({
  open,
  onClose,
  onSuccess,
}: {
  readonly open: boolean;
  readonly onClose: () => void;
  readonly onSuccess: ({ email }: { readonly email: string }) => void;
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const {
    register,
    control,
    formState: { errors },
    handleSubmit,
    setValue,
    reset,
  } = useForm<ReqAddNewUserToCompany>({
    resolver: zodResolver(SchemaAddNewUserToCompany),
    defaultValues: {
      role: 'User',
    },
  });

  const { mutateAsync: saveUser, isLoading } = wrapMutation(
    trpc.organisation.addAndInviteUser.useMutation(),
  );

  const onSave = async (user: ReqAddNewUserToCompany) => {
    const result = await saveUser(user);
    if (isSuccess(result)) onSuccess({ email: user.email });
    else {
      switch (result.left.code) {
        case 'user-already-exists':
          enqueueSnackbar({
            variant: 'error',
            message: 'This user email is already on our platform.',
          });
          return;
        case 'invitation-failure':
          reset();
          enqueueSnackbar({
            variant: 'error',
            message:
              'We have created the user but there was an issue sending them an invite. We will retry later.',
          });
          return;
        default:
          enqueueSnackbar({
            variant: 'error',
            message:
              'We ran into an issue with your request, please try again!',
          });
          return;
      }
    }
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={DialogFadeTransition}
      onClose={onClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle variant="h5" fontWeight={500}>
        Add a new user
      </DialogTitle>
      <form onSubmit={handleSubmit(onSave)}>
        <DialogContent>
          <Typography variant="subtitle1">
            Invite your team members to join you on Ribon!
          </Typography>

          <Stack spacing={3} mt={3}>
            <Controller
              name="role"
              control={control}
              render={({ field }) => {
                return (
                  <ToggleButtonGroup
                    size="small"
                    {...field}
                    color="primary"
                    onChange={(
                      _event: React.MouseEvent<HTMLElement>,
                      value: UserType | undefined,
                    ) => {
                      if (value) setValue(field.name, value);
                    }}
                    exclusive
                    aria-label="text alignment"
                  >
                    <ToggleButton value="User">User</ToggleButton>
                    <ToggleButton value="Admin">Admin</ToggleButton>
                  </ToggleButtonGroup>
                );
              }}
            />

            <Grid item container xs={12}>
              <Grid container item xs={6} pr={1}>
                <TextField
                  id="firstName"
                  label="First name"
                  placeholder="Jane"
                  {...register('firstName')}
                  color="primary"
                  fullWidth
                  error={!!errors.firstName}
                  helperText={
                    errors.firstName ? (
                      <Box>{errors.firstName.message}</Box>
                    ) : null
                  }
                ></TextField>
              </Grid>
              <Grid container item xs={6} pl={1}>
                <TextField
                  id="lastName"
                  label="Last name"
                  placeholder="Smith"
                  {...register('lastName')}
                  color="primary"
                  fullWidth
                  error={!!errors.lastName}
                  helperText={
                    errors.lastName ? (
                      <Box>{errors.lastName.message}</Box>
                    ) : null
                  }
                ></TextField>
              </Grid>
            </Grid>

            <Grid container item xs={12}>
              <TextField
                id="email"
                label="Email"
                placeholder="jane.smith@example.com"
                {...register('email')}
                color="primary"
                fullWidth
                error={!!errors.email}
                helperText={
                  errors.email ? <Box>{errors.email.message}</Box> : null
                }
              ></TextField>
            </Grid>
          </Stack>
        </DialogContent>
        <DialogActions sx={{ padding: 4 }}>
          <LoadingButton
            type="submit"
            variant="contained"
            color="success"
            loading={isLoading}
          >
            Add & invite
          </LoadingButton>
          <Button onClick={onClose} variant="contained" color="error">
            Cancel
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
