import { appColors } from '@fresh-stack/frontend-commons';
import { Box, CssBaseline, Stack } from '@mui/material';
import { ScrollRestoration } from 'react-router-dom';
import { Logo } from '../../components/Logo';
import { Footer } from './Footer';
import { SignupForm } from './SignupForm';

export const SignupPage = () => {
  return (
    <div>
      <CssBaseline />
      <div id="back-to-top-anchor" />

      <Box
        display="flex"
        flexDirection={'column'}
        bgcolor={appColors.secondary}
        minHeight="100vh"
        component="main"
        width={'100%'}
      >
        <Stack
          sx={{
            width: '100%',
            flexGrow: 1, // Allows this box to grow and fill available space, pushing the footer to the bottom
          }}
          alignItems={'center'}
        >
          <Stack maxWidth={1200} pt={4} alignItems={'center'} spacing={5}>
            <Logo variant="h3" color={'primary'} />
            <SignupForm />
          </Stack>
        </Stack>

        <Footer />

        <ScrollRestoration />
      </Box>
    </div>
  );
};
