import { DialogFadeTransition, appColors } from '@fresh-stack/frontend-commons';
import { countBy } from '@fresh-stack/fullstack-commons';
import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd';
import { zodResolver } from '@hookform/resolvers/zod';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import InfoIcon from '@mui/icons-material/Info';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  CircularProgress,
  Dialog,
  Grid,
  IconButton,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Tooltip from '@mui/material/Tooltip';
import { useMemo } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import z from 'zod';

const buildFormSchema = (existingAttributeNames: readonly string[]) =>
  z.object({
    name: z
      .string()
      .min(1, { message: 'Custom field name must not be empty' })
      .refine(
        (name) =>
          !existingAttributeNames
            .map((c) => c.toLowerCase().trim())
            .includes(name.toLowerCase().trim()),
        {
          message: 'Custom field already exists',
        },
      ),
    options: z
      .array(
        // Wrap in an object in order to support useFieldArray
        z.object({
          name: z.string().min(1, { message: 'Option must not be empty' }),
          // Not intended to be updated by the form
          // Will be preserved for options which are set in initialValues
          databaseId: z.string().uuid().optional(),
        }),
      )
      .min(1, { message: 'Custom field must have at least one option' })
      // Set errors on the fields of duplicate options
      .superRefine((options, ctx) => {
        const lowerCaseOptions = options.map((o) =>
          o.name.toLowerCase().trim(),
        );
        const optionCounts = countBy(lowerCaseOptions);
        const duplicateIndexes = lowerCaseOptions.reduce(
          (result, value, index) => {
            if (optionCounts[value] > 1) result.push(index);
            return result;
          },
          [] as number[],
        );

        if (duplicateIndexes.length > 0) {
          for (const index of duplicateIndexes) {
            ctx.addIssue({
              path: [index, 'name'],
              message: 'Option names must be unique',
              code: z.ZodIssueCode.custom,
            });
          }
        }
      }),
  });

export type AttributeFormSchemaType = z.infer<
  ReturnType<typeof buildFormSchema>
>;

export const AttributeDialog = ({
  open,
  onClose,
  onSave,
  otherAttributeNames,
  dialogTitleText,
  submitButtonText,
  initialValues,
  isSaving,
  initialValuesLoading,
}: {
  readonly open: boolean;
  readonly onClose: () => void;
  readonly onSave: (formValues: AttributeFormSchemaType) => void;
  readonly otherAttributeNames: readonly string[];
  readonly dialogTitleText: string;
  readonly submitButtonText: string;
  readonly initialValues?: AttributeFormSchemaType;
  readonly isSaving: boolean;
  readonly initialValuesLoading: boolean;
}) => {
  const formSchema = useMemo(
    () => buildFormSchema(otherAttributeNames),
    [otherAttributeNames],
  );

  const {
    control,
    formState: { errors },
    handleSubmit,
    trigger,
  } = useForm<AttributeFormSchemaType>({
    resolver: zodResolver(formSchema),
    defaultValues: initialValues,
  });

  const { fields, append, remove, move } = useFieldArray({
    control,
    name: 'options',
  });

  return (
    <Dialog
      open={open}
      TransitionComponent={DialogFadeTransition}
      onClose={onClose}
      sx={{ '& .MuiDialog-paper': { minWidth: '500px', maxWidth: '600px' } }}
    >
      {initialValuesLoading ? (
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          padding={5}
        >
          <CircularProgress />
        </Stack>
      ) : (
        <>
          <DialogTitle variant="h5">{dialogTitleText}</DialogTitle>
          <form onSubmit={handleSubmit(onSave)}>
            <DialogContent>
              <Stack spacing={1}>
                {/** Using {...register('name')} led to placeholder text and the default value being
                 * displayed at the same time. Using the Controller component fixed this issue. */}
                <Controller
                  name={'name' as const}
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      id="name"
                      size="small"
                      label="Custom field name"
                      placeholder="Spend category"
                      color="primary"
                      error={!!errors.name}
                      helperText={errors.name ? errors.name.message : null}
                    />
                  )}
                />
                <Stack direction="row" alignItems="center" pt={2}>
                  <Typography variant="h6">Options</Typography>
                  <Tooltip title="The possible values for your custom field.">
                    <InfoIcon color="action" />
                  </Tooltip>
                </Stack>
                <Stack>
                  <DragDropContext
                    onDragEnd={(result) => {
                      if (result.destination)
                        move(result.source.index, result.destination.index);
                    }}
                  >
                    <Droppable droppableId="droppable">
                      {(provided, snapshot) => (
                        <Stack
                          maxHeight={'40vh'}
                          overflow={'auto'}
                          padding={2}
                          boxShadow={1}
                          pt={0}
                        >
                          <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            style={{
                              background: snapshot.isDraggingOver
                                ? appColors.secondaryLighter
                                : undefined,
                            }}
                          >
                            {fields.map((field, index) => (
                              <Draggable
                                key={field.id}
                                draggableId={field.id}
                                index={index}
                              >
                                {(provided) => (
                                  <Grid
                                    container
                                    key={field.id}
                                    alignItems="center"
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    style={{
                                      userSelect: 'none',
                                      ...provided.draggableProps.style,
                                    }}
                                  >
                                    <Grid item xs={1}>
                                      <IconButton
                                        aria-label="reorder"
                                        size="small"
                                        disabled={fields.length === 1}
                                        {...provided.dragHandleProps}
                                      >
                                        <DragIndicatorIcon fontSize="small" />
                                      </IconButton>
                                    </Grid>
                                    <Grid item xs={10}>
                                      <Controller
                                        name={`options.${index}.name` as const}
                                        control={control}
                                        defaultValue={''}
                                        render={({ field }) => (
                                          <TextField
                                            {...field}
                                            fullWidth
                                            variant="outlined"
                                            size="small"
                                            margin="normal"
                                            error={
                                              !!errors.options?.[index]?.name
                                            }
                                            helperText={
                                              errors.options?.[index]?.name
                                                ? errors?.options?.[index]?.name
                                                    ?.message
                                                : null
                                            }
                                            onChange={(e) => {
                                              field.onChange(e);
                                              // Re-validate for duplicate option errors
                                              trigger('options');
                                            }}
                                          />
                                        )}
                                      />
                                    </Grid>
                                    <Grid item xs={1}>
                                      <Tooltip
                                        title={
                                          fields.length === 1
                                            ? 'At least one option is required.'
                                            : ''
                                        }
                                      >
                                        <span>
                                          <IconButton
                                            onClick={() => remove(index)}
                                            aria-label="delete"
                                            color="error"
                                            size="small"
                                            disabled={fields.length === 1}
                                          >
                                            <DeleteIcon fontSize="small" />
                                          </IconButton>
                                        </span>
                                      </Tooltip>
                                    </Grid>
                                  </Grid>
                                )}
                              </Draggable>
                            ))}
                            {provided.placeholder}
                          </div>
                        </Stack>
                      )}
                    </Droppable>
                  </DragDropContext>
                  <Button
                    type="button"
                    onClick={() => append({ name: '' })}
                    variant="outlined"
                    startIcon={<AddIcon />}
                    size="small"
                    sx={{ width: 'fit-content', alignSelf: 'center', mt: 2 }}
                  >
                    Add option
                  </Button>
                </Stack>
              </Stack>
            </DialogContent>
            <DialogActions sx={{ padding: 2 }}>
              <LoadingButton
                type="submit"
                variant="contained"
                color="success"
                loading={isSaving}
                size="small"
              >
                {submitButtonText}
              </LoadingButton>
              <Button
                onClick={onClose}
                variant="contained"
                color="error"
                size="small"
              >
                Cancel
              </Button>
            </DialogActions>
          </form>
        </>
      )}
    </Dialog>
  );
};
