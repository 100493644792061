import { FONTS, useSafeLog, wrapMutation } from '@fresh-stack/frontend-commons';
import {
  SupportedCountryAlpha2Code,
  isError,
} from '@fresh-stack/fullstack-commons';
import {
  Autocomplete,
  Backdrop,
  Button,
  CircularProgress,
  Container,
  InputLabel,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  PlaidLinkError,
  PlaidLinkOnExitMetadata,
  PlaidLinkOnSuccessMetadata,
  PlaidLinkOptions,
  usePlaidLink,
} from 'react-plaid-link';
import { trpc } from '../../utils';

import { useSnackbar } from 'notistack';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../app/routes';
import { FRONTEND_SUPPORTED_COUNTRIES } from '../../utils/language';

export const SetupNewConnectionPage = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const enablePlaidUs = useFeatureFlagEnabled('enablePlaidUs');

  const countryList = useMemo(() => {
    if (enablePlaidUs) return FRONTEND_SUPPORTED_COUNTRIES;
    else return FRONTEND_SUPPORTED_COUNTRIES.filter((x) => x.id !== 'US');
  }, [enablePlaidUs]);

  const [loading, setLoading] = React.useState<
    undefined | 'triggering' | 'saving' | 'done'
  >();
  const [selectedCountry, setSelectedCountry] = React.useState<
    SupportedCountryAlpha2Code | undefined
  >(undefined);
  const safeLog = useSafeLog();
  const [linkToken, setToken] = useState<string | null>(null);

  const { mutateAsync: createUiLinkToken } = wrapMutation(
    trpc.banking.generateUiLinkToken.useMutation(),
  );

  const { mutateAsync: saveBankConnection } = wrapMutation(
    trpc.banking.storeConnectionResult.useMutation(),
  );

  const triggerPlaidLink = async () => {
    if (selectedCountry) {
      setLoading('triggering');
      const token = await createUiLinkToken({
        kind: 'new',
        country: selectedCountry,
      });

      if (isError(token)) {
        setLoading(undefined);
        enqueueSnackbar({
          variant: 'error',
          message: 'We had an issue triggering the flow. Please try again!',
        });
      } else {
        setToken(token.right.link_token);
      }
    }
  };

  const onSuccess = useCallback(
    async (publicToken: string, metadata: PlaidLinkOnSuccessMetadata) => {
      if (selectedCountry && metadata.institution && metadata.accounts.length) {
        setLoading('saving');
        enqueueSnackbar({
          variant: 'success',
          message: 'Saving your new accounts.',
        });
        const saveResult = await saveBankConnection({
          temporaryPublicToken: publicToken,
          country: selectedCountry,
          institution: {
            name: metadata.institution.name,
            externalInstitutionId: metadata.institution.institution_id,
          },
        });
        if (isError(saveResult)) {
          setLoading(undefined);
          enqueueSnackbar({
            variant: 'error',
            message:
              'There was an issue trying to save your new accounts. Please try again!',
          });
          safeLog('----------------- error saving connection');
        } else {
          enqueueSnackbar({
            variant: 'success',
            message:
              'Your new accounts have been added successfully. Your associated transactions should be available shortly!',
          });
          setLoading('done');
        }
      } else {
        setLoading(undefined);
        enqueueSnackbar({
          variant: 'warning',
          message: 'We need at least one selected account to go forward!',
        });
        //error we need at least one account from the selected bank
        safeLog('----------------- bad result', metadata);
      }
    },
    [enqueueSnackbar, safeLog, saveBankConnection, selectedCountry],
  );

  const onExit = useCallback(
    async (error: null | PlaidLinkError, metadata: PlaidLinkOnExitMetadata) => {
      setLoading(undefined);
      if (error) {
        safeLog('----------------- error on plaid process', error, metadata);
      } else {
        safeLog('----------------- interrupted process', metadata);
      }
      enqueueSnackbar({
        variant: 'warning',
        message: 'Connection setup interrupted.',
      });
    },
    [enqueueSnackbar, safeLog],
  );

  const config: PlaidLinkOptions = {
    // token must be the same token used for the first initialization of Link
    token: linkToken,
    onSuccess,
    onExit,
  };

  const { open: openPlaidPopup, ready: isReadyToTriggerPopup } =
    usePlaidLink(config);

  React.useEffect(() => {
    if (isReadyToTriggerPopup) {
      openPlaidPopup();
    }
  }, [openPlaidPopup, isReadyToTriggerPopup]);

  useEffect(() => {
    if (loading === 'done') {
      const timer = setTimeout(() => navigate(ROUTES.Commons.Accounts), 5000);

      // Cleanup the timer on unmount
      return () => {
        if (timer) clearTimeout(timer);
      };
    }
  }, [loading, navigate]);

  return (
    <Container>
      <Backdrop
        sx={{ color: 'white', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={!!loading}
      >
        {loading === 'done' || loading === 'saving' ? (
          <CircularProgress color="secondary" />
        ) : loading === 'triggering' ? null : null}
      </Backdrop>

      <Stack
        height={'90vh'}
        justifyContent={'center'}
        alignItems={'center'}
        pb={3}
        pt={3}
      >
        <Typography
          variant="h4"
          fontFamily={FONTS.montserratBold}
          textAlign={'center'}
          gutterBottom
        >
          Add new bank accounts
        </Typography>
        <Stack maxWidth={600} alignItems={'center'} spacing={2}>
          <InputLabel htmlFor="select-country">
            What country do you want to add new accounts for?
          </InputLabel>

          <Autocomplete
            disablePortal
            id="select-country"
            fullWidth
            size="small"
            onChange={(_, value) => setSelectedCountry(value?.id)}
            options={countryList}
            placeholder="Country"
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Select a country to continue"
                fullWidth
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'new-password', // disable autocomplete and autofill
                }}
              />
            )}
          />
          <Button
            disabled={!selectedCountry}
            color="success"
            sx={{ width: 'fit-content' }}
            onClick={triggerPlaidLink}
            variant="contained"
          >
            Continue
          </Button>
        </Stack>
      </Stack>
    </Container>
  );
};
