import { ALL } from '@fresh-stack/fullstack-commons';
import {
  Autocomplete,
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import { AccountSummary } from '../../types';
import { FilterDatePicker } from '../FilterDatePicker';

export const TRANSACTION_TYPES = [ALL, 'Inflows', 'Outflows'] as const;

export type TransactionType = (typeof TRANSACTION_TYPES)[number];
export const isTransactionType = (
  input: string | undefined,
): input is TransactionType => {
  return input ? TRANSACTION_TYPES.includes(input as TransactionType) : false;
};

export const transactionTypeToIsDeposit = (
  transactionType: TransactionType | 'Deposits' | 'Withdrawals',
): boolean | undefined => {
  switch (transactionType) {
    case 'Inflows':
    case 'Deposits':
      return true;
    case 'Outflows':
    case 'Withdrawals':
      return false;
    case ALL:
      return undefined;
  }
};

export const isDepositToTransactionType = (
  isDeposit: boolean | undefined,
): TransactionType => {
  if (isDeposit === true) return 'Inflows';
  if (isDeposit === false) return 'Outflows';
  return 'All';
};

export const TransactionFilterWidget = ({
  txNameContains,
  onTxNameContainsChange,
  currency,
  onCurrencyChange,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  transactionType,
  onTransactionTypeChange,
  selectedAccountIds,
  onSelectedAccountIdsChange,
  availableCurrencies,
  availableAccounts,
  disabled = false,
  showDefaultFields = true,
}: {
  readonly txNameContains: string | undefined;
  readonly onTxNameContainsChange: (newValue: string | undefined) => void;
  readonly currency: string;
  readonly onCurrencyChange: (newValue: string) => void;
  readonly startDate: Date | undefined;
  readonly setStartDate: (newValue: Date | undefined) => void;
  readonly endDate: Date | undefined;
  readonly setEndDate: (newValue: Date | undefined) => void;
  readonly transactionType: TransactionType;
  readonly onTransactionTypeChange: (newValue: TransactionType) => void;
  readonly selectedAccountIds: string[];
  readonly onSelectedAccountIdsChange: (newValue: string[]) => void;
  readonly availableCurrencies: string[];
  readonly availableAccounts: AccountSummary[];
  readonly disabled?: boolean;
  readonly showDefaultFields?: boolean;
}) => {
  return (
    <Grid
      container
      item
      justifyContent={'flex-start'}
      alignItems={'center'}
      gap={1}
      xs={12}
    >
      {(showDefaultFields || txNameContains) && (
        <TextField
          id="txt-search"
          label="Description contains"
          variant="outlined"
          size="small"
          sx={{ width: 200 }}
          value={txNameContains}
          onChange={(evt) => onTxNameContainsChange(evt.target.value)}
          disabled={disabled}
        />
      )}
      {(showDefaultFields || selectedAccountIds.length > 0) && (
        <Autocomplete
          multiple
          id="autocomplete-accounts"
          options={availableAccounts}
          getOptionLabel={(option) => option.name}
          sx={{ minWidth: 120, maxWidth: 300 }}
          disabled={disabled}
          value={availableAccounts.filter((x) =>
            selectedAccountIds.includes(x.accountId),
          )}
          onChange={(_event, newValue) => {
            onSelectedAccountIdsChange(newValue.map((x) => x.accountId));
          }}
          size="small"
          renderInput={(params) => (
            <TextField
              {...params}
              label="Accounts"
              variant="outlined"
              size="small"
              sx={{ minWidth: 120 }}
            />
          )}
          renderOption={(props, option) => (
            <MenuItem {...props} key={option.accountId}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  width: '100%',
                }}
              >
                <Typography
                  sx={{
                    mr: 1,
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                  noWrap
                >
                  {option.name}
                </Typography>
                <Typography fontFamily="monospace">
                  {option.mask ? `**** ${option.mask}` : ''}
                </Typography>
              </Box>
            </MenuItem>
          )}
          slotProps={{
            paper: {
              style: { width: 'auto', minWidth: '300px' },
            },
          }}
        />
      )}
      {(showDefaultFields || currency !== 'All') && (
        <FormControl sx={{ width: 80 }} disabled={disabled}>
          <InputLabel id="select-currency-label" size="small">
            Currency
          </InputLabel>
          <Select
            labelId="select-currency-label"
            id="select-currency"
            value={currency}
            fullWidth
            size="small"
            label="Currency"
            onChange={(change) => {
              if (change.target.value)
                onCurrencyChange(change.target.value.toString());
            }}
          >
            {availableCurrencies.map((x) => (
              <MenuItem key={'mi-cry-' + x} value={x}>
                {x}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
      {(showDefaultFields || transactionType !== 'All') && (
        <ToggleButtonGroup
          size="small"
          color="success"
          value={transactionType}
          disabled={disabled}
          exclusive
          onChange={(_, newValue) => {
            if (newValue && isTransactionType(newValue))
              onTransactionTypeChange(newValue);
          }}
        >
          {TRANSACTION_TYPES.map((x) => (
            <ToggleButton key={'tb-type-' + x} value={x} size="small">
              {x}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      )}
      <Stack
        direction="row"
        alignItems={'center'}
        sx={{ flexDirection: 'row', flexWrap: 'wrap', gap: 1 }}
      >
        {(showDefaultFields || startDate) && (
          <FilterDatePicker
            value={startDate}
            setValue={setStartDate}
            label="Start date"
            disabled={disabled}
          />
        )}
        {(showDefaultFields || endDate) && (
          <FilterDatePicker
            value={endDate}
            setValue={setEndDate}
            label="End date"
            disabled={disabled}
          />
        )}
      </Stack>
    </Grid>
  );
};
