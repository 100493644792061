import {
  Error404,
  ErrorGeneric,
  Logout,
  RedirectWithQuery,
} from '@fresh-stack/frontend-commons';
import {
  Navigate,
  Outlet,
  RouteObject,
  createBrowserRouter,
} from 'react-router-dom';
import { ORG_SETTINGS_BASE_PATH, ROUTES, SETTINGS_BASE_PATH } from '.';
import { BaseLayout } from '../../layout';
import { PrivacyPage } from '../../pages';
import { SettingsPage } from '../../pages/Account/SettingsPage';
import { ImportsPage } from '../../pages/Imports/ImportsPage';
import { MossUploadPage } from '../../pages/Imports/MossUploadPage';
import { AccountTabs } from '../../pages/OpenBanking/AccountTabs';
import { SetupNewConnectionPage } from '../../pages/OpenBanking/SetupNewConnectionPage';
import { TransactionTabs } from '../../pages/OpenBanking/TransactionTabs';
import { ChartDashboardPage } from '../../pages/Reporting/ChartDashboardPage';
import { SignupPage } from '../../pages/Signup/SignupPage';
import ProtectedRoute from './ProtectedRoute';

const ROUTES_COMMON = [
  {
    path: '/',
    element: <RedirectWithQuery path={ROUTES.Commons.Home} />,
  },
];

const ROUTES_NONADMIN: RouteObject[] = [
  {
    path: ROUTES.Commons.Home,
    element: <ChartDashboardPage />,
    index: true,
  },
  {
    path: ROUTES.Commons.Accounts,
    element: <AccountTabs />,
    index: true,
  },
  {
    path: ROUTES.Commons.AccountTypes,
    element: <AccountTabs />,
    index: true,
  },
  {
    path: ROUTES.Commons.Transactions,
    element: <TransactionTabs />,
    index: true,
  },
  {
    path: ROUTES.Settings.Organisation.Attributes,
    element: <TransactionTabs />,
    index: true,
  },
  {
    path: ROUTES.Commons.TransactionRules,
    element: <TransactionTabs />,
    index: true,
  },
  {
    path: SETTINGS_BASE_PATH,
    element: <Navigate to={ORG_SETTINGS_BASE_PATH} />,
    index: true,
  },
  {
    path: ORG_SETTINGS_BASE_PATH,
    element: <Navigate to={ROUTES.Settings.Organisation.Users} />,
    index: true,
  },
  {
    path: ROUTES.Settings.Organisation.Users,
    element: <SettingsPage />,
    index: true,
  },
  {
    path: ROUTES.Settings.Organisation.Users,
    element: <SettingsPage />,
    index: true,
  },
  {
    path: ROUTES.Settings.User,
    element: <SettingsPage />,
    index: true,
  },
  {
    path: ROUTES.Admin.Imports,
    element: <ImportsPage />,
    index: true,
  },
  {
    path: ROUTES.Admin.UploadedMossFile,
    element: <MossUploadPage />,
    index: true,
  },
];

const ROUTES_ADMIN: RouteObject[] = [
  {
    path: ROUTES.Admin.SetupNewConnection,
    element: <SetupNewConnectionPage />,
    index: true,
  },
];

export const buildAppRouter = () =>
  createBrowserRouter([
    {
      element: <BaseLayout />,
      errorElement: (
        <BaseLayout isErrorPage>
          <ErrorGeneric redirectPath="/" />
        </BaseLayout>
      ),
      children: [
        {
          element: <ProtectedRoute allowedUserTypes={['Admin', 'User']} />,
          children: [...ROUTES_COMMON, ...ROUTES_NONADMIN],
        },
        {
          element: <ProtectedRoute allowedUserTypes={['Admin']} />,
          children: ROUTES_ADMIN,
        },
      ],
    },
    {
      element: (
        <BaseLayout>
          <Outlet />
        </BaseLayout>
      ),
      children: [
        {
          path: ROUTES.Public.Privacy,
          element: <PrivacyPage />,
        },
      ],
    },
    {
      element: <BaseLayout />,
      errorElement: (
        <BaseLayout isErrorPage>
          <ErrorGeneric redirectPath={ROUTES.Public.Signup} />
        </BaseLayout>
      ),
      children: [
        {
          path: ROUTES.Public.Signup,
          element: <SignupPage />,
        },
      ],
    },
    {
      path: ROUTES.Commons.Logout,
      element: <Logout />,
    },
    {
      path: '*',
      element: <Error404 />,
    },
  ]);
