import { wrapQuery } from '@fresh-stack/frontend-commons';
import { isError, sumBy } from '@fresh-stack/fullstack-commons';
import { Grid } from '@mui/material';
import { format } from 'date-fns';
import { useMemo } from 'react';
import { trpc } from '../../utils';
import { AmountHeroCard } from './AmountHeroCard';

export const DashboardSectionHeroCards = ({
  selectedMonth,
  selectedCurrencyCode,
  accounts,
}: {
  readonly selectedMonth: Date;
  readonly selectedCurrencyCode: string;
  readonly accounts: {
    readonly availableBalance: number | undefined;
    readonly id: string;
  }[];
}) => {
  const currentBalance = sumBy(accounts, (x) =>
    x.availableBalance ? +x.availableBalance : 0,
  );

  const { result: maybeCurrencyAmounts, isLoading: isLoadingTotals } =
    wrapQuery(
      trpc.reports.getMonthAggregatedAmountsForCurrency.useQuery(
        {
          currency: selectedCurrencyCode,
          month: selectedMonth.toISOString(),
        },
        {
          refetchOnWindowFocus: false,
          enabled: !!selectedCurrencyCode,
        },
      ),
    );

  const values = useMemo(() => {
    if (!maybeCurrencyAmounts || isLoadingTotals) return undefined;
    else if (isError(maybeCurrencyAmounts))
      throw new Error(); // we are on the home page, show error screen to reload
    else {
      return maybeCurrencyAmounts.right;
    }
  }, [isLoadingTotals, maybeCurrencyAmounts]);

  const niceMonth = format(selectedMonth, 'MMM yyyy');

  return (
    <Grid container item xs={12} pl={1}>
      <Grid item xs={12} sm={6} md={6} lg={3} mt={1} pr={1}>
        <AmountHeroCard
          title="Current balance"
          tooltip={`Total cash position for ${selectedCurrencyCode} accounts`}
          amount={currentBalance}
          currency={selectedCurrencyCode}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={6} lg={3} mt={1} pr={1}>
        <AmountHeroCard
          title={`Incoming (${niceMonth})`}
          tooltip={`Total amount of incoming funds for ${selectedCurrencyCode} accounts in ${format(selectedMonth, 'MMM yyyy')}`}
          amount={values?.totalDeposit ?? 0}
          currency={selectedCurrencyCode}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={6} lg={3} mt={1} pr={1}>
        <AmountHeroCard
          title={`Outgoing (${niceMonth})`}
          tooltip={`Total amount of outgoing funds for ${selectedCurrencyCode} accounts in ${format(selectedMonth, 'MMM yyyy')}`}
          amount={values?.totalWithdrawal ?? 0}
          currency={selectedCurrencyCode}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={6} lg={3} mt={1} pr={1}>
        <AmountHeroCard
          title={`Net cash flow ratio (${niceMonth})`}
          tooltip={`Ratio of total inflows to outflows in ${niceMonth}. A ratio > 1 indicates efficient cash management.`}
          amount={
            values?.totalWithdrawal
              ? (values?.totalDeposit ?? 0) / values.totalWithdrawal
              : undefined
          }
          currency={undefined}
        />
      </Grid>
    </Grid>
  );
};
