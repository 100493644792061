import {
  Result,
  buildError,
  buildSuccess,
  isSuccess,
} from '../lib/action-result';

/* eslint-disable functional/prefer-readonly-type */
export type DateOrStringOrNumber = Date | string | number;

export const strictParseDateOrStringOrNumber = (
  value: DateOrStringOrNumber,
): Result<Date, 'parse-error'> => {
  if (value instanceof Date) {
    // in case an invalid date object was passed in
    if (Number.isNaN(value.getTime()))
      return buildError(
        'parse-error',
        `Invalid date: ${JSON.stringify(value)}`,
      );
    else return buildSuccess(value);
  }

  const parsed = new Date(value);
  if (Number.isNaN(parsed.getTime())) {
    return buildError('parse-error', {
      message: `Failed to parse ${value} as date`,
    });
  } else {
    return buildSuccess(parsed);
  }
};

export const parseDateOrStringOrNumber = (
  value: DateOrStringOrNumber,
): Date | undefined => {
  const result = strictParseDateOrStringOrNumber(value);
  return isSuccess(result) ? result.right : undefined;
};

export type WithNonNullableField<T, K extends keyof T> = T & {
  [P in K]-?: NonNullable<T[P]>;
};

/** Recursively make all readonly fields mutable */
export type DeepMutable<T> = {
  -readonly [P in keyof T]: T[P] extends ReadonlyArray<infer U>
    ? Array<DeepMutable<U>>
    : T[P] extends object
      ? DeepMutable<T[P]>
      : T[P];
};

export const formatBytes = (bytes: number, decimals = 2) => {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + '' + sizes[i];
};
