import { NavigationTabs, TabProps } from '@fresh-stack/frontend-commons';
import { ROUTES } from '../../app/routes';
import { OrganisationUsersTab } from './OrganisationUsersTab';
import { UserSettingsTab } from './UserSettingsTab';
import { NavBarAvatar } from '../../components/navbar/NavBarAvatar';

const tabs: TabProps[] = [
  {
    name: 'User management',
    path: ROUTES.Settings.Organisation.Users,
    component: OrganisationUsersTab,
  },
  {
    name: 'My profile',
    path: ROUTES.Settings.User,
    component: UserSettingsTab,
  },
];

export const SettingsPage = () => (
  <NavigationTabs tabs={tabs} endAdornment={<NavBarAvatar />} />
);
