import { FONTS, appColors, wrapMutation } from '@fresh-stack/frontend-commons';
import { isSuccess } from '@fresh-stack/fullstack-commons';
import { zodResolver } from '@hookform/resolvers/zod';
import { LoadingButton } from '@mui/lab';
import { Box, Grid, Stack, TextField, Typography } from '@mui/material';
import React from 'react';
import { useForm } from 'react-hook-form';
import z from 'zod';
import { trpc } from '../../utils';
import { useSnackbar } from 'notistack';

const SchemaAddNewUserToCompany = z.object({
  email: z.string().email(),
  firstName: z.string().refine(
    (value) => {
      return !!value.trim().length;
    },
    {
      message: 'Please provide your first name.',
    },
  ),
  lastName: z.string().refine(
    (value) => {
      return !!value.trim().length;
    },
    {
      message: 'Please provide your last name.',
    },
  ),
  companyName: z.string().refine(
    (value) => {
      return !!value.trim().length;
    },
    {
      message: 'Please provide your company name.',
    },
  ),
});

type FormPayload = z.infer<typeof SchemaAddNewUserToCompany>;

export const SignupForm = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [message, setMessage] = React.useState<string | undefined>();
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<FormPayload>({
    resolver: zodResolver(SchemaAddNewUserToCompany),
  });

  const {
    mutate: submitSignup,
    isLoading,
    result,
  } = wrapMutation(trpc.organisation.processSignup.useMutation());

  React.useEffect(() => {
    if (!isLoading && result) {
      if (isSuccess(result)) {
        switch (result.right.status) {
          case 'invite-sent':
            setMessage(
              'Thank you for registering with us! We have sent you an invitation email, check your spam folder as well!',
            );
            break;
          case 'not-approved':
            setMessage(
              'Thank you for registering, you have been added to our waitlist and we will reach out to you whenever a spot opens up!',
            );
            break;
        }
      } else {
        switch (result.left.code) {
          case 'invite-failure':
            setMessage(
              'Thank you for registering with us! We will send you an invitation email as soon as we review your details!',
            ); // in this case we should manually send them an invite, TODO add alert for it
            break;
          case 'existing-user':
            setMessage(
              'This user email has already been registered in our system.',
            );
            break;
          case 'bad-email':
          case 'db-failure':
          case 'io-failed':
          case 'unknown':
            enqueueSnackbar({
              variant: 'error',
              message:
                'There was an issue processing your signup, please try again.',
            });
            break;
        }
      }
    }
  }, [result, isLoading, enqueueSnackbar]);

  return (
    <Stack width="100%" maxWidth={1200} minWidth={600} alignItems={'center'}>
      {message ? (
        <Box maxWidth={600}>
          <Typography
            textAlign={'center'}
            variant="h5"
            fontFamily={FONTS.latoBold}
          >
            {message}
          </Typography>
        </Box>
      ) : (
        <form onSubmit={handleSubmit((input) => submitSignup(input))}>
          <Stack
            spacing={2}
            borderRadius={2}
            alignItems={'center'}
            bgcolor={appColors.white}
            padding={4}
          >
            <Grid item container xs={12}>
              <Grid container item xs={6} pr={1}>
                <TextField
                  id="firstName"
                  label="First name"
                  placeholder="Jane"
                  {...register('firstName')}
                  color="primary"
                  fullWidth
                  disabled={isLoading}
                  error={!!errors.firstName}
                  helperText={
                    errors.firstName ? (
                      <Box>{errors.firstName.message}</Box>
                    ) : null
                  }
                ></TextField>
              </Grid>
              <Grid container item xs={6} pl={1}>
                <TextField
                  id="lastName"
                  label="Last name"
                  placeholder="Smith"
                  {...register('lastName')}
                  color="primary"
                  fullWidth
                  disabled={isLoading}
                  error={!!errors.lastName}
                  helperText={
                    errors.lastName ? (
                      <Box>{errors.lastName.message}</Box>
                    ) : null
                  }
                ></TextField>
              </Grid>
            </Grid>
            <Grid item container xs={12}>
              <TextField
                id="email"
                label="Email"
                placeholder="jane@smith.com"
                {...register('email')}
                color="primary"
                fullWidth
                disabled={isLoading}
                error={!!errors.email}
                helperText={
                  errors.email ? <Box>{errors.email.message}</Box> : null
                }
              ></TextField>
            </Grid>
            <Grid item container xs={12}>
              <TextField
                id="companyName"
                label="Company name"
                placeholder="Acme Co."
                {...register('companyName')}
                color="primary"
                fullWidth
                disabled={isLoading}
                error={!!errors.companyName}
                helperText={
                  errors.companyName ? (
                    <Box>{errors.companyName.message}</Box>
                  ) : null
                }
              ></TextField>
            </Grid>
            <LoadingButton
              color="primary"
              type="submit"
              variant="contained"
              sx={{ width: 'fit-content' }}
              loading={isLoading}
            >
              Get early access
            </LoadingButton>
            <Typography variant="caption">
              If you need any help, please contact{' '}
              <a
                target="_blank"
                href="mailto:support@block.green"
                className="hover:no-underline"
                rel="noreferrer"
              >
                support@block.green
              </a>
            </Typography>
          </Stack>
        </form>
      )}
    </Stack>
  );
};
