import { FONTS, appColors } from '@fresh-stack/frontend-commons';
import './assets/App.css';

import { createTheme } from '@mui/material';
import { forwardRef } from 'react';
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from 'react-router-dom';

const bodyFont = FONTS.lato;
const headingFont = FONTS.interBold;

const LinkBehavior = forwardRef<
  HTMLAnchorElement,
  Omit<RouterLinkProps, 'to'> & { readonly href: RouterLinkProps['to'] }
>((props, ref) => {
  const { href, ...other } = props;
  // Map href (Material UI) -> to (react-router)
  return <RouterLink ref={ref} to={href} {...other} />;
});

const buildGlobalTheme = () => {
  const theme = createTheme({
    palette: {
      primary: {
        main: appColors.primary,
      },
      secondary: {
        main: appColors.secondary,
        contrastText: appColors.primary,
      },
      // Used by `getContrastText()` to maximize the contrast between
      // the background and the text.
      contrastThreshold: 3,
      // Used by the functions below to shift a color's luminance by approximately
      // two indexes within its tonal palette.
      // E.g., shift from Red 500 to Red 300 or Red 700.
      tonalOffset: 0.2,
    },
    components: {
      MuiToolbar: {
        styleOverrides: {
          dense: {
            height: 40,
            minHeight: 40,
          },
        },
      },
      MuiLink: {
        defaultProps: {
          component: LinkBehavior,
        },
      },
      MuiButtonBase: {
        defaultProps: {
          LinkComponent: LinkBehavior,
        },
      },
    },
    typography: {
      fontFamily: bodyFont,
      button: {
        textTransform: 'none',
      },
    },
  });

  theme.typography.h5 = {
    ...theme.typography.subtitle1,
    fontSize: '1.4rem',
    fontFamily: headingFont,
  };

  theme.typography.h6 = {
    ...theme.typography.subtitle1,
    fontSize: '1.2rem',
    fontFamily: headingFont,
  };

  theme.typography.subtitle1 = {
    ...theme.typography.subtitle1,
    fontSize: '1.00rem',
    fontFamily: headingFont,
  };

  theme.typography.subtitle2 = {
    ...theme.typography.subtitle2,
    fontSize: '0.95rem',
    fontFamily: headingFont,
  };

  theme.typography.body1 = {
    ...theme.typography.body1,
    fontSize: '0.95rem',
    fontFamily: bodyFont,
  };

  theme.typography.body2 = {
    ...theme.typography.body2,
    fontSize: '0.90rem',
    fontFamily: bodyFont,
  };

  return theme;
};

export const THEME_GLOBAL = buildGlobalTheme();
