import {
  CustomFieldDefinition,
  DownloadButton,
  FONTS,
  TransactionFilterWidget,
  TransactionPagination,
  TransactionSorting,
  TransactionTable,
  TransactionType,
  downloadFile,
  isTransactionType,
  transactionTypeToIsDeposit,
  useQueryState,
  useSafeLog,
  wrapMutation,
  wrapQuery,
} from '@fresh-stack/frontend-commons';
import {
  DateOrStringOrNumber,
  UNCATEGORISED_ID,
  buildTypedError,
  compact,
  ignoreError,
  inspectError,
  inspectSuccess,
  isEqual,
  isSuccess,
  mapError,
  mapSuccess,
  match,
  pipe,
  uniq,
  uuidV4,
} from '@fresh-stack/fullstack-commons';
import { CreateRuleSetDto, TransactionFilter } from '@fresh-stack/router/types';
import SearchOffIcon from '@mui/icons-material/SearchOff';
import {
  Autocomplete,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { format, parse } from 'date-fns';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ROUTES } from '../../app/routes';
import {
  BulkEditFormSchemaType,
  BulkEditTransactionsDialog,
  CLEAR_VALUE,
  EMPTY_VALUE,
} from '../../components/BulkEditTransactionsDialog';
import { trpc } from '../../utils';
import { buildTransactionRulesWithSelectedFieldPath } from '../Automation/AutomationRuleEditor';
import { RuleDialog } from '../Automation/RuleDialog';

const OPTION_ALL = 'All';
const DATE_FORMAT = 'dd-MM-yy';

const buildFilter = ({
  currency = OPTION_ALL,
  search = '',
  selectedAccountIds = undefined,
  startDate = undefined,
  endDate = undefined,
  transactionType = OPTION_ALL,
  attributeClassId = OPTION_ALL,
  attributeValueIds = '',
  selectedAttribute,
}: {
  readonly currency?: string;
  readonly search?: string;
  readonly selectedAccountIds?: string[] | undefined;
  readonly startDate?: Date;
  readonly endDate?: Date;
  readonly transactionType?: TransactionType;
  readonly attributeClassId?: string;
  readonly attributeValueIds?: string;
  readonly selectedAttribute: CustomFieldDefinition | undefined;
}): TransactionFilter => {
  const valueIds = attributeValueIds
    ? attributeValueIds
        .split('|')
        .filter((x) => selectedAttribute?.values.some((y) => x === y.valueId))
    : [];
  return {
    isoCurrencyCode: currency === OPTION_ALL ? undefined : currency?.toString(),
    txNameContains: search ? search.split(' ').filter((x) => x) : undefined,
    accountIds: selectedAccountIds?.length ? selectedAccountIds : undefined,
    startDate: startDate?.toISOString(),
    endDate: endDate?.toISOString(),
    isDeposit: transactionTypeToIsDeposit(transactionType),
    attribute:
      attributeClassId && attributeClassId !== OPTION_ALL && valueIds.length
        ? {
            classId: attributeClassId,
            valueIds: valueIds,
          }
        : undefined,
  };
};

const DEFAULT_FILTER = buildFilter({ selectedAttribute: undefined });

const FIELDNAME_CLASS_ID = 'attributeClassId';
const FIELDNAME_VALUE_ID = 'attributeValueIds';
const FIELDNAME_CURRENCY = 'currency';
const FIELDNAME_STARTDATE = 'startDate';
const FIELDNAME_ENDDATE = 'endDate';
const FIELDNAME_TRANSACTION_TYPE = 'transactionType';

export const buildFilteredTransactionPath = ({
  classId,
  valueId,
  currency,
  startDate,
  endDate,
  transactionType,
}: {
  readonly classId?: string;
  readonly valueId?: string;
  readonly currency?: string;
  readonly startDate?: DateOrStringOrNumber;
  readonly endDate?: DateOrStringOrNumber;
  readonly transactionType?: 'Deposits' | 'Withdrawals';
}) => {
  return (
    `${ROUTES.Commons.Transactions}?` +
    compact([
      classId ? `${FIELDNAME_CLASS_ID}=${classId}` : undefined,
      !!valueId && !!classId ? `${FIELDNAME_VALUE_ID}=${valueId}` : undefined,
      currency ? `${FIELDNAME_CURRENCY}=${currency}` : undefined,
      startDate
        ? `${FIELDNAME_STARTDATE}=${format(new Date(startDate), DATE_FORMAT)}`
        : undefined,
      endDate
        ? `${FIELDNAME_ENDDATE}=${format(new Date(endDate), DATE_FORMAT)}`
        : undefined,
      transactionType
        ? `${FIELDNAME_TRANSACTION_TYPE}=${transactionType}`
        : undefined,
    ]).join('&')
  );
};

export const AllTransactionsTab = () => {
  const safeLog = useSafeLog();
  const navigate = useNavigate();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const [createDialogOpen, setCreateDialogOpen] = useState(false);

  const { mutateAsync: createRuleSetAsync, isLoading: isCreatingRuleSet } =
    wrapMutation(trpc.rules.createRuleSet.useMutation());

  const createRule = async (rule: CreateRuleSetDto) => {
    safeLog('updating rule', rule);
    pipe(
      await createRuleSetAsync({
        filter: rule.filter,
        action: rule.action,
      }),
      inspectError((err) => {
        safeLog('error when creating rule', err);
        enqueueSnackbar({
          message: 'Error when creating rule',
          variant: 'error',
        });
      }),
      inspectSuccess(() => {
        setCreateDialogOpen(false);
        enqueueSnackbar({
          message: 'Rule created',
          variant: 'success',
        });
        navigate(
          buildTransactionRulesWithSelectedFieldPath(
            rule.action.setAttributeValue.attributeId,
          ),
        );
      }),
    );
  };

  const [filterKey, setFilterKey] = useState<string>('default-filter-key');
  const [gridKey, setGridKey] = useState<string>('default-grid-key');

  const [paginationModel, setPaginationModel] = useState<TransactionPagination>(
    {
      page: 0,
      pageSize: 25,
    },
  );

  const [sortModel, setSortModel] = useState<TransactionSorting>({
    by: 'date',
    direction: 'desc',
  });

  const { result: accountsResult, isLoading: accountsIsLoading } = wrapQuery(
    trpc.banking.account.getInstitutionsWithAccounts.useQuery(undefined, {
      refetchOnWindowFocus: false,
    }),
  );

  const allAccounts = useMemo(
    () =>
      accountsResult
        ? pipe(
            accountsResult,
            mapSuccess((banksWithAccounts) =>
              banksWithAccounts.flatMap((bank) =>
                bank.accounts.map((account) => ({
                  name: account.name,
                  mask: account.mask,
                  currency: account.isoCurrency,
                  accountId: account.accountId,
                })),
              ),
            ),
            inspectError((err) => safeLog('Error fetching accounts', err)),
            inspectError(() =>
              enqueueSnackbar({
                message: 'Error fetching accounts',
                variant: 'error',
              }),
            ),
            match(
              (accounts) => accounts,
              () => [],
            ),
          )
        : [],
    [accountsResult, enqueueSnackbar, safeLog],
  );

  const allCurrencies = useMemo(() => {
    if (allAccounts.length === 0) return [OPTION_ALL];
    return uniq(compact([OPTION_ALL, ...allAccounts.map((x) => x.currency)]));
  }, [allAccounts]);

  const [currency, setCurrency] = useQueryState<string>({
    fieldName: FIELDNAME_CURRENCY,
    defaultValue: OPTION_ALL,
    deserialize: (x) => (allCurrencies.includes(x) ? x : undefined),
    serialize: (x) => (x === OPTION_ALL ? '' : x),
  });
  const [startDateStr, setStartDate] = useQueryState<string>({
    fieldName: FIELDNAME_STARTDATE,
    defaultValue: '',
    deserialize: (value: string) => value ?? '',
    serialize: (value) => value ?? '',
  });

  const startDate = useMemo(
    () =>
      startDateStr ? parse(startDateStr, DATE_FORMAT, new Date()) : undefined,
    [startDateStr],
  );

  const [endDateStr, setEndDate] = useQueryState<string>({
    fieldName: FIELDNAME_ENDDATE,
    defaultValue: '',
    deserialize: (value: string) => value ?? '',
    serialize: (value) => value ?? '',
  });

  const endDate = useMemo(
    () => (endDateStr ? parse(endDateStr, DATE_FORMAT, new Date()) : undefined),
    [endDateStr],
  );

  const [transactionType, setTransactionType] = useQueryState<TransactionType>({
    fieldName: FIELDNAME_TRANSACTION_TYPE,
    defaultValue: OPTION_ALL,
    deserialize: (value: string) =>
      isTransactionType(value) ? value : undefined,
    serialize: (value) => value,
  });

  // to avoid pummeling the backend, we delay the actual search, by having one field
  // for the typed value, and one that gets updated with a delay for the actual search.
  // Should we use the debounced value in the URL too?
  const [currentSearchValue, setCurrentSearchValue] = useQueryState<
    string | undefined
  >({
    fieldName: 'currentSearchValue',
    defaultValue: undefined,
    deserialize: (value: string) =>
      value ? decodeURIComponent(value) : undefined,
    serialize: (value) => (value ? encodeURIComponent(value) : ''),
  });

  const [delayedFilterSearch, setDelayedFilterSearch] = useState<
    string | undefined
  >();

  useEffect(() => {
    const delayDebounce = setTimeout(() => {
      setDelayedFilterSearch(currentSearchValue);
    }, 300);

    return () => clearTimeout(delayDebounce);
  }, [currentSearchValue]);

  // we need to use a string to preserve account ids,
  // can't use an object or an array as that creates an infinite effect loop for some reason
  const [selectedAccountIdsStr, setSelectedAccountIds] = useQueryState<string>({
    fieldName: 'selectedAccountIds',
    defaultValue: '',
    deserialize: (x) => x,
    serialize: (x) => x,
  });

  const selectedAccountIds = useMemo(
    () => compact(selectedAccountIdsStr?.trim().split('|')),
    [selectedAccountIdsStr],
  );

  const {
    mutateAsync: generateDownloadLink,
    isLoading: generatingDownloadLink,
  } = wrapMutation(trpc.banking.transaction.generateDownloadLink.useMutation());

  const isGeneratingDownloadLink = generatingDownloadLink;

  const [bulkEditDialogOpen, setBulkEditDialogOpen] = useState<
    | undefined
    | {
        readonly kind: 'only-visible-selection' | 'all-transactions';
        readonly count: number;
      }
  >(undefined);

  const { result: attributesResult, isFetching: isFetchingAttributes } =
    wrapQuery(
      trpc.banking.transactionAttribute.list.useQuery(undefined, {
        refetchOnWindowFocus: false,
      }),
    );

  const defaultFields: CustomFieldDefinition[] = useMemo(() => {
    if (!attributesResult) {
      return [];
    } else
      return pipe(
        attributesResult,
        inspectError((err) =>
          safeLog('Error fetching custom field schema', err),
        ),
        mapSuccess((schema) =>
          schema.map((attr) => ({
            classId: attr.attributeId,
            className: attr.name,
            values: [
              ...attr.options.map((opt) => ({
                valueId: opt.optionId,
                valueName: opt.name,
              })),
            ],
          })),
        ),
        ignoreError([]),
      );
  }, [attributesResult, safeLog]);

  const customFields: CustomFieldDefinition[] = useMemo(() => {
    if (!attributesResult) {
      return [];
    } else
      return pipe(
        attributesResult,
        inspectError((err) =>
          safeLog('Error fetching custom field schema', err),
        ),
        mapSuccess((schema) =>
          schema.map((attr) => ({
            classId: attr.attributeId,
            className: attr.name,
            values: [
              ...attr.options.map((opt) => ({
                valueId: opt.optionId,
                valueName: opt.name,
              })),
            ],
          })),
        ),
        ignoreError([]),
      );
  }, [attributesResult, safeLog]);

  const [attributeClassId, setAttributeClassId] = useQueryState<string>({
    fieldName: FIELDNAME_CLASS_ID,
    defaultValue: OPTION_ALL,
    deserialize: (value: string) => value || OPTION_ALL,
    serialize: (value) => value ?? OPTION_ALL,
  });

  // we need to use a string to preserve value ids,
  // can't use an object or an array as that creates an infinite effect loop for some reason
  const [attributeValueIds, setAttributeValueIds] = useQueryState<string>({
    fieldName: FIELDNAME_VALUE_ID,
    defaultValue: '',
    deserialize: (value: string) => value,
    serialize: (value) => value,
  });
  const selectedAttribute = useMemo(
    () => customFields.find((x) => x.classId === attributeClassId),
    [attributeClassId, customFields],
  );

  const selectedAttrFilterValues = selectedAttribute
    ? [
        ...selectedAttribute.values,
        {
          valueId: UNCATEGORISED_ID,
          valueName: 'Uncategorised',
        },
      ]
    : [];

  const { canCreateRule, hasNoFilter, currentFilter } = useMemo(() => {
    const currentFilter = buildFilter({
      currency,
      search: delayedFilterSearch,
      selectedAccountIds,
      startDate,
      endDate,
      transactionType,
      attributeClassId,
      attributeValueIds,
      selectedAttribute,
    });
    const hasNoFilter = isEqual(currentFilter, DEFAULT_FILTER);
    const canCreateRule = !isEqual(DEFAULT_FILTER, {
      ...currentFilter,
      attribute: undefined,
    });
    return { canCreateRule, hasNoFilter, currentFilter };
  }, [
    currency,
    delayedFilterSearch,
    selectedAccountIds,
    startDate,
    endDate,
    transactionType,
    attributeClassId,
    attributeValueIds,
    selectedAttribute,
  ]);

  const currentTxListParams = useMemo(
    () => ({
      pagination: {
        offset: paginationModel.pageSize * paginationModel.page,
        take: paginationModel.pageSize,
      },
      sort: sortModel,
      filter: currentFilter,
    }),
    [currentFilter, paginationModel, sortModel],
  );

  const [selectedTxRows, setSelectedTxRows] = useState<{
    readonly selected: string[];
    readonly isFullPageSelected: boolean;
  }>({ selected: [], isFullPageSelected: false });

  const [allPagesSelected, setAllPagesSelected] = useState(false);

  const {
    mutateAsync: setManyAttributeValues,
    isLoading: setManyAttributeValuesIsLoading,
  } = wrapMutation(trpc.banking.transaction.attribute.setMany.useMutation());

  const { mutateAsync: setManyByFilter, isLoading: setManyByFilterLoading } =
    wrapMutation(
      trpc.banking.transaction.attribute.setManyByFilter.useMutation(),
    );

  const saveBulkEditForm =
    (kind: 'only-visible-selection' | 'all-transactions') =>
    async (values: BulkEditFormSchemaType) => {
      safeLog('Bulk edit form values', values);
      const setAttributeIds: Record<string, { readonly optionId: string }> = {};
      const clearAttributeIds: string[] = [];
      for (const [attributeId, value] of Object.entries(values)) {
        if (value === CLEAR_VALUE) {
          clearAttributeIds.push(attributeId);
        } else if (value !== EMPTY_VALUE) {
          setAttributeIds[attributeId] = { optionId: value };
        }
      }

      const doUpdate = () => {
        safeLog('Bulk updating selected', selectedTxRows.selected);
        if (kind === 'only-visible-selection')
          return setManyAttributeValues({
            transactionIds: [...selectedTxRows.selected],
            setAttributeIds,
            clearAttributeIds,
          });
        else
          return setManyByFilter({
            setAttributeIds,
            clearAttributeIds,
            transactionFilter: currentFilter,
            exceptTransactionIds: [],
          });
      };

      pipe(
        await doUpdate(),
        inspectError((err) => {
          safeLog('Error setting multiple custom field values', err);
        }),
        mapError((_err) =>
          buildTypedError('unknown', 'Error bulk updating custom field values'),
        ),
        inspectSuccess(() => refetchTransactions()),
        inspectSuccess(() => {
          setBulkEditDialogOpen(undefined);
          resetSelectedTxRows();
          enqueueSnackbar({
            message: 'Successfully updated custom fields',
            variant: 'success',
          });
        }),
        inspectError(() => {
          enqueueSnackbar({
            message: 'Failed to update custom fields',
            variant: 'error',
          });
        }),
      );
    };

  const updateTransaction = async ({
    transactionId,
    customFieldValueId,
    customFieldId,
  }: {
    readonly transactionId: string;
    readonly customFieldId: string;
    readonly customFieldValueId: string;
  }) => {
    safeLog('Bulk edit form values', {
      transactionId,
      customFieldId,
      customFieldValueId,
    });
    const setAttributeIds: Record<string, { readonly optionId: string }> = {};
    const clearAttributeIds: string[] = [];
    if (!customFieldValueId) return;
    if (customFieldValueId === CLEAR_VALUE) {
      clearAttributeIds.push(customFieldId);
    } else if (customFieldValueId !== EMPTY_VALUE) {
      setAttributeIds[customFieldId] = { optionId: customFieldValueId };
    }

    pipe(
      await setManyAttributeValues({
        transactionIds: [transactionId],
        setAttributeIds,
        clearAttributeIds,
      }),
      inspectError((err) => {
        safeLog('Error setting custom field value', err);
      }),
      mapError((_err) =>
        buildTypedError('unknown', 'Error bulk updating custom field values'),
      ),
      inspectSuccess(() => {
        refetchTransactions();
        setIndividualTransaction(undefined);
        resetSelectedTxRows();
        enqueueSnackbar({
          message: 'Successfully updated transaction.',
          variant: 'success',
        });
      }),
      inspectError(() => {
        enqueueSnackbar({
          message: 'Failed to update transaction',
          variant: 'error',
        });
      }),
    );
  };

  const handleSelectionChanged = ({
    transactionIds,
    isFullPageSelected,
  }: {
    readonly transactionIds: string[];
    readonly isFullPageSelected: boolean;
  }) => {
    safeLog('Checked row change', transactionIds, isFullPageSelected);

    setSelectedTxRows({
      selected: [...transactionIds],
      isFullPageSelected: isFullPageSelected,
    });
    setAllPagesSelected(false);
  };

  const resetSelectedTxRows = useCallback(() => {
    setSelectedTxRows({ selected: [], isFullPageSelected: false });
    setAllPagesSelected(false);
    setGridKey(uuidV4());
  }, []);

  const {
    result: storedTransactionsResult,
    isFetching: storedTransactionsFetching,
    refetch: refetchTransactions,
  } = wrapQuery(
    trpc.banking.transaction.list.useQuery(currentTxListParams, {
      refetchOnWindowFocus: false,
      refetchInterval: 60_000,
    }),
  );

  const transactionsResult = useMemo(
    () =>
      storedTransactionsResult
        ? pipe(
            storedTransactionsResult,
            inspectError((err) => safeLog('Error fetching transactions', err)),
          )
        : undefined,
    [storedTransactionsResult, safeLog],
  );

  const rowCount =
    transactionsResult && isSuccess(transactionsResult)
      ? transactionsResult.right.totalCount
      : 0;

  const selectedCount = allPagesSelected
    ? rowCount
    : selectedTxRows.selected.length;

  const selectedLabel = selectedCount === 1 ? 'transaction' : 'transactions';

  const [individualTransaction, setIndividualTransaction] = useState<
    | { readonly transactionId: string; readonly customFieldId: string }
    | undefined
  >();

  const individualCustomField = useMemo(
    () =>
      customFields.find(
        (x) => x.classId === individualTransaction?.customFieldId,
      ),
    [customFields, individualTransaction?.customFieldId],
  );

  const transactionRowInput = useMemo(() => {
    if (!transactionsResult || !isSuccess(transactionsResult)) return [];

    return transactionsResult.right.transactions.map((transaction) => ({
      transactionId: transaction.transactionId,
      accountName: transaction.accountName,
      date: new Date(transaction.date),
      amount: transaction.amount,
      isPending: transaction.isPending,
      isRejected: transaction.isRejected,
      transactionType:
        transaction.transactionType === 'Deposit' ? 'Inflow' : 'Outflow',
      nameOrDescription: transaction.nameOrDescription ?? '-',
      isoCurrencyCode: transaction.isoCurrencyCode ?? '',
      attributes: transaction.attributes,
    }));
  }, [transactionsResult]);

  const onDownloadButtonClick = async () => {
    pipe(
      await generateDownloadLink(currentTxListParams),
      inspectError((err) => safeLog('Error generating download link', err)),
      inspectSuccess((link) => {
        safeLog('Generated download link', link);
        downloadFile(link.downloadlink);
      }),
    );
  };

  const SelectionActions = useMemo(() => {
    return () => (
      <Grid
        container
        item
        xs={12}
        sm={8}
        alignItems={'center'}
        justifyContent="flex-start"
        gap={1}
      >
        {selectedCount > 0 && customFields.length && (
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={() =>
              setBulkEditDialogOpen({
                kind: 'only-visible-selection',
                count: selectedCount,
              })
            }
            disabled={isFetchingAttributes}
          >
            Edit {selectedCount} {selectedLabel}
          </Button>
        )}
        {selectedTxRows.isFullPageSelected && customFields.length && (
          <Button
            size="small"
            color="primary"
            variant="contained"
            sx={{ width: 'fit-content' }}
            onClick={() =>
              setBulkEditDialogOpen({
                kind: 'all-transactions',
                count: rowCount,
              })
            }
          >
            Edit all {rowCount.toLocaleString()} transactions
          </Button>
        )}
        {!!selectedTxRows.selected.length && (
          <Button
            size="small"
            color="success"
            variant="contained"
            sx={{ width: 'fit-content' }}
            onClick={() => resetSelectedTxRows()}
          >
            Clear selection
          </Button>
        )}
      </Grid>
    );
  }, [
    customFields.length,
    isFetchingAttributes,
    resetSelectedTxRows,
    rowCount,
    selectedCount,
    selectedLabel,
    selectedTxRows.isFullPageSelected,
    selectedTxRows.selected.length,
  ]);

  return (
    <Stack>
      <Box height={'91vh'} overflow={'auto'}>
        <Stack pl={1} pr={1}>
          {!accountsIsLoading && allAccounts.length === 0 ? (
            <Typography
              variant="h6"
              fontFamily={FONTS.interBold}
              color="grey"
              textAlign={'center'}
            >
              Looks like you don't have any bank accounts setup yet. You can add
              them in the <a href={ROUTES.Commons.Accounts}>Accounts</a>{' '}
              section.
            </Typography>
          ) : (
            <Stack spacing={1}>
              <Stack spacing={1}>
                {customFields.length > 0 && (
                  <Grid container item xs={12} pt={2}>
                    <Grid
                      container
                      item
                      xs={12}
                      justifyContent={'flex-start'}
                      gap={1}
                    >
                      <FormControl>
                        <InputLabel id="select-attribute-label" size="small">
                          Custom field
                        </InputLabel>
                        <Select
                          size="small"
                          label="Custom field"
                          sx={{ width: 200 }}
                          onChange={(e) => {
                            setAttributeClassId(e.target.value);
                          }}
                          value={attributeClassId}
                        >
                          <MenuItem key={'All'} value="All">
                            All
                          </MenuItem>
                          {customFields.map((value) => (
                            <MenuItem key={value.classId} value={value.classId}>
                              <Typography
                                style={{
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                  whiteSpace: 'nowrap',
                                }}
                              >
                                {value.className}
                              </Typography>
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      {selectedAttribute && (
                        <Autocomplete
                          multiple
                          id="autocomplete-attr-values"
                          options={selectedAttrFilterValues}
                          getOptionLabel={(option) => option.valueName}
                          sx={{ width: 200 }}
                          value={selectedAttrFilterValues.filter((x) =>
                            attributeValueIds.includes(x.valueId),
                          )}
                          onChange={(_event, newValue) => {
                            setAttributeValueIds(
                              newValue.map((x) => x.valueId).join('|'),
                            );
                          }}
                          size="small"
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Custom field options"
                              variant="outlined"
                              size="small"
                              sx={{ minWidth: 120 }}
                            />
                          )}
                          renderOption={(props, option) => (
                            <MenuItem {...props} key={option.valueId}>
                              <Typography
                                sx={{
                                  mr: 1,
                                  whiteSpace: 'nowrap',
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                  fontStyle:
                                    option.valueId === UNCATEGORISED_ID
                                      ? 'italic'
                                      : undefined,
                                }}
                                noWrap
                              >
                                {option.valueName}
                              </Typography>
                            </MenuItem>
                          )}
                          PaperComponent={({ children }) => (
                            <Paper
                              style={{
                                width: 'auto',
                                minWidth: '300px',
                              }}
                            >
                              {children}
                            </Paper>
                          )}
                        />
                      )}
                    </Grid>
                    <Grid
                      container
                      item
                      xs={12}
                      alignItems={'flex-end'}
                      justifyContent={'flex-end'}
                    ></Grid>
                  </Grid>
                )}
                <Grid
                  container
                  item
                  xs={12}
                  justifyContent={'space-between'}
                  alignItems={'flex-end'}
                  gap={1}
                >
                  <Grid
                    container
                    item
                    boxShadow={2}
                    width={'fit-content'}
                    padding={1}
                    borderRadius={1}
                    gap={1}
                  >
                    <Box>
                      <TransactionFilterWidget
                        key={filterKey}
                        txNameContains={currentSearchValue}
                        onTxNameContainsChange={setCurrentSearchValue}
                        currency={currency}
                        onCurrencyChange={setCurrency}
                        startDate={startDate}
                        setStartDate={(newValue) =>
                          setStartDate(
                            newValue ? format(newValue, DATE_FORMAT) : '',
                          )
                        }
                        endDate={endDate}
                        setEndDate={(newValue) =>
                          setEndDate(
                            newValue ? format(newValue, DATE_FORMAT) : '',
                          )
                        }
                        transactionType={transactionType}
                        onTransactionTypeChange={setTransactionType}
                        selectedAccountIds={selectedAccountIds}
                        onSelectedAccountIdsChange={(x) => {
                          setSelectedAccountIds(x.join('|'));
                        }}
                        availableCurrencies={allCurrencies}
                        availableAccounts={allAccounts}
                        showDefaultFields={true}
                      />
                    </Box>
                    {canCreateRule ? (
                      <Box display="flex" alignItems={'center'}>
                        <Button
                          variant="contained"
                          sx={{
                            width: 'fit-content',
                            fontFamily: FONTS.lato,
                          }}
                          onClick={() => setCreateDialogOpen(true)}
                        >
                          Create automation rule
                        </Button>
                      </Box>
                    ) : null}
                  </Grid>
                  <Box height={'100%'} display="flex" alignItems={'flex-end'}>
                    <Button
                      variant={'outlined'}
                      color="primary"
                      size="small"
                      onClick={() => {
                        navigate(location.pathname);
                        setFilterKey(uuidV4()); //force reset of the widget to remove any leftovers
                      }}
                      disabled={hasNoFilter}
                      startIcon={<SearchOffIcon />}
                      sx={{
                        width: 'fit-content',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      Reset all filters
                    </Button>
                  </Box>
                </Grid>
              </Stack>
              <SelectionActions />

              <TransactionTable
                key={gridKey}
                isLoading={storedTransactionsFetching}
                rowCount={rowCount}
                paginationModel={paginationModel}
                sortModel={sortModel}
                transactions={transactionRowInput}
                customFields={customFields}
                onSortChange={setSortModel}
                onSelectionChanged={handleSelectionChanged}
                onPaginationChange={setPaginationModel}
                onEditIndividualTransaction={setIndividualTransaction}
              />
              <Grid container item xs={12} sm={12}>
                <SelectionActions />

                {!!rowCount && (
                  <Grid item xs={12} sm={4} container justifyContent="flex-end">
                    <DownloadButton
                      size="small"
                      text="Download CSV"
                      disabled={!rowCount}
                      isLoading={isGeneratingDownloadLink}
                      onClick={onDownloadButtonClick}
                    />
                  </Grid>
                )}
              </Grid>
            </Stack>
          )}
        </Stack>
      </Box>

      {/* ------------------------------ */}
      {/* dialogs, loading screens, ...  */}
      <Backdrop
        sx={{ color: 'white', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isCreatingRuleSet}
      >
        <CircularProgress color="secondary" />
      </Backdrop>

      {!!bulkEditDialogOpen?.count && (
        <BulkEditTransactionsDialog
          isOpen={!!bulkEditDialogOpen}
          onClose={() => setBulkEditDialogOpen(undefined)}
          onSave={saveBulkEditForm(bulkEditDialogOpen.kind)}
          isSaving={setManyAttributeValuesIsLoading || setManyByFilterLoading}
          customFields={customFields}
          title={`Bulk edit transactions (${bulkEditDialogOpen.count} selected)`}
        />
      )}

      {!!individualTransaction && individualCustomField && (
        <BulkEditTransactionsDialog
          isOpen={true}
          onClose={() => setIndividualTransaction(undefined)}
          onSave={(update) =>
            updateTransaction({
              transactionId: individualTransaction.transactionId,
              customFieldId: individualTransaction.customFieldId,
              customFieldValueId: update[individualTransaction.customFieldId],
            })
          }
          isSaving={setManyAttributeValuesIsLoading || setManyByFilterLoading}
          customFields={[individualCustomField]}
          title={`Update transaction`}
        />
      )}

      {createDialogOpen && (
        <RuleDialog
          onClose={() => setCreateDialogOpen(false)}
          onSave={async (row) => {
            createRule({
              filter: {
                isoCurrencyCode:
                  row.filter.isoCurrencyCode === OPTION_ALL
                    ? undefined
                    : row.filter.isoCurrencyCode,
                accountIds: row.filter.accountIds,
                txNameContains: row.filter.txNameContains.map((x) => ({
                  value: x.value?.split(' ').filter(Boolean),
                })),
                isDeposit: transactionTypeToIsDeposit(
                  row.filter.transactionType,
                ),
                startDate: row.filter.startDate?.toISOString(),
                endDate: row.filter.endDate?.toISOString(),
              },
              action: {
                setAttributeValue: {
                  attributeId: row.customFieldId,
                  valueId: row.customFieldValueId,
                },
              },
            });
          }}
          isSaving={isCreatingRuleSet}
          initialRuleFilter={{
            isoCurrencyCode: currency,
            txNameContains: delayedFilterSearch
              ? [{ value: delayedFilterSearch ?? '' }]
              : [],
            transactionType: transactionType,
            accountIds: selectedAccountIds,
            startDate: startDate,
            endDate: endDate,
          }}
          availableCurrencies={allCurrencies}
          availableAccounts={allAccounts}
          dialogTitleText="Add new rule"
          customFields={defaultFields}
          editMode={{ kind: 'allow-choice' }}
        />
      )}
    </Stack>
  );
};
