import {
  AsyncResult,
  compact,
  difference,
  inspectError,
  inspectSuccess,
  pipe,
} from '@fresh-stack/fullstack-commons';
import {
  ReqUpdateTxAttribute,
  TxAttributeDto,
} from '@fresh-stack/router/types';
import { useSnackbar } from 'notistack';
import { useMemo } from 'react';
import { AttributeDialog, AttributeFormSchemaType } from './AttributeDialog';

export const UpdateAttributeDialog = <T,>({
  onClose,
  onSuccess,
  existingAttributeNames,
  attributeId,
  attributeIsLoading,
  dbAttribute,
  updateAttribute,
  attributeIsUpdating,
}: {
  readonly onClose: () => void;
  readonly onSuccess: ({ name }: { readonly name: string }) => void;
  readonly existingAttributeNames: readonly string[];
  readonly attributeId: string;
  readonly attributeIsLoading: boolean;
  readonly dbAttribute: TxAttributeDto | undefined;
  readonly updateAttribute: (
    req: ReqUpdateTxAttribute,
  ) => AsyncResult<NonNullable<T>, string>;
  readonly attributeIsUpdating: boolean;
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const initialValues: AttributeFormSchemaType | undefined = useMemo(() => {
    if (!dbAttribute) {
      return undefined;
    }
    return {
      name: dbAttribute.name,
      options: dbAttribute.options.map((o) => ({
        name: o.name,
        databaseId: o.optionId,
      })),
    };
  }, [dbAttribute]);

  const otherAttributeNames = existingAttributeNames.filter(
    (x) => x !== dbAttribute?.name,
  );

  const onSave = async (formValues: AttributeFormSchemaType) => {
    if (!dbAttribute) {
      enqueueSnackbar({
        variant: 'error',
        message:
          'We ran into an issue getting this custom field, please try again!',
      });
      return;
    }
    const req: ReqUpdateTxAttribute = buildUpdateAttributeReq({
      attributeId,
      formValues,
      dbAttribute,
    });
    pipe(
      await updateAttribute(req),
      inspectSuccess((_) => onSuccess({ name: formValues.name })),
      inspectError((_) =>
        enqueueSnackbar({
          variant: 'error',
          message: 'We ran into an issue with your request, please try again!',
        }),
      ),
    );
  };
  // TODO(enp-442): add text to the form of how many accounts will be modified etc.
  return (
    <AttributeDialog
      key={btoa(JSON.stringify(initialValues))}
      open={true}
      onClose={onClose}
      onSave={onSave}
      otherAttributeNames={otherAttributeNames}
      dialogTitleText="Update custom field"
      submitButtonText="Save"
      initialValuesLoading={attributeIsLoading}
      initialValues={initialValues}
      isSaving={attributeIsUpdating}
    />
  );
};

const buildUpdateAttributeReq = ({
  attributeId,
  formValues,
  dbAttribute,
}: {
  readonly attributeId: string;
  readonly formValues: AttributeFormSchemaType;
  readonly dbAttribute: TxAttributeDto;
}): ReqUpdateTxAttribute => ({
  attributeId,
  newName: formValues.name === dbAttribute.name ? undefined : formValues.name,
  options: formValues.options.map((formOption) => {
    if (formOption.databaseId == null) {
      return { name: formOption.name, _type: 'added' };
    } else if (
      dbAttribute.options.find((o) => o.optionId === formOption.databaseId)
        ?.name !== formOption.name
    ) {
      return {
        optionId: formOption.databaseId,
        name: formOption.name,
        _type: 'modified',
      };
    } else {
      return { optionId: formOption.databaseId, _type: 'unchanged' };
    }
  }),
  removedOptions: difference(
    dbAttribute.options.map((o) => o.optionId),
    compact(formValues.options.map((o) => o.databaseId)),
  ).map((optionId) => ({ optionId })),
});
