import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import DescriptionIcon from '@mui/icons-material/Description';
import GradingIcon from '@mui/icons-material/Grading';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import PaidIcon from '@mui/icons-material/Paid';
import SpaceDashboardIcon from '@mui/icons-material/SpaceDashboard';
import { SvgIconOwnProps } from '@mui/material';
import { ROUTES } from './routes';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import { UserType } from '@fresh-stack/fullstack-commons';
export interface AppMenuItem {
  readonly name: string;
  readonly path: string;
  readonly isLocked?: boolean;
  readonly icon: (props: SvgIconOwnProps) => JSX.Element & SvgIconOwnProps;
}

type AppMenu = ReadonlyArray<AppMenuItem>;

const NAV_MENU_NONADMIN: AppMenu = [
  {
    name: 'Dashboards',
    path: ROUTES.Commons.Home,
    icon: (props) => <LeaderboardIcon fontSize={'small'} {...props} />,
  },
  {
    name: 'Accounts',
    path: ROUTES.Commons.Accounts,
    icon: (props) => <AccountBalanceWalletIcon fontSize={'small'} {...props} />,
  },
  {
    name: 'Transactions',
    path: ROUTES.Commons.Transactions,
    icon: (props) => <SpaceDashboardIcon fontSize={'small'} {...props} />,
  },
  {
    name: 'Imports',
    path: ROUTES.Admin.Imports,
    icon: (props) => <ImportExportIcon fontSize={'small'} {...props} />,
  },
  {
    name: 'Invoice processing',
    path: ROUTES.Admin.Invoices,
    isLocked: true,
    icon: (props) => <DescriptionIcon fontSize={'small'} {...props} />,
  },
  {
    name: 'Sales',
    path: ROUTES.Admin.Sales,
    isLocked: true,
    icon: (props) => <GradingIcon fontSize={'small'} {...props} />,
  },
  {
    name: 'Reports',
    path: ROUTES.Admin.Reports,
    isLocked: true,
    icon: (props) => <PaidIcon fontSize={'small'} {...props} />,
  },
];
const NAV_MENU_ADMIN: AppMenu = [...NAV_MENU_NONADMIN];

export const getMenu = (userType: UserType): AppMenu => {
  switch (userType) {
    case 'Admin':
      return NAV_MENU_ADMIN;
    case 'User':
      return NAV_MENU_NONADMIN;
  }
};
