import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import qs from 'qs';

export const useQueryState = <T>({
  fieldName,
  defaultValue,
  deserialize,
  serialize,
}: {
  readonly fieldName: string;
  readonly defaultValue: T;
  readonly deserialize: (x: string) => T | undefined;
  readonly serialize: (x: T) => string;
}): [T, (value: T) => void] => {
  const location = useLocation();
  const navigate = useNavigate();

  const setQuery = useCallback(
    (value: T) => {
      const existingQueries = qs.parse(location.search, {
        ignoreQueryPrefix: true,
      });

      // to avoid including empty values for nothing in the query
      const serialised = serialize(value) || null;

      const queryString = qs.stringify(
        {
          ...existingQueries,
          [fieldName]: serialised,
        },
        { skipNulls: true },
      );
      navigate(`${location.pathname}?${queryString}`);
    },
    [location.search, location.pathname, fieldName, serialize, navigate],
  );

  return [
    deserialize(
      qs
        .parse(location.search, { ignoreQueryPrefix: true })
        [fieldName]?.toString() ?? '',
    ) || defaultValue,
    setQuery,
  ];
};
