import { Link, Stack, Typography } from '@mui/material';
import { ROUTES } from '../../app/routes';
import { appColors } from '@fresh-stack/frontend-commons';

export const Footer = () => {
  return (
    <Stack
      width="100%"
      alignItems={'center'}
      justifyContent={'center'}
      bgcolor={appColors.primary}
      spacing={2}
      padding={2}
      height={125}
    >
      <Typography color="secondary">
        Copyright © 2024 Ribon - All Rights Reserved.
      </Typography>
      <Link underline="always" color="secondary" href={ROUTES.Public.Privacy}>
        PRIVACY POLICY
      </Link>
    </Stack>
  );
};
